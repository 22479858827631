<template>
  <validation-observer
    ref="buttonObserver"
    v-slot="{ valid }"
  >
    <h4>{{ $t('gasketSideTitle') }}:</h4>
    <el-radio-group
      v-model="selectedSideGasket"
      fill="#1bafd6"
      style="margin-bottom: 8px"
    >
      <el-radio-button
        v-for="gasket in siteGaskets"
        :key="gasket.id"
        :label="getLimitedGasket(gasket)"
        class="card"
        rules="required"
      >
        <el-button
          class="info-box"
          size="xs"
          circle
          @click="openInfoBox(gasket.title, gasket.body_html)"
        >
          <i class="el-icon-info info-icon" />
        </el-button>
        <div class="img-box">
          <img
            :src="gasket.image?.src"
            class="image"
          >
        </div>
        <div class="caption">
          <span class="title">{{ getTitle(gasket.title) }}</span>
          <div class="bottom">
            <span class="info">{{ $t('select') }}</span>
            <span class="info price">{{ calculateSideGasketPrice(getVariantPrice(gasket.variants)) }} €</span>
          </div>
        </div>
        <div class="overlay" />
      </el-radio-button>
      <el-radio-button
        v-if="selectedHinge && !selectedHinge.gasket_required"
        :label="{ title: 'Keine Dichtung' }"
        class="card"
        rules="required"
      >
        <el-button
          class="info-box"
          size="xs"
          circle
          @click="openInfoBox($t('withoutDescTitle'), $t('withoutDescHtml'))"
        >
          <i class="el-icon-info info-icon" />
        </el-button>
        <div class="img-box">
          <img
            :src="getImgUrl('ohne')"
            class="image"
          >
        </div>
        <div class="caption">
          <span class="title">{{ $t('withoutGasket') }}</span>
          <div class="bottom">
            <span class="info">{{ $t('select') }}</span>
          </div>
        </div>
        <div class="overlay" />
      </el-radio-button>
    </el-radio-group>

    <h4 v-if="lipGaskets.length > 0 && lipGasketNeeded">
      {{ $t('gasketLipTitle') }}:
    </h4>
    <el-radio-group
      v-if="lipGaskets.length > 0 && lipGasketNeeded"
      v-model="selectedLipGasket"
      fill="#1bafd6"
      style="margin-bottom: 8px"
    >
      <el-radio-button
        v-for="gasket in lipGaskets"
        :key="gasket.id"
        :label="getLimitedGasket(gasket)"
        class="card"
        rules="required"
      >
        <el-button
          class="info-box"
          size="xs"
          circle
          @click="openInfoBox(gasket.title, gasket.body_html)"
        >
          <i class="el-icon-info info-icon" />
        </el-button>
        <div class="img-box">
          <img
            :src="gasket.image?.src"
            class="image"
          >
        </div>
        <div class="caption">
          <span class="title">{{ getTitle(gasket.title) }}</span>
          <div class="bottom">
            <span class="info">{{ $t('select') }}</span>
            <span class="info price">{{ getVariantPrice(gasket.variants) * getSidePlateCount }} €</span>
          </div>
        </div>
        <div class="overlay" />
      </el-radio-button>
    </el-radio-group>

    <h4 v-if="midGaskets.length > 0 && (getDoorCount > 1 || sidePlate != fitting)">
      {{ $t('gasketMidTitle') }}:
    </h4>
    <el-radio-group
      v-if="midGaskets.length > 0 && (getDoorCount > 1 || sidePlate != fitting)"
      v-model="selectedMidGasket"
      fill="#1bafd6"
      style="margin-bottom: 8px"
    >
      <el-radio-button
        v-for="gasket in midGaskets"
        :key="gasket.id"
        :label="getLimitedGasket(gasket)"
        class="card"
        rules="required"
      >
        <el-button
          class="info-box"
          size="xs"
          circle
          @click="openInfoBox(gasket.title, gasket.body_html)"
        >
          <i class="el-icon-info info-icon" />
        </el-button>
        <div class="img-box">
          <img
            :src="gasket.image?.src"
            class="image"
          >
        </div>
        <div class="caption">
          <span class="title">{{ getTitle(gasket.title) }}</span>
          <div class="bottom">
            <span class="info">{{ $t('select') }}</span>
            <span class="info price">{{ getVariantPrice(gasket.variants) }} €</span>
          </div>
        </div>
        <div class="overlay" />
      </el-radio-button>
    </el-radio-group>

    <h4>{{ $t('gasketBottomTitle') }}:</h4>
    <el-radio-group
      v-model="selectedBottomGasket"
      fill="#1bafd6"
      style="margin-bottom: 8px"
    >
      <el-radio-button
        v-for="gasket in bottomGaskets"
        :key="gasket.id"
        :label="getLimitedGasket(gasket, calculateShowerGlassWidth)"
        class="card"
        rules="required"
      >
        <el-button
          class="info-box"
          size="xs"
          circle
          @click="openInfoBox(gasket.title, gasket.body_html)"
        >
          <i class="el-icon-info info-icon" />
        </el-button>
        <div class="img-box">
          <img
            :src="gasket.image?.src"
            class="image"
          >
        </div>
        <div class="caption">
          <span class="title">{{ getTitle(gasket.title) }}</span>
          <div class="bottom">
            <span class="info">{{ $t('select') }}</span>
            <span class="info price">{{ getVariantPrice(gasket.variants, calculateShowerGlassWidth) }} €</span>
          </div>
        </div>
        <div class="overlay" />
      </el-radio-button>

      <el-radio-button
        :label="{ title: 'Keine Dichtung' }"
        class="card"
        rules="required"
      >
        <el-button
          class="info-box"
          size="xs"
          circle
          @click="openInfoBox($t('withoutDescTitle'), $t('withoutDescHtml'))"
        >
          <i class="el-icon-info info-icon" />
        </el-button>
        <div class="img-box">
          <img
            :src="getImgUrl('ohne')"
            class="image"
          >
        </div>
        <div class="caption">
          <span class="title">{{ $t('withoutGasket') }}</span>
          <div class="bottom">
            <span class="info">{{ $t('select') }}</span>
          </div>
        </div>
        <div class="overlay" />
      </el-radio-button>
    </el-radio-group>

    <div
      align="right"
    >
      <el-button
        type="primary"
        :disabled="!valid || !selectedBottomGasket || !selectedSideGasket"
        @click="next()"
      >
        {{ $t('nextBtn') }}
      </el-button>
    </div>
  </validation-observer>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import i18n from "@/js/plugins/i18n";

export default {
  name: "Dichtung",
  props: {
    nextIndex: {
      type: Number,
      default: 5
    },
  },
  computed: {
    ...mapGetters([
      "calculateShowerGlassWidth",
      "calculateSideGasketPrice",
      "filterAccessoriesBy",
      "getDoorCount",
      "getSidePlateCount",
      "lipGasketNeeded",
      "selectVariantByOption"
    ]),
    ...mapFields([
      "length",
      "height",
      "fitting",
      "sidePlate",
      "selectedHinge",
      "selectedBottomGasket",
      "selectedLipGasket",
      "selectedMidGasket",
      "selectedSideGasket",
    ]),
    bottomGaskets() {
      return this.filterAccessoriesBy([
        ['title', 'Wasserabweisprofil'],
        ['type', 'Dichtung']
      ]);
    },
    lipGaskets() {
      return this.filterAccessoriesBy([
        ['title', 'Lippendichtung'],
        ['type', 'Dichtung']
      ]);
    },
    midGaskets() {
      return this.filterAccessoriesBy([
        ['title', 'Magnetdichtung 180°'],
        ['type', 'Dichtung']
      ]);
    },
    siteGaskets() {
      return this.filterAccessoriesBy([
        ['title', 'Streifdichtung'],
        ['type', 'Dichtung']
      ]);
    }
  },
  watch: {
    fitting() {
      this.setLipGasket();
      this.setMidGasket();
    },
    sidePlate() {
      this.setLipGasket();
      this.setMidGasket();
    },
    selectedHinge() {
      this.setLipGasket();
    },
    midGaskets() {
      this.setMidGasket();
    },
    lipGaskets() {
      this.setLipGasket();
    }
  },
  created() {
    this.setLipGasket();
    this.setMidGasket();
  },
  methods: {
    next() {
      this.$emit("nextStep", this.nextIndex);
    },
    getImgUrl(filename) {
      var images = require.context("../../assets/", false, /\.jpg$/);
      return images("./" + filename + ".jpg");
    },
    getLimitedGasket(gasket, size = this.height) {
      const copy = Object.assign({}, gasket);
      delete copy.body_html;
      delete copy.tags;
      delete copy.image;
      copy.variants = this.getVariant(copy.variants, size);
      return copy;
    },
    getVariant(variants, size) {
      let variant = this.selectVariantByOption(variants, [
        ['options.name', '==', 'Glasstärke'],
        ['options.value', '==', '8mm']
      ]);

      if (variant.length > 1) {
        variant = this.selectVariantByOption(variant, [
          ['options.name', '==', 'Länge'],
          ['options.value', '>', size - 1]
        ]);
      }
      
      return variant.length ? variant : variants;
    },
    getVariantPrice(variants, size = this.height) {
      const variant = this.getVariant(variants, size);
      return parseFloat(variant[0].price).toFixed(2);
    },
    getTitle(title) {
      const segments = title.split(' ');
      segments.forEach((el, i) => { 
        const key = `gasket_${el.replace(/\s/g, '')}`;
        segments[i] = i18n.te(key) ? `${i18n.t(key)}` : key.replace('gasket_', '');
      });

      return segments.join(' ');
    },
    openInfoBox(title, html) {
      this.$alert(html, this.getTitle(title), {
        dangerouslyUseHTMLString: true
      });
    },
    setLipGasket() {
      if (this.lipGasketNeeded) {
        this.selectedLipGasket = this.getLimitedGasket(this.lipGaskets[0]);
      } else {
        this.selectedLipGasket = undefined;
      }
    },
    setMidGasket() {
      if (
        this.midGaskets.length && !this.selectedMidGasket &&
        (this.getDoorCount > 1 || this.sidePlate !== this.fitting)
      ) {
        this.selectedMidGasket = this.getLimitedGasket(this.midGaskets[0]);
      } else {
        this.selectedMidGasket = undefined;
      }
    }
  }
};
</script>