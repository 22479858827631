<template>
  <v-group>
    <!-- Hinge -->
    <image-component
      :x="x"
      :y="y"
      :file="getFilePath()"
      :flip="flip"
      vertical="center"
      :scale="scale"
    />
    
    <v-line
      v-if="active"
      :config="{
        x: flip ? x + 5 : x - 5,
        y: y,
        points: flip ? 
          [ 10, 0, -25, 0 ] : 
          [ -10, 0, 25, 0 ],
        closed: false,
        stroke: 'rgba(200, 0, 0, 1)',
        strokeWidth: 1,
      }"
    />

    <v-line
      v-if="active"
      :config="{
        x: flip ? x + 5 : x - 5,
        y: offsetY,
        points: flip ? 
          [ 15, 0, 20, 0, 20, y - offsetY, 15, y - offsetY ] : 
          [ -15, 0, -20, 0, -20, y - offsetY, -15, y - offsetY ],
        closed: false,
        stroke: 'rgba(200, 0, 0, 1)',
        strokeWidth: 1,
      }"
    />
    <v-label
      v-if="active"
      :config="{
        x: flip ? x + 20 : x - 30,
        y: offsetY + (y / 2) + 15,
      }"
    >
      <v-tag :config="{ fill: '#fff', rotation: 270 }" />
      <v-text
        :config="{
          text: `${label} mm`,
          fontSize: 10,
          align: 'center',
          verticalAlign: 'middle',
          rotation: 270,
          width: 50
        }"
      />
    </v-label>
  </v-group>
</template>
<script>
import ImageComponent from './ImageComponent.vue';

export default {
  name: "Hinge",
  components: {
    ImageComponent
  },
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    offsetY: {
      type: Number,
      default: 0
    },
    label: {
      type: [Number,String],
      default: ""
    },
    active: {
      type: Boolean,
      default: false
    },
    flip: {
      type: Boolean,
      default: false,
      description: "Flip sites if fitting is changed from left to right"
    },
    connection: {
      type: String,
      default: 'wall',
      validator(value) {
        const acceptedValues = ['wall', 'glass'];
        return acceptedValues.includes(value.toLowerCase());
      },
    },
    material: {
      type: String,
      default: 'chrome',
      validator(value) {
        const acceptedValues = ['schwarz', 'chrome', 'edelstahl', 'edelstahl design', 'glanzverchromt'];
        return acceptedValues.includes(value.toLowerCase());
      },
    },
    form: {
      type: String,
      default: 'quadrat',
      validator(value) {
        const acceptedValues = ['quadrat', 'rechteck'];
        return acceptedValues.includes(value);
      },
    },
    type: {
      type: String,
      default: 'hinge',
      validator(value) {
        const acceptedValues = ['angle', 'hinge'];
        return acceptedValues.includes(value);
      },
    },
    scale: {
      type: Number,
      default: 1
    }
  },
  methods: {
    getFilePath() {
      return `./${this.type}/${this.connection}_${this.form}_${this.material.toLowerCase()}.svg`;
    }
  }
}
</script>