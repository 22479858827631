<template>
  <validation-observer
    ref="buttonObserver"
    v-slot="{ valid }"
  >
    <div>
      <el-row
        v-if="drillings.length > 0"
        :gutter="12"
      >
        <el-col
          :xs="12"
          :sm="6"
          :md="5"
          :lg="4"
          :xl="3"
        >
          <form-item-wrapper
            label="Abstand"
            :rules="{
              required: true,
              between: { min: 30, max: length / getDoorCount - 30 }
            }"
            use-slot
          >
            <span slot="label">
              <i class="el-icon-right" />
              {{ $t('distance') }}
            </span>
            <el-input
              v-model="pos.x"
              :name="`button_0`"
              @focus="focusInput"
              @blur="blurInput"
            >
              <span slot="suffix">mm</span>
            </el-input>
          </form-item-wrapper>
        </el-col>
        <el-col
          :xs="12"
          :sm="6"
          :md="5"
          :lg="4"
          :xl="3"
        >
          <form-item-wrapper
            label="Abstand"
            :rules="{
              required: true,
              between: { min: 30, max: height - 30 }
            }"
            use-slot
          >
            <span slot="label">
              <i class="el-icon-bottom" />
              {{ $t('distance') }}
            </span>
            <el-popover
              ref="popover"
              placement="bottom-start"
              width="150"
              trigger="focus"
            >
              <span>{{ $t('assistance') }}:<br><i class="el-icon-top" /> {{ $t('distance') }} {{ height - pos.y }} mm</span>
            </el-popover>
            <el-input
              v-model="pos.y"
              v-popover:popover
              name="button_0"
              @focus="focusInput"
              @blur="blurInput"
            >
              <span slot="suffix">mm</span>
            </el-input>
          </form-item-wrapper>
        </el-col>
      </el-row>
      <el-radio-group
        v-model="selectedButton"
        fill="#1bafd6"
        style="margin-bottom: 8px"
      >
        <el-radio-button
          v-for="btn in buttons"
          :key="btn.id"
          :label="getLimitedButton(btn)"
          :disabled="btn.diameter === 0"
          class="card"
          rules="required"
        >
          <el-button
            class="info-box"
            size="xs"
            circle
            @click="openInfoBox(btn.title, btn.body_html)"
          >
            <i class="el-icon-info info-icon" />
          </el-button>
          <div class="img-box">
            <img
              :src="btn.image?.src"
              class="image"
            >
          </div>
          <div class="caption">
            <span class="title">{{ getTitle(btn.title) }}</span>
            <div class="bottom clearfix">
              <span class="info">{{ $t('select') }}</span>
              <span class="info price">{{ calculatePrice(btn.variants) }} €</span>
            </div>
          </div>
          <div class="overlay" />
        </el-radio-button>
      </el-radio-group>
      <div
        align="right"
      >
        <el-button
          type="primary"
          :disabled="!valid || !selectedButton || selectedButton.color.toLowerCase() != farbe.toLowerCase()"
          @click="next()"
        >
          {{ $t('nextBtn') }}
        </el-button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { mapMultiRowFields, mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import i18n from "@/js/plugins/i18n";

export default {
  name: "Griff",
  props: {
    nextIndex: {
      type: Number,
      default: 4
    },
  },
  data() {
    return {
      gap: 70, 
      pos: {
        x: 0,
        y: 0
      }
    };
  },
  computed: {
    ...mapMultiRowFields(["drillings"]),
    ...mapGetters([
      "calculateShowerGlassWidth",
      "filterAccessoriesBy",
      "getSidePlateCount",
      "getDoorCount",
      "selectVariantByOption"
    ]),
    ...mapFields([
      "length",
      "length1",
      "height",
      "fitting",
      "selectedButton",
      "sidePlate",
      "farbe",
      "glassThickness"
    ]),
    buttons() {
      return this.filterAccessoriesBy([
        ['color', this.farbe],
        ['type', 'Griff']
      ]);
    },
    sidePlateLength() {
      return this.getSidePlateCount && this.fitting == 'left' ? this.length1 : 0;
    }
  },
  watch: {
    length() {
      this.calculateDrillings();
    },
    length1() {
      this.calculateDrillings();
    },
    height() {
      this.calculateDrillings();
    },
    fitting() {
      this.calculateDrillings();
    },
    sidePlate() {
      this.calculateDrillings();
    },
    buttons() {
      const buttons = this.buttons;
      // auto select if only one button
      if (buttons.length === 1) {
        this.selectedButton = this.getLimitedButton(buttons[0]);
      }
    },
    'pos.x'() {
      this.drillings[0].x = parseInt(this.pos.x);
      this.getDoorCount > 1 && 
        (this.drillings[1].x = 
          (this.calculateShowerGlassWidth - this.sidePlateLength) / this.getDoorCount - parseInt(this.pos.x));
    },
    'pos.y'() {
      this.drillings[0].y = parseInt(this.pos.y);
      this.getDoorCount > 1 && (this.drillings[1].y = parseInt(this.pos.y));
    },
    selectedButton() {
      if (this.selectedButton) {
        this.drillings[0].durchmesser = this.selectedButton.diameter;
        this.getDoorCount > 1 && (this.drillings[1].durchmesser = this.selectedButton.diameter);
      }
    }
  },
  created() {
    if (this.drillings.length == 0) {  
      this.addDrilling();

      if (this.getDoorCount > 1) {
        this.addDrilling();
      }
      this.calculateDrillings();
    }    
  },
  methods: {
    next() {
      this.$emit("nextStep", this.nextIndex);
    },
    addDrilling() {
      this.$store.commit("addDrilling");
    },
    blurInput() {
      this.$emit("activeInput", '');
    },
    calculateDrillings() {
      const length = this.calculateShowerGlassWidth / this.getDoorCount;
      if(this.fitting == 'left') {
        this.pos.x = length - this.gap;
      } else {
        this.pos.x = this.gap;
      }
      this.pos.y = this.height - 1050;
    },
    focusInput(evt) {
      evt.target.select();
      this.$emit("activeInput", evt.target.name);
    },
    getLimitedButton(btn) {
      const copy = Object.assign({}, btn);
      delete copy.body_html;
      delete copy.tags;
      delete copy.image;
      copy.variants = this.getVariant(btn.variants);
      return copy;
    },
    getVariant(variants) {
      let variant = this.selectVariantByOption(variants, [
        ['options.name', '==', 'Glasstärke'],
        ['options.value', '==', '8mm']
      ]);

      return variant.length ? variant : variants;
    },
    calculatePrice(variants) {
      const variant = this.getVariant(variants);

      return parseFloat(variant[0]?.price * this.getDoorCount).toFixed(2);
    },
    getTitle(title) {
      const segments = title.split(' ');
      segments.forEach((el, i) => { 
        const key = `button_${el.replace(/\s/g, '')}`;
        segments[i] = i18n.te(key) ? `${i18n.t(key)}` : key.replace('button_', '');
      });

      return segments.join(' ');
    },
    openInfoBox(title, html) {
      this.$alert(html, this.getTitle(title), {
        dangerouslyUseHTMLString: true
      });
    }
  }
};
</script>