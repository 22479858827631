<template>
  <div>
    <el-dialog
      :title="$t('errDialogTitle')"
      :visible.sync="errorDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span>{{ $t('errDialogMsg') }}</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="getProductDetails"
        >
          {{ $t('tryAgainBtn') }}
        </el-button>
      </span>
    </el-dialog>
    
    <component
      :is="currentComponent"
      v-if="currentComponent"
      :collection-id="collectionId"
      :what-if="whatIf"
      @loadSavedConfig="getSavedConfig"
      @notify="notify"
      @postLineItems="postLineItems"
      @addedToCart="reset"
    />

    <ElRow>
      <ElCol
        align="right"
        class="footer"
      >
        <el-tag
          v-if="getOversizeSurcharge(currentComponent) > 1"
          type="info"
          size="mini"
          style="vertical-align:middle;margin-right:5px;"
        >
          {{ $t('oversizeSurcharge') }}
        </el-tag>
        <del 
          :class="{ 'strikethrough': discountExists }" 
          class="price"
        >
          <span>
            € {{ price }}
          </span>
        </del>
        <ins class="price">
          <span
            v-if="discountExists"
          >
            € {{ discountPrice }}
          </span>
        </ins>
      </ElCol>
    </ElRow>
  </div>
</template>

<script>
import GlassKonfigurator from "@/views/GlassKonfigurator.vue";
import DuschkabinenKonfigurator from "@/views/DuschkabinenKonfigurator.vue";
import SchiebewandKonfigurator from "@/views/SchiebewandKonfigurator.vue";
import TerassenDachKonfigurator from "@/views/TerassenDachKonfigurator.vue";
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ConfiguratorContainer",
  components: {
    GlassKonfigurator,
    DuschkabinenKonfigurator,
    SchiebewandKonfigurator,
    TerassenDachKonfigurator
  },
  data() {
    return {
      errorDialog: false,
      collectionId: null,
      currentComponent: null,
      priceMethod: null,
      whatIf: false
    };
  },
  computed: {
    ...mapGetters([
      "getOversizeSurcharge"
    ]),
    ...mapFields([
      "activeStep",
      "handle",
      "variantId",
    ]),
    discountExists() {
      return this.$store.getters.discountExists();
    },
    discountPrice() {
      return this.$store.getters.calculateDiscountPrice(this.price);
    },
    price() {
      return this.priceMethod && this.$store.getters[this.priceMethod];
    },
  },
  watch: {
    activeStep() {
      this.activeStep > 1 && this.$emit("datachange");
    },
    handle() {
      if(this.isTypeOf(['duschtur', 'duschtuer'])) {
        this.currentComponent = 'DuschkabinenKonfigurator';
        this.priceMethod = 'showerPriceCalculator';
      } else if (this.isTypeOf(['schiebe'])) {
        this.currentComponent = 'SchiebewandKonfigurator';
        this.priceMethod = 'slidingPriceConfigurator';
      } else if (this.isTypeOf(['terrasse', 'terasse'])) {
        this.currentComponent = 'TerassenDachKonfigurator';
        this.priceMethod = 'terraceGlassPriceCalculator';
      } else {
        this.currentComponent = 'GlassKonfigurator';
        this.priceMethod = 'glassConfiguratorPrice';
      }
    }
  },
  mounted() {
    const whatIf = this.$route.query.whatif;
    if (
      whatIf &&
      (whatIf.toLowerCase() === "true" || parseInt(whatIf) === 1)
    ) {
      this.whatIf = true;
    }
  },
  created() {
    this.getProductDetails()
    this.getPaymentDetails();
  },
  methods: {
    notify({ title, message, type = '' }) {
      this.$notify({
        title,
        message,
        position: "bottom-right",
        offset: 100,
        type
      });
    },
    isTypeOf(types) {
      return types.some(elem => this.handle.indexOf(elem) !== -1);
    },
    getProductDetails() {
      const id = this.$route.query.id;
      if (isNaN(id) || (id.length !== 13 && id.length !== 14)) {
        this.errorDialog = true;
        return;
      }

      this.$http
        .get(`${process.env.VUE_APP_GET_PRODUCTS}${id}`)
        .then(response => {
          for (let item of response.data) {
            if(item.collection) {
              this.collectionId = item.collection;
            }
            this.$store.commit("addAvailableProduct", item);
            if (item.configurator_items) {
              this.$store.commit("addAdditionalAccessoryProducts", item.configurator_items);
            }
            this.getSavedConfig();
          }
        })
        .catch(() => {
          // TODO: SigNoz error captcha
          this.errorDialog = true;
        });
    },
    getSavedConfig() {
      let konfig_id = this.$route.query.konfig_id;
      if (konfig_id) {
        this.$http
          .get(`${process.env.VUE_APP_GET_CONFIG}${konfig_id}`)
          .then(response => {
            this.$store.commit("setAvailableProduct", response.data.glassThickness);
            this.$store.commit("setActiveProduct", response.data);
            this.$emit("finishLoading");
            this.errorDialog = false;
          })
          .catch(() => {
            this.errorDialog = true;
          });
      } else {
        this.$emit("finishLoading");
        this.errorDialog = false;
      }
    },
    getPaymentDetails() {
      this.$http
        .get(process.env.VUE_APP_GET_PAYMENT_DETAILS)
        .then(response => {
          this.$store.commit("setPaymentInformation", response.data);
        })
        .catch(() => {
          this.errorDialog = true;
        });
    },
    postLineItems(productId, items) {
      if (Object.keys(items).length === 0) { // empty
        throw 'Generate lineItems failed!';
      }
      setTimeout(() => {
        parent.postMessage(
          {
            action: "add",
            id: productId,
            basis_id: this.variantId,
            quantity: 1,
            properties: items
          },
          "*"
        );
        parent.postMessage(
          {
            action: "configurator_add",
            id: productId,
            basis_id: this.variantId,
            quantity: 1,
            properties: items
          },
          "*"
        );
      }, 500);
      setTimeout(() => {
        this.notify({
          title: this.$t('cart'),
          message: this.$t('addToCartMsg')
        });
        this.$emit("addedToCart");
      }, 700);
    },
    reset() {
      this.$emit("datachange", () => {
        this.activeStep = 1;
        this.configId = "";
      });
    }
  }
};
</script>

<style lang="css" scoped>
/deep/ .el-button--primary {
  margin-top: 16px;
}
</style>
