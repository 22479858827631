var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-observer',{ref:"formatObserver",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [(_vm.possibleFormats.length)?_c('el-row',{staticStyle:{"margin-bottom":"8px"}},[_c('el-radio-group',{attrs:{"fill":"#1bafd6","radius":"no"},model:{value:(_vm.selectedFormat),callback:function ($$v) {_vm.selectedFormat=$$v},expression:"selectedFormat"}},_vm._l((_vm.possibleFormats),function(format){return _c('el-radio-button',{key:format.title,attrs:{"label":format.title}},[_c('img',{staticStyle:{"width":"24px"},attrs:{"src":_vm.getImgUrl(format)}})])}),1)],1):_vm._e(),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"xs":12,"sm":_vm.showWeight ? 8 : 12,"md":8,"lg":6,"xl":4}},[_c('form-item-wrapper',{attrs:{"label":_vm.lengthText,"required":true,"rules":{
          required: true,
          between: { min: _vm.minWidth, max: _vm.maxWidth }
        }}},[_c('el-input',{on:{"change":_vm.lengthChanged,"focus":_vm.onFocus},model:{value:(_vm.mySelf[_vm.lengthName]),callback:function ($$v) {_vm.$set(_vm.mySelf, _vm.lengthName, _vm._n($$v))},expression:"mySelf[lengthName]"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("mm")])])],1)],1),(_vm.showHeight)?_c('el-col',{attrs:{"xs":12,"sm":_vm.showWeight ? 8 : 12,"md":8,"lg":6,"xl":4}},[_c('form-item-wrapper',{attrs:{"label":_vm.heightText,"required":true,"rules":{
          required: true,
          between: { min: _vm.minHeight, max: _vm.maxHeight }
        }}},[_c('el-input',{on:{"change":_vm.heightChanged,"focus":_vm.onFocus},model:{value:(_vm.mySelf[_vm.heightName]),callback:function ($$v) {_vm.$set(_vm.mySelf, _vm.heightName, _vm._n($$v))},expression:"mySelf[heightName]"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("mm")])])],1)],1):_vm._e(),(_vm.showWeight)?_c('el-col',{attrs:{"xs":8,"sm":7,"md":8,"lg":5,"xl":3}},[_c('form-item-wrapper',{attrs:{"label":_vm.$t('weight'),"rules":{ required: true }}},[_c('el-input',{attrs:{"disabled":true,"value":_vm.calculateWeight()}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("kg")])])],1)],1):_vm._e()],1),(_vm.showThickness)?_c('GlassThickness',{staticStyle:{"margin":"14px 0 8px"},attrs:{"thickness-title":_vm.$t('glassThickness')}}):_vm._e(),(_vm.hasNextListener)?_c('div',{attrs:{"align":"right"}},[_c('el-button',{attrs:{"type":"primary","disabled":invalid},on:{"click":function($event){return _vm.$emit('nextStep', 1)}}},[_vm._v(" "+_vm._s(_vm.$t('nextBtn'))+" ")])],1):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }