<template>
  <validation-observer
    ref="buttonObserver"
    v-slot="{ valid }"
  >
    <div>
      <el-checkbox-group
        v-model="selectedStabilization"
        fill="#1bafd6"
        style="margin-bottom: 8px"
        @change="onChange"
      >
        <el-checkbox-button
          v-for="bar in stabilizationBars"
          :key="bar.id"
          :label="getLimitedStabilizationBar(bar)"
          class="card"
        >
          <svg 
            class="check-mark"
            width="80"
            height="80"
            viewBox="0 0 250 250"
          >
            <path
              d="m0 0h250v250h-250z"
              fill-rule="evenodd"
              stroke="none"
            />
            <path
              d="m46.196 74.921 22.882 22.882 47.225-47.225"
              fill="none"
              stroke-linecap="round"
              stroke-width="7.8125"
            />
          </svg>
          <div class="img-box">
            <img
              :src="bar.image?.src"
              class="image"
            >
          </div>
          <el-button
            class="info-box"
            size="xs"
            circle
            @click="openInfoBox(bar.title, bar.body_html)"
          >
            <i class="el-icon-info info-icon" />
          </el-button>
          <div class="caption">
            <span class="title">{{ getTitle(bar.title) }}</span>
            <div class="bottom clearfix">
              <span class="info">{{ $t('select') }}</span>
              <span class="info price">{{ parseFloat(bar.variants[0].price).toFixed(2) }} €</span>
            </div>
          </div>
          <div class="overlay" />
        </el-checkbox-button>
      </el-checkbox-group>
      <el-alert
        v-if="selectedStabilization.length === 0"
        title="Wir empfehlen ausdrücklich die Verwendung einer Stabilisierungsstange."
        type="warning"
        :closable="false"
      />
      <div
        align="right"
      >
        <el-button
          type="primary"
          :disabled="!valid || selectedStabilization.length > 0 && selectedStabilization[0].color.toLowerCase() != farbe.toLowerCase()"
          @click="next()"
        >
          {{ $t('nextBtn') }}
        </el-button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import i18n from "@/js/plugins/i18n";

export default {
  name: "Stabilisierungsstange",
  props: {
    nextIndex: {
      type: Number,
      default: 4
    },
  },
  computed: {
    ...mapFields([
      "selectedStabilization",
      "farbe"
    ]),
    stabilizationBars() {
      return this.$store.getters.filterAccessoriesBy([
        ['color', this.farbe],
        ['type', 'Stabilisierungsstange']
      ]);
    }
  },
  watch: {
    stabilizationBars() {
      const bars = this.stabilizationBars;
      // auto select
      if (bars.length > 0) {
        this.selectedStabilization = [this.getLimitedStabilizationBar(bars[0])];
      }
    }
  },
  methods: {
    next() {
      this.$emit("nextStep", this.nextIndex);
    },
    getLimitedStabilizationBar(bar) {
      const copy = Object.assign({}, bar);
      delete copy.body_html;
      delete copy.tags;
      delete copy.image;
      return copy;
    },
    getTitle(title) {
      const segments = title.split(' ');
      segments.forEach((el, i) => { 
        const key = `stabilization_${el.replace(/\s/g, '')}`;
        segments[i] = i18n.te(key) ? `${i18n.t(key)}` : key.replace('stabilization_', '');
      });

      return segments.join(' ');
    },
    onChange(value) {
      if (value.length > 0) {
        this.selectedStabilization = [value.at(-1)];
      } else {
        this.selectedStabilization = [];
      }
    },
    openInfoBox(title, html) {
      this.$alert(html, this.getTitle(title), {
        dangerouslyUseHTMLString: true
      });
    }
  }
};
</script>