<template>
  <v-group>
    <v-line
      v-if="position == 'side'"
      :config="{
        x: x,
        y: y,
        points: [
          0, 0,
          0, height
        ],
        closed: false,
        stroke: getColor(),
        strokeWidth: thickness,
      }"
    />
    <v-line
      v-if="position == 'side'"
      :config="{
        x: x,
        y: y,
        points: [
          length, height,
          length, 0
        ],
        closed: false,
        stroke: getColor(),
        strokeWidth: thickness,
      }"
    />

    <v-line
      v-if="position == 'bottom'"
      :config="{
        x: x,
        y: y,
        points: [
          0, height,
          length, height
        ],
        closed: false,
        stroke: getColor(),
        strokeWidth: thickness,
      }"
    />
  </v-group>
</template>
<script>
export default {
  name: "Gasket",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: 'transparent',
      validator(value) {
        const acceptedValues = ['schwarz', 'transparent'];
        return acceptedValues.includes(value.toLowerCase());
      },
    },
    position: {
      type: String,
      default: 'side',
      validator(value) {
        const acceptedValues = ['bottom', 'side'];
        return acceptedValues.includes(value.toLowerCase());
      },
    },
    thickness: {
      type: Number,
      default: 7
    }
  },
  methods: {
    getColor() {
      switch(this.color.toLowerCase()) {
        case 'schwarz':
          return 'rgb(0, 0, 0)';
        case 'transparent':
          return 'rgb(224, 225, 232)';
      }
    }
  }
}
</script>