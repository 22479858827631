<template>
  <div>
    <template>
      <el-checkbox-group
        v-model="selectedAccessories"
        fill="#1bafd6"
        style="margin-bottom: 8px"
      >
        <el-checkbox-button
          v-for="(extra, index) in optionalAccessories"
          :key="'acc_'+index"
          :label="getLimitedAcc(extra)"
          :checked="true"
          :disabled="true"
          class="card"
        >
          <svg 
            class="check-mark"
            width="80"
            height="80"
            viewBox="0 0 250 250"
          >
            <path
              d="m0 0h250v250h-250z"
              fill-rule="evenodd"
              stroke="none"
            />
            <path
              d="m46.196 74.921 22.882 22.882 47.225-47.225"
              fill="none"
              stroke-linecap="round"
              stroke-width="7.8125"
            />
          </svg>
          <div class="img-box">
            <img
              :src="extra.image?.src"
              class="image"
            >
          </div>
          <el-button
            class="info-box"
            size="xs"
            circle
            @click="
              openInfoBox(`${extra.title}`, getVariant(extra.variants, extra.offset)?.at(0).title)
            "
          >
            <i class="el-icon-info info-icon" />
          </el-button>
          <div class="caption">
            <span class="title">{{ extra.title }}</span>
            <div class="bottom clearfix">
              <span class="info">{{ $t('select') }}</span>
              <span class="info price">{{ getVariantPrice(extra.variants, extra.offset) }} €</span>
            </div>
          </div>
          <div class="overlay" />
        </el-checkbox-button>

        <el-tooltip 
          v-for="(extra, index) in extras"
          :key="index"
          :disabled="isDisabled(extra)"
          effect="light"
          :content="isBlocked(extra) ? $t('highDemandMsg') : $te(`${extra}Msg`) ? $t(`${extra}Msg`) : ''"
          placement="bottom"
          :visible-arrow="false"
        >
          <el-checkbox-button
            :label="extra"
            :disabled="isBlocked(extra) || disabledAccessories.includes(extra)"
            class="card"
          >
            <svg 
              class="check-mark"
              width="80"
              height="80"
              viewBox="0 0 250 250"
            >
              <path
                d="m0 0h250v250h-250z"
                fill-rule="evenodd"
                stroke="none"
              />
              <path
                d="m46.196 74.921 22.882 22.882 47.225-47.225"
                fill="none"
                stroke-linecap="round"
                stroke-width="7.8125"
              />
            </svg>
            <div class="img-box">
              <img
                :src="getImgUrl(extra)"
                class="image"
              >
            </div>
            <el-button
              class="info-box"
              size="xs"
              circle
              @click="openInfoBox($t(extra), getContent(extra))"
            >
              <i class="el-icon-info info-icon" />
            </el-button>
            <div class="caption">
              <span class="title">{{ $t(extra) }}</span>
              <div class="bottom clearfix">
                <span class="info">{{ getInfo(extra) }}</span>
                <span class="info price">{{ getPrice(extra) }}</span>
              </div>
            </div>
            <div class="overlay" />
          </el-checkbox-button>
        </el-tooltip>
      </el-checkbox-group>
    </template>
    <h2>{{ $t('notes') }}</h2>
    <el-input
      v-model="additionalInfo"
      type="textarea"
      class="additional-info"
    />
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import i18n from "@/js/plugins/i18n";
export default {
  name: "Extras",
  data() {
    return {
      blockedFrom: new Date(process.env.VUE_APP_EXPRESS_BLOCKED_FROM),
      blockedTo: new Date(process.env.VUE_APP_EXPRESS_BLOCKED_TO),
      extras: ['glassLabel', 'expressProduction'],
      disabledAccessories: []
    }
  },
  computed: {
    ...mapFields([
      "additionalInfo",
      "productionTime",
      "expressProductionTime",
      "handle",
      "heatSoak",
      "stamp",
      "lotus",
      "frames",
      "selectedAccessories"
    ]),
    ...mapGetters([
      "filterAccessoriesBy",
      "selectVariantByOption",
      "lotusLayerPrice",
      "heatSoakPrice"
    ]),
    optionalAccessories() {
      return this.filterAccessoriesBy([
        ['type', 'Zubehör']
      ]);
    },
  },
  watch: {
    expressProductionTime() {
      if (!this.expressProductionTime) {
        this.removeFromArray(this.extras, 'expressProduction');
      }
    },
    heatSoak() {
      if (this.heatSoak) {
        this.addToArray(this.extras, 0, 'heatSoak');
      }
    },
    selectedAccessories() {
      this.disabledAccessories = [];
      if (this.isOptishower()) {
        this.addToArray(this.selectedAccessories, 0 , 'glassLabel');
        this.addToArray(this.disabledAccessories, 0 , 'glassLabel');
      }
      if (this.selectedAccessories.includes('heatSoak')) {
        this.addToArray(this.selectedAccessories, 0 , 'glassLabel');
        this.addToArray(this.disabledAccessories, 0 , 'expressProduction');
        this.addToArray(this.disabledAccessories, 0 , 'glassLabel');
      }
      if (this.selectedAccessories.includes('expressProduction')) {
        this.addToArray(this.disabledAccessories, 0 , 'heatSoak');
      }
    }
  },
  created() {
    if (this.handle) {
      if (
        (this.handle.includes('isolierglas') || this.lotus) && 
        !this.isOptishower() &&
        !/hust|simple/i.test(this.handle) &&
        this.frames.length == 0
      ) {
        this.addToArray(this.extras, 0, 'lotusLayer');
      }

      if (!this.stamp) {
        this.removeFromArray(this.selectedAccessories, 'glassLabel');
        this.removeFromArray(this.extras, 'glassLabel');
      }

      if (this.heatSoak) {
        this.addToArray(this.extras, 0, 'heatSoak');
      }
    }
  },
  methods: {
    addToArray(arr, index, item) {
      if (!arr.includes(item)) {
        arr.splice(index, 0, item);
      }
    },
    removeFromArray(arr, item) {
      if (arr.includes(item)) {
        arr.splice(arr.indexOf(item), 1);
      }
    },
    getContent(extra) {
      let key = 'Text';

      if (this.selectedAccessories.includes('heatSoak')) {
        key = 'HlText';
      } else if (this.isOptishower()) {
        key = 'OptishowerText';
      }

      return i18n.te(`${extra}${key}`) ?
        i18n.t(`${extra}${key}`) : i18n.t(`${extra}Text`);
    },
    getLimitedAcc(acc) {
      const copy = Object.assign({}, acc);
      delete copy.body_html;
      delete copy.tags;
      delete copy.image;
      copy.variants = this.getVariant(copy.variants, copy.offset);
      return copy;
    },
    getInfo(extra) {
      if (extra === 'expressProduction') {
        return this.selectedAccessories.includes('expressProduction') ?
          this.expressProductionTime : this.productionTime;
      }
      return i18n.t('select');
    },
    getPrice(extra) {
      if (extra === 'glassLabel') {
        return '';
      } else if (extra === 'expressProduction') {
        return '50% Aufschlag';
      }
      return `${this[`${extra}Price`]} €`;
    },
    getVariant(variants, thickness) {
      let variant = this.selectVariantByOption(variants, [
        ['options.name', '*=', 'Materialstärke'],
        ['options.value', '*=', `${thickness} mm`]
      ]);

      if (variant.length > 1) {
        variant = this.selectVariantByOption(variant, [
          ['options.name', '==', 'Anzahl'],
          ['options.value', '*=', '4 Stück']
        ]);
      }
      
      return variant;
    },
    getVariantPrice(variants, thickness) {
      const variant = this.getVariant(variants, thickness);
      return parseFloat(variant[0].price).toFixed(2);
    },
    isBlocked(extra) {
      const currentDate = new Date();
      return extra === 'expressProduction' ?
        this.blockedFrom <= currentDate && this.blockedTo >= currentDate :
        false;
    },
    isDisabled(extra) {
      if (extra === 'expressProduction' && this.isBlocked(extra)) {
        return false;
      } else {
        return !this.disabledAccessories.includes(extra) || !i18n.te(`${extra}Msg`);
      }
    },
    isOptishower() {
      return /optishower|anti-kalk/i.test(this.handle);
    },
    openInfoBox(title, html) {
      this.$alert(html, title, {
        dangerouslyUseHTMLString: true,
        showClose: false
      });
    },
    getImgUrl(filename) {
      var images = require.context("../assets/", false, /\.jpg$/);
      return images("./" + filename + ".jpg");
    }
  }
};
</script>
