import Vue from "vue";
import Vuex from "vuex";
import { getField, updateField } from "vuex-map-fields";
import i18n from "@/js/plugins/i18n";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeStep: 1,
    maxStep: 1,
    productId: undefined,
    productTitle: undefined,
    sku: undefined,
    availableProducts: [],
    drillings: [],
    weightPerSqm: 1,
    handle: undefined,
    selectedAccessories: ['glassLabel'],
    additionalInfo: "",
    selectedFormat: i18n.t('formRectangle'),
    lanes: 1,
    length: 1,
    length1: undefined,
    length2: undefined,
    lock: false,
    height: 1,
    height1: undefined,
    glassColor: undefined,
    glassDesign: undefined,
    glassProcessing: undefined,
    glassThickness: undefined,
    glassKind: undefined,
    farbig: false,
    farbe: undefined,
    flipped: false,
    selectedHex: undefined,
    minWidth: 600,
    minHeight: 1400,
    maxWidth: undefined,
    maxHeight: undefined,
    productionTime: undefined,
    expressProductionTime: undefined,
    pricePerSquaremeter: undefined,
    priceExpressProduction: undefined,
    priceHeatSoak: undefined,
    priceLotusCoating: 0,
    oversizeSurcharge: undefined,
    stamp: false,
    heatSoak: false,
    lotus: true,
    edgeHemmed: true,
    addCustomImg: false,
    imgToLoad: null,
    imageUrl: null,
    printPdf: null,
    variantId: undefined,
    corners: {
      oben_links: {
        x: 0,
        y: 0,
        r: 0,
        name: i18n.t('cornerPointed')
      },
      oben_mitte: {
        x: 0,
        y: 0,
        r: 0,
        name: i18n.t('cornerPointed')
      },
      oben_rechts: {
        x: 0,
        y: 0,
        r: 0,
        name: i18n.t('cornerPointed')
      },
      unten_rechts: {
        x: 0,
        y: 0,
        r: 0,
        name: i18n.t('cornerPointed')
      },
      unten_links: {
        x: 0,
        y: 0,
        r: 0,
        name: i18n.t('cornerPointed')
      }
    },
    priceColor: false,
    priceCuttingEdge: false,
    priceHemmedEdge: false,
    priceFacetEdge: false,
    minPriceFrame: false,
    priceFrame: false,
    priceHonedEdge: false,
    pricePolishedEdge: false,
    priceWhiteGap: false,
    priceBlackGap: false,
    priceButton: false,
    priceLock: false,
    priceOpeningDirection: false,
    discounts: undefined,
    discount: 0,
    selectedEdge: "",
    selectedGap: "",
    surface: undefined,
    uploadData: [],
    drillingsCounter: 0,
    configId: "",
    config_url: undefined,

    accessories: [],

    // Shower Stuff
    hingePositions: [],
    selectedAngle: undefined,
    selectedHinge: undefined,
    selectedButton: undefined,
    selectedBottomGasket: undefined,
    selectedLipGasket: undefined,
    selectedMidGasket: undefined,
    selectedSideGasket: undefined,
    selectedStabilization: [],
    fitting: "left",
    priceCutout: undefined,
    sidePlate: "right",

    // Terassendach Stuff
    terraceGlasses: [
      {
        height: 600,
        width: undefined
      }
    ],
    onlyFrontEdge: false,
    terraceWidth: 0,
    individualTerraceGlassSizes: false,
    selectedKlemmdeckel: undefined,
    selectedHaltewinkel: undefined,
    recommendedMaxWidthInMm: undefined,
    frames: [],
    selectedFrame: undefined,
    frameThickness: 10,
    barWidthInMm: 60,
    substructure: undefined,
    isJson: (item) => {
      let value = typeof item !== "string" ? JSON.stringify(item) : item;
      try {
        value = JSON.parse(value);
      } catch (e) {
        return false;
      }

      return typeof value === "object" && value !== null;
    }
  },
  getters: {
    //#region global getters
    getField,
    getActiveProduct: state => {
      let activeProduct = {
        selectedFormat: state.selectedFormat,
        lanes: Number.parseInt(state.lanes),
        length: Number.parseInt(state.length),
        length1: Number.parseInt(state.length1),
        length2: Number.parseInt(state.length2),
        height: Number.parseInt(state.height),
        height1: Number.parseInt(state.height1),
        glassThickness: state.glassThickness,
        glassKind: state.glassKind,
        corners: state.corners,
        drillingsCounter: state.drillingsCounter,
        configId: state.configId,
        maxStep: state.maxStep,
        activeStep: state.activeStep,
        barWidthInMm: state.barWidthInMm,
        fitting: state.fitting,
        sidePlate: state.sidePlate
      };
      state.drillings.length > 0 && (activeProduct.drillings = state.drillings);
      state.additionalInfo && (activeProduct.additionalInfo = state.additionalInfo);
      state.selectedAccessories && (activeProduct.selectedAccessories = state.selectedAccessories);
      state.lock && (activeProduct.lock = state.lock);
      state.farbe && (activeProduct.farbe = state.farbe);
      state.farbig && (activeProduct.farbig = state.farbig);
      state.flipped && (activeProduct.flipped = state.flipped);
      state.selectedEdge && (activeProduct.selectedEdge = state.selectedEdge);
      state.selectedGap && (activeProduct.selectedGap = state.selectedGap);
      state.selectedHex && (activeProduct.selectedHex = state.selectedHex);
      state.surface && (activeProduct.surface = state.surface);
      state.uploadData.length > 0 && (activeProduct.uploadData = state.uploadData);
      state.imgToLoad && (activeProduct.imgToLoad = state.imgToLoad);
      state.printPdf && (activeProduct.printPdf = state.printPdf);
      state.terraceWidth > 0 &&
        (activeProduct.terraceWidth = state.terraceWidth) &&
        (activeProduct.terraceGlasses = state.terraceGlasses);
      state.onlyFrontEdge && (activeProduct.onlyFrontEdge = state.onlyFrontEdge);
      state.individualTerraceGlassSizes && (activeProduct.individualTerraceGlassSizes = state.individualTerraceGlassSizes);
      state.selectedKlemmdeckel && (activeProduct.selectedKlemmdeckel = state.selectedKlemmdeckel);
      state.selectedHaltewinkel && (activeProduct.selectedHaltewinkel = state.selectedHaltewinkel);
      state.selectedFrame && (activeProduct.selectedFrame = state.selectedFrame);
      state.substructure && (activeProduct.substructure = state.substructure);
      state.hingePositions.length > 0 && (activeProduct.hingePositions = state.hingePositions);
      state.selectedAngle && (activeProduct.selectedAngle = state.selectedAngle);
      state.selectedHinge && (activeProduct.selectedHinge = state.selectedHinge);
      state.selectedButton && (activeProduct.selectedButton = state.selectedButton);
      state.selectedBottomGasket && (activeProduct.selectedBottomGasket = state.selectedBottomGasket);
      state.selectedLipGasket && (activeProduct.selectedLipGasket = state.selectedLipGasket);
      state.selectedMidGasket && (activeProduct.selectedMidGasket = state.selectedMidGasket);
      state.selectedSideGasket && (activeProduct.selectedSideGasket = state.selectedSideGasket);
      state.selectedStabilization && (activeProduct.selectedStabilization = state.selectedStabilization);

      return activeProduct;
    },
    getShopifyGlassKonfiguratorProduct: (state, getters) => {
      const shopifyProductObject = {
        product: {
          input: {},
          media: []
        },
        variants: []
      };

      shopifyProductObject.product.input = {
        title: `${i18n.tc('pane', 1)} ${i18n.t('individualConfig')} ${state.length} x ${state.height
          }`,
        vendor: "Glas Strack",
        tags: [
          "konfiguriert",
          `basis_produkt_${state.productId}`,
          `basis_handle_${state.handle}`,
        ],
        metafields: [],
        templateSuffix: "noindex"
      };
      shopifyProductObject.variants = [{
        price: getters.calculateDiscountPrice(getters.glassConfiguratorPrice),
        inventoryItem: {
          measurement: {
            weight: {
              unit: "KILOGRAMS",
              value: parseFloat(getters.calculateWeight())
            }
          },
          tracked: false
        }
      }];

      if (state.selectedFormat == i18n.t('formSpecRect')) {
        shopifyProductObject.product.input.title += ` x ${state.height1}`;
      }

      if (state.imageUrl) {
        shopifyProductObject.product.media.push({
          originalSource: state.imageUrl,
          mediaContentType: "IMAGE"
        });
      }

      shopifyProductObject.product.input.metafields.push({
        namespace: "glasstar",
        key: "konfig_url",
        value: state.config_url,
        type: "url"
      });

      return shopifyProductObject;
    },
    getShopifyGlassKonfiguratorCornerHtml: () => (text, corner) => {
      let html = '';
      if (corner.name === i18n.t('cornerBevelled')) {
        html = `<li>${text}: ${corner.x} x ${corner.y} mm, angeschrägt</li>`;
      } else if (corner.name === i18n.t('cornerRounded')) {
        html = `<li>${text}: ${corner.r} mm Radius, rund</li>`;
      }
      return html;
    },
    getShopifyGlassKonfiguratorCartHtml: (state, getters) => {
      let dimension = [state.length, state.height];
      let formData = '';
      let cornerHtml = '';
      let drillingsHtml = '';
      let colorHtml = '';
      let extraHtml = '';
      let infoHtml = '';

      if ([i18n.t('formSpecRect'), i18n.t('formPentagon')].includes(state.selectedFormat)) {
        dimension.push(state.height1);
      }
      if ([
        i18n.t('formParallelogram'), i18n.t('formPentagon'), i18n.t('formTrapezium'), i18n.t('formTriangle')
      ].includes(state.selectedFormat) && state.length1 > 0
      ) {
        dimension.push(state.length1);
      }
      if (state.selectedFormat == i18n.t('formTrapezium')) {
        dimension.push(state.length2);
      }

      if (state.glassKind === "hust") {
        formData = `<li>Ausgabebreite: ${state.length1}</li><li>Ausgabehöhe: ${state.height1}</li>`;
      }
      if (state.selectedGap) {
        formData += `<li>Scheibenzwischenraum: ${state.selectedGap}</li>`;
      }
      if (state.printPdf) {
        formData += `<li>Druckdatei: ${state.printPdf}</li>`;
      }

      if (state.selectedFrame) {
        formData += `<li>Rahmen: ${state.selectedFrame}</li>`;
      }

      cornerHtml = getters.getShopifyGlassKonfiguratorCornerHtml('Oben links', state.corners.oben_links);
      cornerHtml += getters.getShopifyGlassKonfiguratorCornerHtml('Oben rechts', state.corners.oben_rechts);
      cornerHtml += getters.getShopifyGlassKonfiguratorCornerHtml('Unten rechts', state.corners.unten_rechts);
      cornerHtml += getters.getShopifyGlassKonfiguratorCornerHtml('Unten links', state.corners.unten_links);
      if (cornerHtml) {
        cornerHtml = `<li>Ecken <ul class="cart_corner-data">${cornerHtml}</ul></li>`
      }

      if (state.uploadData[0] || state.drillings.length) {
        if (state.drillings.length) {
          let items = '';
          for (let drilling of state.drillings) {
            items += `<li>Ø: ${drilling.durchmesser}, Abstand links: ${drilling.x}, Abstand oben: ${drilling.y}</li>`;
          }
          drillingsHtml = `<ol class="cart_drilling-data">${items}</ol>`;
        }
        if (state.uploadData[0]) {
          drillingsHtml += `<ul class="cart_drilling-data">
            <li>Skizze: ${state.uploadData[0].url}</li>
            <li>Anzahl: ${state.drillingsCounter}</li>
          </ul>`;
        }

        drillingsHtml = `<li>Bohrungen (in mm) ${drillingsHtml}</li>`;
      }

      if (state.farbe) {
        colorHtml = `<li>Farbton: ${state.farbe}</li>`;
      }

      state.selectedAccessories.forEach((extra) => {
        if (state.expressProductionTime && extra === 'expressProduction') {
          extraHtml += '<li>Expressproduktion</li>';
        } else if (state.heatSoak && extra === 'heatSoak') {
          extraHtml += '<li>Heat-Soak-Test</li>';
        } else if (extra === 'lotusLayer') {
          extraHtml += '<li>Lotusbeschichtung</li>';
        } else if (state.stamp && extra === 'glassLabel') {
          extraHtml += '<li>Normierungsstempel</li>';
        }
      });

      if (extraHtml) {
        extraHtml = `<li>Extras <ul class="cart_extra-data">${extraHtml}</ul></li>`
      }
      if (getters.getOversizeSurcharge() > 1) {
        extraHtml += `<li>${i18n.t('oversizeSurcharge')}</li>`;
      }

      if (state.additionalInfo) {
        infoHtml = `<li>Info: ${state.additionalInfo}</li>`;
      }

      let htmlCardDetails = `<ul class="conf-details">
        <li>Produkt: ${state.productTitle}</li>
        <li>Format 
          <ul class="cart_form-data">
            <li>Form: ${state.selectedFormat} <br>(${dimension.join(' x ')})</li>
            ${formData}
            <li>Dicke: ${state.glassThickness} mm</li>
            <li>Gewicht: ${getters.calculateWeight()} kg</li>
          </ul>
        </li>
        <li>Kanten: ${state.selectedEdge}</li>
        ${cornerHtml}${drillingsHtml}${colorHtml}${extraHtml}${infoHtml}
      </ul>`;

      return htmlCardDetails;
    },
    getShopifyGlassKonfiguratorLineItems: (state, getters) => {
      let shopifyLineItems = {
        _titel: `${i18n.tc('pane', 1)} ${i18n.t('individualConfig')} ${state.length} x ${state.height}`,
        _preis: getters.calculateDiscountPrice(getters.glassConfiguratorPrice) * 100,
        Produkt: state.productTitle,
        Form: state.selectedFormat,
        Modellnummer: getters.getShapeNumber,
        _breite_mm: state.length,
        _hoehe_mm: state.height,
        _Artikelnummer: state.sku,
        _basis_variant_id: state.variantId
      };

      if ([i18n.t('formSpecRect'), i18n.t('formPentagon')].includes(state.selectedFormat)) {
        Object.assign(shopifyLineItems, { _hoehe_1_mm: state.height1 });
      }

      if ([
        i18n.t('formParallelogram'), i18n.t('formPentagon'), i18n.t('formTrapezium'), i18n.t('formTriangle')
      ].includes(state.selectedFormat) && state.length1 > 0
      ) {
        Object.assign(shopifyLineItems, { _breite_1_mm: state.length1 });
      }

      if (state.selectedFormat == i18n.t('formTrapezium')) {
        Object.assign(shopifyLineItems, { _breite_2_mm: state.length2 });
      }

      if (state.glassKind === "hust") {
        Object.assign(shopifyLineItems, {
          _ausgabebreite: state.length1,
          _ausgabehoehe: state.height1
        });
      }

      if (state.selectedFrame) {
        Object.assign(shopifyLineItems, { Rahmen: state.selectedFrame });
      }

      Object.assign(shopifyLineItems, {
        _dicke_mm: state.glassThickness,
        _gewicht_g: (getters.calculateWeight() * 1000).toFixed(2),
        _ecke_oben_links: state.corners.oben_links,
        _ecke_oben_rechts: state.corners.oben_rechts,
        _ecke_unten_rechts: state.corners.unten_rechts,
        _ecke_unten_links: state.corners.unten_links,
        _Kanten: state.selectedEdge,
        _loecher: state.drillings,
        _Info: state.additionalInfo
      });

      if (state.imageUrl) {
        Object.assign(shopifyLineItems, { _productImage: state.imageUrl });
      }

      if (state.selectedGap) {
        Object.assign(shopifyLineItems, { _Scheibenzwischenraum: state.selectedGap });
      }

      if (state.farbe) {
        Object.assign(shopifyLineItems, { _Farbton: state.farbe });

      }

      if (getters.discountExists) {
        Object.assign(shopifyLineItems, { _Rabatt: state.discount });
      }

      if (state.expressProductionTime) {
        Object.assign(shopifyLineItems, {
          _Expressproduktion: state.selectedAccessories.includes('expressProduction') ? "Ja" : "Nein"
        });
      }
      if (state.heatSoak && state.selectedAccessories.includes('heatSoak')) {
        Object.assign(shopifyLineItems, { _heat_soak_test: "Ja" });
      }
      if (state.selectedAccessories.includes('lotusLayer')) {
        Object.assign(shopifyLineItems, { _Lotusbeschichtung: "Ja" });
      }
      if (state.stamp) {
        Object.assign(shopifyLineItems, {
          _normierungsstempel: state.selectedAccessories.includes('glassLabel') ? "Ja" : "Nein"
        });
      }

      if (state.uploadData[0]) {
        Object.assign(shopifyLineItems, {
          _anzahl: state.drillingsCounter,
          _skizze: state.uploadData[0].url
        });
      }

      if (state.printPdf) {
        Object.assign(shopifyLineItems, {
          _Druckdatei: state.printPdf
        });
      }

      Object.assign(shopifyLineItems, {
        _html: getters.getShopifyGlassKonfiguratorCartHtml
      });

      return shopifyLineItems;
    },
    discountExists: state => () => {
      const discounts = state.discounts;
      if (discounts) {
        for (let discount of discounts) {
          const now = new Date().getTime();
          const expire = discount.valid_until ? new Date(discount.valid_until).getTime() : 0;

          if (now <= expire &&
            state.handle &&
            (discount.exclusion == null || state.handle.indexOf(discount.exclusion.toLowerCase()) === -1) &&
            (discount.inclusion == null || state.handle.indexOf(discount.inclusion.toLowerCase()) > -1)
          ) {
            state.discount = discount;
            return parseFloat(state.discount?.value || state.discount) > 0;
          }
        }
      }

      state.discount = 0;
      return false;
    },
    calculateDiscountPrice: (state, getters) => price => {
      if (getters.discountExists()) {
        let discount = parseFloat(state.discount?.value || state.discount);
        discount = price * discount;

        return isNaN(discount) ? "-" : (price - discount).toFixed(2);
      }
      return price
    },
    selectedFramePrice: state => {
      let price = 0;
      for (let frame of state.frames) {
        let name = frame.name.replace(/\s/g, '');
        name = `${i18n.t(`frame${name}`)} ${frame.size}`;
        if (state.selectedFrame == name) {
          price = frame.price;
          break;
        }
      }
      return price;
    },
    calculateFramePrice: (state, getters) => {
      const price = getters.selectedFramePrice;
      return ((state.length / 1000) * (state.height / 1000)) * price;
    },
    glassConfiguratorPrice: (state, getters) => {
      function round(i) {
        return parseFloat(Math.round(i * 100) / 100).toFixed(2);
      }

      const areaPrice = getters.calculateAreaPrice;
      const edgePrice = getters.calculateEdgePrice;
      let holePrice = state.priceHole ? state.drillings.length * state.priceHole : 0;
      state.uploadData.length == 1 &&
        (holePrice += state.drillingsCounter * state.priceHole);

      let gapPrice = 0;
      if (state.selectedGap.includes(i18n.t('gapWhiteTitle'))) {
        gapPrice = state.priceWhiteGap * getters.calculateRunningMeter();
      } else if (state.selectedGap.includes(i18n.t('gapBlackTitle'))) {
        gapPrice = state.priceBlackGap * getters.calculateRunningMeter();
      }
      const framePrice = getters.calculateFramePrice;
      const cornerPrice = getters.countRoundedCorners * state.priceRoundedCorner;
      const lotusPrice = state.selectedAccessories.includes('lotusLayer')
        ? state.priceLotusCoating * getters.calculateAreaWithMin(state.length, state.height, 1)
        : 0;
      const heatSoakPrice = state.heatSoak && state.selectedAccessories.includes('heatSoak')
        ? state.priceHeatSoak * state.glassThickness * getters.calculateAreaWithMin(state.length, state.height, 1)
        : 0;

      let price = areaPrice + holePrice + edgePrice + gapPrice + framePrice + cornerPrice + lotusPrice + heatSoakPrice;
      const expressPrice = getters.calculateExpressProductionPrice(price);
      return isNaN(price) ? "-" : round(price + expressPrice);
    },
    calculateWeight: (state, getters) => area => {
      area = area ?? getters.calculateArea();
      return (area * state.weightPerSqm).toFixed(1);
    },
    calculateArea: state => (length, height) => {
      const lengthInM = (length ? length : state.frames.length ? state.length - state.frameThickness : state.length) / 1000;
      const heightInM = (height ? height : state.frames.length ? state.height - state.frameThickness : state.height) / 1000;
      return lengthInM * heightInM;
    },
    calculateAreaWithMin: (state, getters) => (length, height, min) => {
      const calculatedArea = getters.calculateArea(length, height);
      const minArea = min ? min : state.minArea;
      return calculatedArea > minArea ? calculatedArea : minArea;
    },
    calculateAreaPrice: (state, getters) => {
      let surcharge = 1;
      [i18n.t('formSpecRect'), i18n.t('formPentagon')].includes(state.selectedFormat) && (surcharge = 1.4);
      [i18n.t('formTriangle'), i18n.t('formTrapezium')].includes(state.selectedFormat) && (surcharge = 1.5);
      state.selectedFormat == i18n.t('formParallelogram') && (surcharge = 1.6);
      state.selectedFormat == i18n.t('formEllipse') && (surcharge = 2.5);

      return getters.calculateAreaWithMin() * state.pricePerSquaremeter * surcharge * getters.getOversizeSurcharge();
    },
    calculateEdgePrice: (state, getters) => {
      let edgePrice = 0;

      if (state.selectedEdge.includes(i18n.t('edgeSharpTitle'))) {
        edgePrice = state.priceCuttingEdge;
      } else if (state.selectedEdge.includes(i18n.t('edgeLinedTitle'))) {
        edgePrice = state.priceHemmedEdge;
      } else if (state.selectedEdge.includes(i18n.t('edgeMattTitle'))) {
        edgePrice = state.priceHonedEdge;
      } else if (state.selectedEdge.includes(i18n.t('edgeHighGlossTitle'))) {
        edgePrice = state.pricePolishedEdge;
      } else if (state.selectedEdge.includes(i18n.t('edgeFacetTitle'))) {
        edgePrice = state.priceFacetEdge;
      }

      return edgePrice * getters.calculateRunningMeter() * getters.getOversizeSurcharge();
    },
    calculateRunningMeter: state => (length, height) => {
      let umfang = undefined;

      let lengthInM = (length ? length : state.length) / 1000;
      let length1InM = state.length1 / 1000;
      let length2InM = state.length2 / 1000;
      let heightInM = (height ? height : state.height) / 1000;
      let height1InM = state.height1 / 1000;
      switch (state.selectedFormat) {
        case i18n.t('formEllipse'):
          var l = (lengthInM - heightInM) / (lengthInM + heightInM);
          umfang =
            Math.PI *
            (lengthInM + heightInM) *
            (1 + (3 * l * l) / (10 + Math.sqrt(4 - 3 * l * l)));
          break;
        case i18n.t('formTriangle'):
          var c = Math.sqrt(Math.pow(lengthInM, 2) + Math.pow(heightInM, 2));
          umfang = lengthInM + heightInM + c;
          break;
        case i18n.t('formSpecRect'):
          var d = Math.sqrt(
            Math.pow(heightInM - height1InM, 2) + Math.pow(lengthInM, 2)
          );
          umfang = lengthInM + heightInM + heightInM + d;
          break;
        case i18n.t('formParallelogram'):
          umfang = 2 * (lengthInM - length1InM + heightInM);
          break;
        case i18n.t('formTrapezium'):
          var b = Math.sqrt(Math.pow(length2InM, 2) + Math.pow(heightInM, 2));
          d = Math.sqrt(Math.pow(length1InM, 2) + Math.pow(heightInM, 2));
          umfang = lengthInM + b + (lengthInM - length1InM - length2InM) + d;
          break;
        case i18n.t('formPentagon'):
          var e = Math.sqrt(
            Math.pow(lengthInM - length1InM, 2) +
            Math.pow(heightInM - height1InM, 2)
          );
          umfang = lengthInM + heightInM + length1InM + height1InM + e;
          break;
        default:
          umfang = 2 * (lengthInM + heightInM);
          break;
      }
      return umfang;
    },
    getMaxWidth: (state) => {
      let value = state.maxWidth;
      if (Array.isArray(value)) {
        for (const item of value) {
          if (state.height <= item.maxheight) {
            value = item.value;
            break;
          }
        }
      }

      return value;
    },
    getMinDrillingDistance: state => r => {
      let glassThickness = state.glassThickness;
      if (state.glassKind == 'vsg') {
        glassThickness /= 2;
      }
      return glassThickness * 2 + r;
    },
    getFormCoordinates: (state, getters) => r => {
      const s = getters.getMinDrillingDistance(r);
      const length = parseInt(state.length - s);
      const length1 = parseInt(state.length1);
      const length2 = parseInt(state.length2);
      const height = parseInt(state.height - s);
      const height1 = parseInt(state.height1 - s);
      const deg = length1 > 0 ? s / 2 : 0;
      let points = [[s, s], [s, s]];
      switch (state.selectedFormat) {
        case i18n.t('formEllipse'):
          points = [[length / 2], [height / 2]];
          break;
        case i18n.t('formTriangle'):
          state.flipped ?
            points = [[s + deg, s], [length - deg, s], [length - length1, height - deg], [s + deg, s]] :
            points = [[s + deg, s], [length - deg, s], [length1, height - deg], [s + deg, s]];
          break;
        case i18n.t('formSpecRect'):
          state.flipped ?
            points.splice(1, 0, [length, s], [length, height], [s, height1]) :
            points.splice(1, 0, [length, s], [length, height1], [s, height]);
          break;
        case i18n.t('formParallelogram'):
          state.flipped ?
            points = [[length1 + s, s], [length, s], [length - length1, height], [s, height], [length1 + s, s]] :
            points.splice(1, 0, [length - length1, s], [length, height], [length1 + s, height]);
          break;
        case i18n.t('formTrapezium'):
          state.flipped ?
            points.splice(1, 0, [length, s], [length1 + s, height], [length - length2, height]) :
            points.splice(1, 0, [length, s], [length - length2, height], [length1 + s, height]);
          break;
        case i18n.t('formPentagon'):
          state.flipped ?
            points.splice(1, 0, [length, s], [length, height], [length1 - s, height], [s, height1]) :
            points.splice(1, 0, [length, s], [length, height1], [length1 - s, height], [s, height]);
          break;
        default:
          points.splice(1, 0, [length, s], [length, height], [s, height]);
          break;
      }

      return points;
    },
    getOversizeSurcharge: state => (component = 'GlassKonfigurator') => {
      let { length, height, terraceGlasses, oversizeSurcharge } = state;

      if (component === 'TerassenDachKonfigurator') {
        length = 1;
        height = terraceGlasses[0].height;
      } else if (component !== 'GlassKonfigurator') {
        return 1;
      }

      let surcharge = 1;
      if (oversizeSurcharge) {
        for (let os of oversizeSurcharge) {
          if (length >= os.length || height >= os.length) {
            surcharge = parseFloat(os.percent) + 1;
          }
        }
      }

      return surcharge;
    },
    getShapeNumber: state => {
      let shape = 0; // Rectangle
      switch (state.selectedFormat) {
        case i18n.t('formEllipse'):
          shape = 75;
          break;
        case i18n.t('formTriangle'):
          shape = state.length1 > 0 ? 5 : 1;
          state.flipped && shape++;
          break;
        case i18n.t('formSpecRect'):
          shape = state.flipped ? 12 : 11;
          break;
        case i18n.t('formParallelogram'):
          shape = state.flipped ? 20 : 19;
          break;
        case i18n.t('formTrapezium'):
          shape = state.flipped ? 22 : 21;
          break;
        case i18n.t('formPentagon'):
          shape = state.flipped ? 42 : 41;
          break;
      }

      return shape;
    },
    countRoundedCorners: state => {
      let amount = 0;
      for (let corner of Object.keys(state.corners)) {
        if (!state.corners[corner].name.includes(i18n.t('cornerPointed'))) {
          amount++;
        }
      }
      return amount;
    },
    calculateExpressProductionPrice: state => total => {
      function getMinPrice() {
        let price = state.priceExpressProduction[0].price; // fallback
        for (let express of state.priceExpressProduction) {
          if (express.name.toLowerCase().split(' ').includes(state.glassKind.toLowerCase())) {
            price = express.price;
          }
        }
        return price;
      }

      let price = 0;
      if (state.selectedAccessories.includes('expressProduction')) {
        let defaultPrice = getMinPrice();
        price = total * 0.5; // 50% Aufschlag
        price = price < defaultPrice ? defaultPrice : price;
      }

      return price;
    },
    //#endregion

    /**
     * Filter accessories by given tuples 
     * 
     * @param {*} state 
     * @param tuples List of key value pairs like [[key, value], ...]
     * 
     * Example:  [["color","Schwarz"], ['type',"Scharnier"]]
     * 
     * @returns subset object of accessories or []
     */
    filterAccessoriesBy: state => (tuples) => {
      return state.accessories.filter(accessory => {
        return tuples.every(tuple => {
          const [key, value] = tuple;
          const valueA = accessory[key]?.toString();
          const valueB = value?.toString();
          return valueA?.toLowerCase() == valueB?.toLowerCase();
        })
      });
    },
    selectVariantByOption: () => (variants, tuples) => {
      const validate = (arr, keys, valueB, operation) => {
        const valueA = keys.reduce((obj, k) => obj?.[k], arr)?.toString();

        switch (operation) {
          case "==":
            return valueA?.replace(/\s/g,'').toLowerCase() === valueB?.replace(/\s/g,'').toLowerCase();
          case "*=": {
            const pattern = new RegExp(valueB, 'i');
            return pattern.test(valueA);
          }
          case "<":
            return parseFloat(valueA) < parseFloat(valueB);
          case ">":
            return parseFloat(valueA) > parseFloat(valueB);
        }
      }

      return variants.filter(variant => {
        return tuples.every(tuple => {
          const [key, operation, value] = tuple;
          const keys = key.split('.');
          const keyName = keys.shift();

          if (Array.isArray(variant[keyName])) {
            return variant[keyName].some(option => {
              return validate(option, keys, value, operation);
            });
          } else {
            return validate(variant[keyName], keys, value, operation);
          }
        });
      });
    },
    getAmountByOptions: () => (options) => {
      let amount = 0;
      for (const option of options) {
        if (/Anzahl/i.test(option.name)){
          amount = option.value.replace(/\D/g, '');
          break;
        }
      }
      return amount;
    },
    //#region Shower Stuff
    getDoorCount: state => {
      return parseInt(state.handle.replace(/\D/g, '')) || 1; // only numbers
    },
    getSidePlateCount: state => {
      return /seitenteil/i.test(state.handle) ? 1 : 0;
    },
    calculateShowerGlassHeight: state => {
      const gasketOffset = state.selectedBottomGasket?.offset || 2;

      return state.height - gasketOffset;
    },
    calculateShowerSidePlateHeight: state => {
      let gasketOffset = 0;
      state.selectedAccessories.forEach((extra) => {
        if (typeof extra === 'object') {
          if (extra.title.indexOf('Unterleger')) {
            gasketOffset = 2
          }
        }
      });
      
      return state.height - gasketOffset;
    },
    calculateShowerGlassWidth: (state, getters) => {
      const length = state.length - (state.length1 * getters.getSidePlateCount);
      let defaultOffset;
      let gasketOffset = state.selectedSideGasket?.offset || 0;
      if (getters.getDoorCount > 1 || getters.getSidePlateCount === 0) {
        gasketOffset *= 2;
      }
      const midGasketOffset = state.selectedMidGasket?.offset / (2 / getters.getDoorCount) || 0;
      const hingeOffset = state.selectedHinge?.offset * getters.getDoorCount || 0;

      gasketOffset > 0 ? defaultOffset = 0 : defaultOffset = 2 * getters.getDoorCount;
      return length - defaultOffset - hingeOffset - gasketOffset - midGasketOffset;
    },
    calculateShowerSidePlateWidth: (state, getters) => {
      const midGasketOffset = state.selectedMidGasket?.offset / 2 || 0;
      const angleOffset = state.selectedAngle?.offset * getters.getSidePlateCount || 0;

      return state.length1 - angleOffset - midGasketOffset;
    },
    getAccessorySubsetBy: state => (key, arr) => {
      const accessories = arr || state.accessories;
      return accessories.map(x => x[key]).filter((item, index, arr) => arr.indexOf(item) === index);
    },
    getShowerGlassProduct: (state, getters) => {
      const shopifyProductObject = {
        product: {
          input: {},
          media: []
        },
        variants: []
      };

      shopifyProductObject.product.input = {
        title: `Duschtür ${getters.getDoorCount}-flügel ${i18n.t('individualConfig')} ${getters.calculateShowerGlassWidth / getters.getDoorCount
          } x ${getters.calculateShowerGlassHeight
          }`,
        vendor: "Glas Strack",
        tags: [
          "konfiguriert",
          `basis_produkt_${state.productId}`,
          `basis_handle_${state.handle}`,
        ],
        metafields: [],
        templateSuffix: "noindex"
      };
      shopifyProductObject.variants = [{
        price: getters.calculateDiscountPrice(getters.showerPriceCalculator),
        inventoryItem: {
          measurement: {
            weight: {
              unit: "KILOGRAMS",
              value: parseFloat(getters.calculateWeight())
            }
          },
          tracked: false
        }
      }];

      if (state.imageUrl) {
        shopifyProductObject.product.media.push({
          originalSource: state.imageUrl,
          mediaContentType: "IMAGE"
        });
      }

      shopifyProductObject.product.input.metafields.push({
        namespace: "glasstar",
        key: "konfig_url",
        value: state.config_url,
        type: "url"
      });

      return shopifyProductObject;
    },
    getShowerGlassCartHtml: (state, getters) => {
      function getGasketHtml(selectedGasket, amount) {
        let html = '';
        if (selectedGasket && selectedGasket.title !== 'Keine Dichtung') {
          html += `<li>${amount} x ${selectedGasket.title}
            <ul class="cart_form-data">
              <li>Farbe: ${selectedGasket.color}</li>
            </ul>
          </li>`;
        }

        return html;
      }

      const dimensions = [[getters.calculateShowerGlassWidth / getters.getDoorCount, getters.calculateShowerGlassHeight]];
      let orgDimension = [state.length, state.height];
      let accessoriesHtml = '';
      let extraHtml = '';
      let infoHtml = '';

      if (getters.getSidePlateCount) {
        dimensions.push([getters.calculateShowerSidePlateWidth / getters.getSidePlateCount, getters.calculateShowerSidePlateHeight]);
      }

      if (state.selectedHinge) {
        let items = '';
        for (let pos of state.hingePositions) {
          items += `<li>Abstand oben: ${pos.y} mm</li>`;
        }
        accessoriesHtml = `<li>${state.hingePositions.length * getters.getDoorCount} x ${state.selectedHinge.title}
          <ul class="cart_form-data">
            <li>
              Anschlagseite: ${getters.getDoorCount > 1 ?
            'Links & Rechts' :
            state.fitting !== 'left' ? 'Rechts' : 'Links'
          }
            </li>
            <li>Farbe: ${state.selectedHinge.color}</li>
            <li>Positionen <ol class="cart_drilling-data">${items}</ol></li>
          </ul>
        </li>`;
      }

      if (state.selectedAngle) {
        let items = '';
        for (let pos of state.hingePositions) {
          items += `<li>Abstand oben: ${pos.y} mm</li>`;
        }
        accessoriesHtml += `<li>${state.hingePositions.length * getters.getSidePlateCount} x ${state.selectedAngle.title}
          <ul class="cart_form-data">
            <li>
              Anschlagseite: ${getters.getSidePlateCount > 1 ?
            'Links & Rechts' :
            state.sidePlate !== 'left' ? 'Rechts' : 'Links'
          }
            </li>
            <li>Farbe: ${state.selectedAngle.color}</li>
            <li>Positionen <ol class="cart_drilling-data">${items}</ol></li>
          </ul>
        </li>`;
      }

      if (state.selectedButton) {
        for (let i = 0; i < getters.getDoorCount; i++) {
          accessoriesHtml += `<li>1 x ${state.selectedButton.title}
            <ul class="cart_form-data">
              <li>Farbe: ${state.selectedButton.color}</li>
              <li>Position 
                <ul class="cart_drilling-data">
                  <li>Abstand links: ${state.drillings[i].x} mm, Abstand oben: ${state.drillings[i].y} mm</li>
                </ul>
              </li>
            </ul>
          </li>`;
        }
      }

      if (state.selectedStabilization.length === 1) {
        accessoriesHtml += `<li>1 x ${state.selectedStabilization[0].title}
            <ul class="cart_form-data">
              <li>Farbe: ${state.selectedStabilization[0].color}</li>
            </ul>
          </li>`;
      }

      accessoriesHtml += getGasketHtml(state.selectedLipGasket, 1);
      accessoriesHtml += getGasketHtml(state.selectedMidGasket, 1);
      accessoriesHtml += getGasketHtml(state.selectedBottomGasket, 1);
      accessoriesHtml += getGasketHtml(state.selectedSideGasket, getters.getSideGasketAmount);
      accessoriesHtml += [state.selectedBottomGasket?.title, state.selectedSideGasket?.title].includes('Keine Dichtung') ?
        `${i18n.t('withoutDescHtml')}` : '';

      accessoriesHtml = `<li>Zubehör <ul class="cart_extra-data">${accessoriesHtml}</ul></li>`

      state.selectedAccessories.forEach((extra) => {
        if (typeof extra === 'object') {
          extraHtml += `<li>${extra.title}</li>`;
        } else if (state.expressProductionTime && extra === 'expressProduction') {
          extraHtml += '<li>Expressproduktion</li>';
        } else if (state.heatSoak && extra === 'heatSoak') {
          extraHtml += '<li>Heat-Soak-Test</li>';
        } else if (extra === 'lotusLayer') {
          extraHtml += '<li>Lotusbeschichtung</li>';
        } else if (state.stamp && extra === 'glassLabel') {
          extraHtml += '<li>Normierungsstempel</li>';
        }
      });
      if (extraHtml) {
        extraHtml = `<li>Extras <ul class="cart_extra-data">${extraHtml}</ul></li>`
      }

      if (state.additionalInfo) {
        infoHtml = `<li>Info: ${state.additionalInfo}</li>`;
      }

      const htmlSidePlate = dimensions.length === 2 ? 
        `<li>
          Glasmaß: ${getters.getSidePlateCount} x ${state.selectedFormat} <br>
          (${dimensions[1].join(' x ')})
        </li>` : '';
      let htmlCardDetails = `<ul class="conf-details">
        <li>Produkt: ${state.productTitle}</li>
        <li>Format 
          <ul class="cart_form-data">
            <li>Gemessenes Nischenmaß: ${orgDimension.join(' x ')} mm</li>
            <li>
              Glasmaß: ${getters.getDoorCount} x ${state.selectedFormat} <br>
              (${dimensions[0].join(' x ')})
            </li>
            ${htmlSidePlate}
            <li>Dicke: ${state.glassThickness} mm</li>
            <li>Gewicht: ${getters.calculateWeight()} kg</li>
          </ul>
        </li>
        <li>Kanten: ${state.selectedEdge}</li>
        ${accessoriesHtml}${extraHtml}${infoHtml}
      </ul>`;

      return htmlCardDetails;
    },
    getShowerGlassLineItems: (state, getters) => {
      const doorCount = getters.getDoorCount;
      const sideCount = getters.getSidePlateCount;
      let shopifyLineItems = {
        _titel: `${state.productTitle} ${i18n.t('individualConfig')} ${state.length} x ${state.height}`,
        _preis: getters.calculateDiscountPrice(getters.showerPriceCalculator) * 100,
        Produkt: state.productTitle,
        _Produkttyp: 'Showerdoor',
        _breite_mm: state.length,
        _hoehe_mm: state.height,
        _Artikelnummer: state.sku,
        _basis_variant_id: state.variantId
      };

      const glasses = [];
      while (glasses.length < (doorCount + sideCount)) {
        const glass = {
          menge: 1,
          Form: state.selectedFormat,
          _Artikelnummer: state.sku,
          _breite_mm: glasses.length < doorCount ?
            getters.calculateShowerGlassWidth / doorCount :
            getters.calculateShowerSidePlateWidth / sideCount,
          _hoehe_mm: glasses.length < doorCount ?
            getters.calculateShowerGlassHeight :
            getters.calculateShowerSidePlateHeight,
          _dicke_mm: state.glassThickness,
          _Kanten: state.selectedEdge,
          _loecher: glasses.length < doorCount ?
            [state.drillings[glasses.length]] :
            state.drillings.slice(doorCount),
          _randausschnitt: []
        };
        if (state.glassProcessing && state.glassDesign) {
          Object.assign(glass, { _specialProcessing: state.glassProcessing });
        }

        if (state.selectedHinge) {
          const sku = state.selectedHinge.variants[0].sku;
          const gasket = state.selectedSideGasket && state.selectedSideGasket.title !== "Keine Dichtung" ? true : false;
          let edge = 3;
          let corner = 3;

          if (glasses.length < doorCount) {
            if (
              (doorCount > 1 && glasses.length === 0) ||
              (doorCount === 1 && state.fitting === 'left')
            ) {
              edge = 2;
              corner = 1;
            }

            glass._randausschnitt.push({ edge, corner, pos: state.hingePositions[0].y, sku, gasket });
            glass._randausschnitt.push({ edge, corner, pos: state.hingePositions[1].y, sku, gasket });
          } else if (state.selectedHinge.connection == 'Glas-Glas') {
            if (
              (sideCount > 1 && glasses.length === doorCount) ||
              (sideCount == 1 && state.fitting === 'right')
            ) {
              edge = 2;
              corner = 1;
            }

            glass._randausschnitt.push({ edge, corner, pos: state.hingePositions[0].y, sku, gasket, sideplate: true });
            glass._randausschnitt.push({ edge, corner, pos: state.hingePositions[1].y, sku, gasket, sideplate: true });
          }
        }

        state.selectedAccessories.forEach((extra) => {
          if (state.heatSoak && extra === 'heatSoak') {
            Object.assign(glass, { _heat_soak_test: "Ja" });
          } else if (extra === 'lotusLayer') {
              Object.assign(glass, { _Lotusbeschichtung: "Ja" });
          }
        });
        if (state.stamp) {
          Object.assign(glass, {
            _normierungsstempel: state.selectedAccessories.includes('glassLabel') ? "Ja" : "Nein"
          });
        }

        glasses.push(glass);
      }

      const accessories = [];
      if (state.selectedHinge) {
        accessories.push({
          _sku: state.selectedHinge.variants[0].sku,
          Produkt: state.selectedHinge.title,
          _price: state.selectedHinge.variants[0].price * doorCount,
          _menge: state.hingePositions.length * doorCount
        });
      }

      if (state.selectedAngle) {
        accessories.push({
          _sku: state.selectedAngle.variants[0].sku,
          Produkt: state.selectedAngle.title,
          _price: state.selectedAngle.variants[0].price * sideCount,
          _menge: state.hingePositions.length * sideCount
        });
      }

      if (state.selectedButton) {
        accessories.push({
          _sku: state.selectedButton.variants[0].sku,
          Produkt: state.selectedButton.title,
          _price: state.selectedButton.variants[0].price * doorCount,
          _menge: doorCount
        });
      }

      if (state.selectedBottomGasket && state.selectedBottomGasket.title !== "Keine Dichtung") {
        accessories.push({
          _sku: state.selectedBottomGasket.variants?.at(0).sku,
          Produkt: state.selectedBottomGasket.title,
          _price: state.selectedBottomGasket.variants?.at(0).price,
          _menge: 1
        });
      }
      if (state.selectedLipGasket) {
        accessories.push({
          _sku: state.selectedLipGasket.variants?.at(0).sku,
          Produkt: state.selectedLipGasket.title,
          _price: state.selectedLipGasket.variants?.at(0).price,
          _menge: getters.getSidePlateCount
        });
      }
      if (state.selectedMidGasket && state.selectedMidGasket.title !== "Keine Dichtung") {
        accessories.push({
          _sku: state.selectedMidGasket.variants?.at(0).sku,
          Produkt: state.selectedMidGasket.title,
          _price: state.selectedMidGasket.variants?.at(0).price,
          _menge: 1
        });
      }
      if (
        state.selectedSideGasket && state.selectedSideGasket.title !== "Keine Dichtung") {
        accessories.push({
          _sku: state.selectedSideGasket.variants?.at(0).sku,
          Produkt: state.selectedSideGasket.title,
          _price: state.selectedSideGasket.variants?.at(0).price,
          _menge: getters.getSideGasketAmount
        });
      }
      if (state.selectedStabilization.length === 1) {
        accessories.push({
          _sku: state.selectedStabilization[0].variants?.at(0).sku,
          Produkt: state.selectedStabilization[0].title,
          _price: state.selectedStabilization[0].variants?.at(0).price,
          _menge: 1
        });
      }

      state.selectedAccessories.forEach((extra) => {
        if (typeof extra === 'object') {
          accessories.push({
            _sku: extra.variants?.at(0).sku,
            Produkt: extra.title,
            _price: extra.variants?.at(0).price,
            _menge: getters.getAmountByOptions(extra.variants?.at(0).options) || 1
          });
        }
      });
      if (state.expressProductionTime) {
        Object.assign(shopifyLineItems, {
          _Expressproduktion: state.selectedAccessories.includes('expressProduction') ? "Ja" : "Nein"
        });
      }

      Object.assign(shopifyLineItems, {
        _scheiben: glasses,
        _zubehör: accessories,
        _gewicht_g: (getters.calculateWeight() * 1000).toFixed(2),
        _Info: state.additionalInfo
      });

      if (state.imageUrl) {
        Object.assign(shopifyLineItems, { _productImage: state.imageUrl });
      }

      if (getters.discountExists) {
        Object.assign(shopifyLineItems, { _Rabatt: state.discount });
      }

      Object.assign(shopifyLineItems, {
        _html: getters.getShowerGlassCartHtml
      });

      return shopifyLineItems;
    },
    lipGasketNeeded: state => {
      return state.selectedHinge &&
        /Lippendichtung/i.test(state.selectedHinge.gasket);
    },
    getSideGasketAmount: (state, getters) => {
      return !getters.lipGasketNeeded &&
        (!getters.getSidePlateCount || state.sidePlate == state.fitting || getters.getDoorCount > 1) ? 2 : 1;
    },
    calculateSideGasketPrice: (state, getters) => (price) => {
      let gasketPrice = parseFloat(price);
      gasketPrice *= getters.getSideGasketAmount;

      return gasketPrice;
    },
    heatSoakPrice: (state, getters) => {
      const mainArea = getters.calculateAreaWithMin(getters.calculateShowerGlassWidth, getters.calculateShowerGlassHeight, 1);
      const sidePlateArea = getters.getSidePlateCount 
        ? getters.calculateAreaWithMin(getters.calculateShowerSidePlateWidth, getters.calculateShowerGlassHeight, 1) 
        : 0;

      const totalArea = mainArea + sidePlateArea;
      return parseFloat(parseFloat(state.priceHeatSoak * state.glassThickness * totalArea).toFixed(2));
    },
    lotusLayerPrice: (state, getters) => {
      const mainArea = getters.calculateAreaWithMin(getters.calculateShowerGlassWidth, getters.calculateShowerGlassHeight, 1);
      const sidePlateArea = getters.getSidePlateCount 
        ? getters.calculateAreaWithMin(getters.calculateShowerSidePlateWidth, getters.calculateShowerGlassHeight, 1) 
        : 0;
    
      const totalArea = mainArea + sidePlateArea;
      return parseFloat(parseFloat(state.priceLotusCoating * totalArea).toFixed(2));
    },
    showerPriceCalculator: (state, getters) => {
      function round(i) {
        return parseFloat(Math.round(i * 100) / 100).toFixed(2);
      }

      let areaPrice = getters.calculateAreaWithMin(getters.calculateShowerGlassWidth, getters.calculateShowerGlassHeight) *
        state.pricePerSquaremeter;
      areaPrice += getters.getSidePlateCount ?
        getters.calculateAreaWithMin(getters.calculateShowerSidePlateWidth, getters.calculateShowerGlassHeight) * state.pricePerSquaremeter :
        0;
      const holePrice = state.priceHole ? state.drillings.length * state.priceHole : 0;
      let edgePrice = state.pricePolishedEdge *
        getters.calculateRunningMeter(getters.calculateShowerGlassWidth / getters.getDoorCount, getters.calculateShowerGlassHeight) *
        getters.getDoorCount;
      edgePrice += getters.getSidePlateCount ? 
        state.pricePolishedEdge * getters.calculateRunningMeter(getters.calculateShowerSidePlateWidth / getters.getSidePlateCount, getters.calculateShowerGlassHeight) * getters.getSidePlateCount :
        0;
      let hingePrice = state.selectedHinge
        ? parseFloat(state.selectedHinge.variants[0].price) * state.hingePositions.length * getters.getDoorCount
        : 0;
      hingePrice += state.priceCutout
        ? state.priceCutout * state.hingePositions.length * getters.getDoorCount
        : 0;
      let anglePrice = state.selectedAngle
        ? parseFloat(state.selectedAngle.variants[0].price) * state.hingePositions.length * getters.getSidePlateCount
        : 0;

      const buttonPrice = state.selectedButton
        ? parseFloat(state.selectedButton.variants[0].price) * getters.getDoorCount
        : 0;

      let gasketPrice = 0;
      if (state.selectedSideGasket && state.selectedSideGasket.title != 'Keine Dichtung') {
        gasketPrice = getters.calculateSideGasketPrice(state.selectedSideGasket.variants[0].price);
      }
      if (state.selectedBottomGasket && state.selectedBottomGasket.title != 'Keine Dichtung') {
        gasketPrice += parseFloat(state.selectedBottomGasket.variants[0].price);
      }
      if (state.selectedLipGasket) {
        gasketPrice += parseFloat(state.selectedLipGasket.variants[0].price) * getters.getSidePlateCount;
      }
      if (state.selectedMidGasket) {
        gasketPrice += parseFloat(state.selectedMidGasket.variants[0].price);
      }

      let barPrice = 0;
      if (state.selectedStabilization.length) {
        barPrice = parseFloat(state.selectedStabilization[0].variants[0].price);
      }

      let lotusPrice = state.selectedAccessories.includes('lotusLayer') ? getters.lotusLayerPrice : 0;
      let heatSoakPrice = state.heatSoak && state.selectedAccessories.includes('heatSoak')
        ? getters.heatSoakPrice : 0;

      let price = areaPrice + holePrice + edgePrice + hingePrice + anglePrice + buttonPrice + gasketPrice + barPrice + lotusPrice + heatSoakPrice;
      const expressPrice = getters.calculateExpressProductionPrice(price);
      return isNaN(price) ? "-" : round(price + expressPrice);
    },
    //#endregion

    //#region Sliding Walls Stuff
    getSlidingWallProduct: (state, getters) => {
      const shopifyProductObject = {
        product: {
          input: {},
          media: []
        },
        variants: []
      };

      shopifyProductObject.product.input = {
        title: `Schiebewand ${i18n.t('individualConfig')} ${state.length
          } x ${state.height
          }`,
        vendor: "Glas Strack",
        tags: [
          "konfiguriert",
          `basis_produkt_${state.productId}`,
          `basis_handle_${state.handle}`,
        ],
        metafields: [],
        templateSuffix: "noindex"
      };
      shopifyProductObject.variants = [{
        price: getters.calculateDiscountPrice(getters.slidingPriceConfigurator),
        inventoryItem: {
          measurement: {
            weight: {
              unit: "KILOGRAMS",
              value: parseFloat(getters.calculateWeight())
            }
          },
          tracked: false
        }
      }];

      if (state.imageUrl) {
        shopifyProductObject.product.media.push({
          originalSource: state.imageUrl,
          mediaContentType: "IMAGE"
        });
      }

      shopifyProductObject.product.input.metafields.push({
        namespace: "glasstar",
        key: "konfig_url",
        value: state.config_url,
        type: "url"
      });

      return shopifyProductObject;
    },
    getSlidingWallCartHtml: (state, getters) => {
      let dimension = [getters.getSlidingGlasLength, getters.getSlidingGlasHeight];
      let orgDimension = [state.length, state.height];
      let color = '';
      let extraHtml = `<li>${getters.getPanelMultiplier} x Griff</li>`;
      let infoHtml = '';

      if (state.surface !== 'natural_anodized') {
        color = `(${state.farbe})`;
      }
      if (state.lock) {
        extraHtml += '<li>Verriegelung</li>';
      }
      if (state.heatSoak && state.selectedAccessories.includes('heatSoak')) {
        extraHtml += '<li>Heat-Soak-Test</li>';
      }
      if (extraHtml) {
        extraHtml = `<li>Extras <ul class="cart_extra-data">${extraHtml}</ul></li>`
      }

      if (state.additionalInfo) {
        infoHtml = `<li>Info: ${state.additionalInfo}</li>`;
      }

      let htmlCardDetails = `<ul class="conf-details">
        <li>Produkt: ${state.productTitle}</li>
        <li>Format 
          <ul class="cart_form-data">
            <li>Gesamtmaß: ${orgDimension.join(' x ')} mm</li>
            <li>
              Glasmaß: ${state.lanes * getters.getPanelMultiplier} x ${state.selectedFormat} <br>
              (${dimension.join(' x ')})
              </li>
            <li>Dicke: ${state.glassThickness} mm</li>
            <li>Gewicht: ${getters.calculateWeight()} kg</li>
          </ul>
        </li>
        <li>Kanten: ${state.selectedEdge}</li>
        <li>Öffnungsrichtung: ${i18n.t(state.fitting)}</li>
        <li>Schienen: ${state.lanes}</li>
        <li>Oberfläche: ${i18n.t(state.surface)} ${color}</li>
        ${extraHtml}${infoHtml}
      </ul>`;

      return htmlCardDetails;
    },
    getSlidingWallLineItems: (state, getters) => {
      let shopifyLineItems = {
        _titel: `Schiebewand ${i18n.t('individualConfig')} ${state.length} x ${state.height}`,
        _preis: getters.calculateDiscountPrice(getters.slidingPriceConfigurator) * 100,
        Produkt: state.productTitle,
        _Produkttyp: 'Slidingdoor',
        _breite_mm: state.length,
        _hoehe_mm: state.height,
        _Artikelnummer: state.sku,
        _basis_variant_id: state.variantId,
        _zubehör: [{
          _sku: 78361,
          Produkt: `${state.productTitle} Zubehör`,
          _price: 0,
          _menge: 1,
          _config: {
            _breite_mm: state.length,
            _hoehe_mm: state.height,
            _oeffnungsrichtung: state.fitting,
            _schienen: state.lanes,
            _oberflaeche: state.surface,
          }
        }]
      };

      if (state.surface !== 'natural_anodized') {
        Object.assign(shopifyLineItems._zubehör[0]._config, { _farbe: state.farbe });
      }

      const glass = {
        Form: state.selectedFormat,
        menge: state.lanes * getters.getPanelMultiplier - getters.getPanelMultiplier,
        _breite_mm: getters.getSlidingGlasLength,
        _hoehe_mm: getters.getSlidingGlasHeight,
        _dicke_mm: state.glassThickness,
        _Kanten: state.selectedEdge,
        _Artikelnummer: state.sku,
        _normierungsstempel: "Ja"
      };

      if (state.heatSoak && state.selectedAccessories.includes('heatSoak')) {
        Object.assign(glass, { _heat_soak_test: "Ja" });
      }

      const glasses = [{ ...glass }];

      if (state.lock) {
        Object.assign(shopifyLineItems._zubehör[0]._config, { _verriegelung: "Ja" });
        if (state.fitting === 'left') {
          glass._eckausschnitt = [{ edge: 4, corner: 3 }]; // Bottom-Right
        } else if (state.fitting === 'right') {
          glass._eckausschnitt = [{ edge: 1, corner: 1 }]; // Bottom-Left
          glass._normierungsstempel = { edge: 4, x: -20 };
        }
      }

      if (!(state.lock && state.fitting === 'left_right')) {
        while (glasses.length <= state.drillings.length) {
          glass.menge = 1;
          glass._loecher = [state.drillings[glasses.length - 1]];
          glasses.push({ ...glass });
        }
      } else {
        glass.menge = 1;
        glass._randausschnitt = [{ edge: 4, corner: 3, pos: 1050 }];
        glass._eckausschnitt = [{ edge: 4, corner: 3 }]; // Bottom-Right
        glasses.push({ ...glass });
        glass._normierungsstempel = { edge: 4, x: -20 };
        glass._randausschnitt = [{ edge: 1, corner: 1, pos: 1050 }];
        glass._eckausschnitt = [{ edge: 1, corner: 1 }]; // Bottom-Left
        glasses.push({ ...glass });
      }

      Object.assign(shopifyLineItems, {
        _scheiben: glasses,
        _gewicht_g: (getters.calculateWeight() * 1000).toFixed(2),
        _Info: state.additionalInfo
      });

      if (state.imageUrl) {
        Object.assign(shopifyLineItems, { _productImage: state.imageUrl });
      }

      if (getters.discountExists) {
        Object.assign(shopifyLineItems, { _Rabatt: state.discount });
      }

      Object.assign(shopifyLineItems, {
        _html: getters.getSlidingWallCartHtml
      });

      return shopifyLineItems;
    },
    getSlidingProfileOffset: (state, getters) => {
      const panels = getters.getPanelMultiplier;
      let offset = panels === 1 ? -2 : 30;

      if (state.lanes === 3) {
        offset = panels === 1 ? 38 : 110;
      } else if (state.lanes === 4) {
        offset = panels === 1 ? 78 : 190;
      } else if (state.lanes === 5) {
        offset = panels === 1 ? 158 : 270;
      }

      if (panels == 2 && state.lock) {
        offset += 6;
      }

      return offset;
    },
    getSlidingGlasLength: (state, getters) => {
      const offset = getters.getSlidingProfileOffset;
      return parseInt((state.length + offset) / (state.lanes * getters.getPanelMultiplier));
    },
    getSlidingGlasHeight: state => {
      return state.height - 90;
    },
    /**
     * Sliding walls can be opend in both directions.
     * In this case panes must be multiplied.
     * 
     * @param {*} state 
     * @returns int 2 or 1 (default)
     */
    getPanelMultiplier: state => {
      return state.fitting === "left_right" ? 2 : 1;
    },
    slidingPriceConfigurator: (state, getters) => {
      function round(i) {
        return parseFloat(Math.round(i * 100) / 100).toFixed(2);
      }

      let minFramePrice = state.minPriceFrame[state.lanes];
      let framePrice = state.priceFrame[state.lanes] * state.length / 1000;
      let areaPrice =
        getters.calculateAreaWithMin(getters.getSlidingGlasLength, getters.getSlidingGlasHeight) *
        state.pricePerSquaremeter * state.lanes * getters.getPanelMultiplier;
      let holePrice = state.priceHole ? state.drillings.length * state.priceHole : 0;
      let edgePrice = state.pricePolishedEdge *
        getters.calculateRunningMeter(getters.getSlidingGlasLength, getters.getSlidingGlasHeight) *
        state.lanes * getters.getPanelMultiplier;

      let lockPrice = state.lock
        ? parseFloat(state.priceLock[state.fitting])
        : 0;

      let openingDirectionPrice = state.fitting === "left_right"
        ? parseFloat(state.priceOpeningDirection[state.lanes])
        : 0;

      let cutoutPrice = state.lock && state.fitting === "left_right" ? state.priceCutout * getters.getPanelMultiplier : 0;

      let buttonPrice = state.priceButton[state.lanes] * state.drillings.length;
      framePrice = framePrice < minFramePrice ? minFramePrice : framePrice;
      framePrice = state.surface !== "natural_anodized" ? state.priceColor * framePrice : framePrice;

      const heatSoakPrice = state.heatSoak && state.selectedAccessories.includes('heatSoak')
        ? state.priceHeatSoak * state.glassThickness * getters.calculateAreaWithMin(getters.getSlidingGlasLength, getters.getSlidingGlasHeight, 1)
        : 0;
      let price = round(
        (framePrice + areaPrice + holePrice + edgePrice + lockPrice + openingDirectionPrice + buttonPrice + cutoutPrice + heatSoakPrice)
      );
      return isNaN(price) ? "-" : price;
    },
    //#endregion

    //#region Terrassendach Stuff
    getProfile: (state, getters) => (pos) => {
      return getters.filterAccessoriesBy([
        ['pos', pos],
        ['size', state.barWidthInMm],
        ['type', 'verlegeprofil']
      ])[0];
    },
    terraceGlassPriceCalculator: (state, getters) => {
      function round(i) {
        return parseFloat(Math.round(i * 100) / 100).toFixed(2);
      }

      let totalGlassPrice = 0;
      let extras = 0;
      let profilePrice = 0;
      state.terraceGlasses.forEach(function (glass, index) {
        const widthInM = Number.parseInt(glass.width) / 1000;
        const heightInM = Number.parseInt(glass.height) / 1000;
        let individualGlassPrice = widthInM * heightInM * state.pricePerSquaremeter;

        const edgeMeters = state.onlyFrontEdge ? widthInM * 2 : (widthInM + heightInM) * 2;

        if (state.selectedEdge.includes(i18n.t('edgeMattTitle'))) {
          individualGlassPrice += state.priceHonedEdge * edgeMeters;
        } else if (state.selectedEdge.includes(i18n.t('edgeHighGlossTitle'))) {
          individualGlassPrice += state.pricePolishedEdge * edgeMeters;
        }

        totalGlassPrice += individualGlassPrice;

        // Add profiles
        if (index == 0) {
          profilePrice += getters.getProfile('end')?.price * heightInM * 2;

          //calculate all extras
          if (state.selectedHaltewinkel) {
            extras += state.selectedHaltewinkel.price;
          }
          if (state.selectedKlemmdeckel) {
            extras += state.selectedKlemmdeckel.price * heightInM;
          }
          extras *= (state.terraceGlasses.length + 1);
        }

        // Only for multiple glasses
        if (index) {
          profilePrice += getters.getProfile('mid')?.price * heightInM;
        }
      });

      totalGlassPrice *= getters.getOversizeSurcharge('TerassenDachKonfigurator');

      let price = totalGlassPrice + extras + profilePrice;
      const expressPrice = getters.calculateExpressProductionPrice(price);
      return isNaN(price) ? "-" : round(price + expressPrice);
    },
    profileWidthSum: (state) => {
      return state.barWidthInMm + (state.terraceGlasses.length) * 20;
    },
    maxGlassWidthInMiliMeters: (state, getters) => {
      return state.terraceWidth - getters.profileWidthSum;
    },
    currentAccumulatedWidthOfGlassinMiliMeters: (state) => {
      let width = 0;
      for (const glass of state.terraceGlasses) {
        width += parseInt(glass.width);
      }
      return width;
    },
    isCurrentConfigurationTooNarrow: (state, getters) => {
      return getters.individualTerraceGlassSizes ? getters.maxGlassWidthInMiliMeters > getters.currentAccumulatedWidthOfGlassinMiliMeters : false;
    },
    getShopifyTerraceGlassProduct: (state, getters) => {
      const shopifyProductObject = {
        product: {
          input: {},
          media: []
        },
        variants: []
      };

      const price = getters.terraceGlassPriceCalculator;
      shopifyProductObject.product.input = {
        title: `${i18n.t('terraceRoof')} ${i18n.t('individualConfig')} ${state.terraceWidth} x ${state.terraceGlasses[0].height}`,
        vendor: "Glas Strack",
        tags: [
          "konfiguriert",
          `basis_produkt_${state.productId}`,
          `basis_handle_${state.handle}`,
        ],
        metafields: [],
        templateSuffix: "noindex"
      };
      shopifyProductObject.variants = [{
        price: getters.calculateDiscountPrice(price),
        inventoryItem: {
          measurement: {
            weight: {
              unit: "KILOGRAMS",
              value: parseFloat(getters.getAccumulatedWeightOfGlassesInKiloGram)
            }
          },
          tracked: false
        }
      }];

      if (state.imageUrl) {
        shopifyProductObject.product.media.push({
          originalSource: state.imageUrl,
          mediaContentType: "IMAGE"
        });
      }

      shopifyProductObject.product.input.metafields.push({
        namespace: "glasstar",
        key: "konfig_url",
        value: state.config_url,
        type: "string"
      });

      return shopifyProductObject;
    },
    getShopifyTerraceGlassCartHtml: (state, getters) => {
      let dimension = [state.terraceWidth, state.terraceGlasses[0].height];
      let glassesHtml = '';
      let edgeHtml = '';
      let extraHtml = '';

      let items = '';
      state.terraceGlasses.forEach((glass) => {
        items += `<li>${glass.width} x ${glass.height} mm</li>`;
      });
      glassesHtml = `<li>Scheiben <ol class="cart_drilling-data">${items}</ol></li>`;

      if (state.onlyFrontEdge) {
        edgeHtml = `(Vorder- und hinter Kante)`;
      }
      edgeHtml = `<li>Kanten: ${state.selectedEdge} ${edgeHtml}</li>`;

      if (state.terraceGlasses.length > 1 && state.selectedKlemmdeckel) {
        if (state.selectedKlemmdeckel.color === 'white') {
          extraHtml = `<li>${state.terraceGlasses.length + 1} x ${state.selectedKlemmdeckel.title}</li>`;
        }
      }
      if (state.selectedHaltewinkel) {
        extraHtml += `<li>${state.terraceGlasses.length + 1} x ${state.selectedHaltewinkel.title}</li>`;
      }
      if (getters.getOversizeSurcharge('TerassenDachKonfigurator') > 1) {
        extraHtml += `<li>${i18n.t('oversizeSurcharge')}</li>`;
      }
      if (extraHtml) {
        extraHtml = `<li>Extras <ul class="cart_extra-data">${extraHtml}</ul></li>`
      }

      let htmlCardDetails = `<ul class="conf-details">
        <li>Produkt: ${state.productTitle}</li>
        <li>Angaben 
          <ul class="cart_form-data">
            <li>Abmesung: ${dimension.join(' x ')} mm</li>
            ${glassesHtml}
            <li>Dicke: ${state.glassThickness} mm</li>
            <li>Gewicht: ${getters.getAccumulatedWeightOfGlassesInKiloGram} kg</li>
            <li>Unterkonstruktion: ${state.substructure}</li>
            <li>Balkenstärke: ${state.barWidthInMm} mm</li>
          </ul>
        </li>
        ${edgeHtml}${extraHtml}
      </ul>`;

      return htmlCardDetails;
    },
    getShopifyTerraceGlassLineItems: (state, getters) => {
      const price = getters.terraceGlassPriceCalculator;
      let shopifyLineItems = {
        _titel: `${i18n.t('terraceRoof')} ${i18n.t('individualConfig')} ${state.terraceWidth} x ${state.terraceGlasses[0].height}`,
        _preis: getters.calculateDiscountPrice(price) * 100,
        Produkt: `${state.productTitle} (${state.substructure}konstruktion)`,
        _breite_mm: state.terraceWidth,
        _hoehe_mm: state.terraceGlasses[0].height,
        _Artikelnummer: state.sku,
        _basis_variant_id: state.variantId,
        _scheiben: [],
        _gewicht_g: (getters.getAccumulatedWeightOfGlassesInKiloGram * 1000).toFixed(2),
        _balken_breite_in_mm: state.barWidthInMm,
        _Unterkonstruktion: state.substructure
      };

      for (const item of state.terraceGlasses) {
        const glass = {
          menge: state.individualTerraceGlassSizes ? 1 : state.terraceGlasses.length,
          Form: state.selectedFormat,
          _Artikelnummer: state.sku,
          _breite_mm: item.width,
          _hoehe_mm: item.height,
          _dicke_mm: state.glassThickness,
          _Kanten: state.selectedEdge,
          _Kanten_Bearbeitung: state.onlyFrontEdge ? "Nur vorder- und hinter Kante" : "Alle Seiten"
        };

        shopifyLineItems._scheiben.push(glass);
        if (!state.individualTerraceGlassSizes) {
          break;
        }
      }

      if (state.imageUrl) {
        Object.assign(shopifyLineItems, { _productImage: state.imageUrl });
      }

      if (getters.discountExists) {
        Object.assign(shopifyLineItems, { _Rabatt: state.discount });
      }

      const endProfile = getters.getProfile('end');
      const midProfile = getters.getProfile('mid');
      const accessories = [{
        Produkt: endProfile.title,
        _price: (endProfile.price * shopifyLineItems._hoehe_mm / 1000 * 2).toFixed(2),
        _menge: 2,
        _sku: endProfile.sku
      }];

      if (state.terraceGlasses.length >= 2) {
        accessories.push({
          Produkt: midProfile.title,
          _price: (midProfile.price * shopifyLineItems._hoehe_mm / 1000 * (state.terraceGlasses.length - 1)).toFixed(2),
          _menge: state.terraceGlasses.length - 1,
          _sku: midProfile.sku
        });
      }

      if (state.terraceGlasses.length > 1 && state.selectedKlemmdeckel) {
        accessories.push({
          Produkt: state.selectedKlemmdeckel.title,
          _price: (state.selectedKlemmdeckel.price * shopifyLineItems._hoehe_mm / 1000 * (state.terraceGlasses.length + 1)).toFixed(2),
          _menge: state.terraceGlasses.length + 1,
          _sku: state.selectedKlemmdeckel.sku
        });

        if (state.selectedHaltewinkel) {
          accessories.push({
            Produkt: state.selectedHaltewinkel.title,
            _price: (state.selectedHaltewinkel.price * (state.terraceGlasses.length + 1)).toFixed(2),
            _menge: state.terraceGlasses.length + 1,
            _sku: state.selectedHaltewinkel.sku
          });
        }
      }

      Object.assign(shopifyLineItems, {
        _zubehör: accessories,
        _html: getters.getShopifyTerraceGlassCartHtml
      });

      return shopifyLineItems;
    },
    getAccumulatedWeightOfGlassesInKiloGram: state => {
      let glassWeight = 0;
      state.terraceGlasses.forEach(function (glass) {
        let widthInM = Number.parseInt(glass.width) / 1000;
        let heightInM = Number.parseInt(glass.height) / 1000;
        glassWeight += widthInM * heightInM * state.weightPerSqm;
      });
      return glassWeight || 0;
    }
    //#endregion
  },
  mutations: {
    //#region global mutations
    updateField,
    updateUserField(state, field) {
      updateField(state.user, field);
    },
    addDrilling(state, drilling = undefined) {
      const defaultDrilling = {
        durchmesser: 20,
        x: state.glassThickness * 2 + 10,
        y: state.glassThickness * 2 + 10
      };
      state.drillings.push(drilling ? drilling : defaultDrilling);
    },
    removeDrilling(state, drillingIndex) {
      state.drillings.splice(drillingIndex, 1);
    },
    addAvailableProduct(state, payload) {
      let kind = payload.handle.split("-")[0];
      if (payload.handle.indexOf('isolierglas') !== -1) { kind = 'ISO' }
      if (kind != 'musterprodukt') {
        let product = {
          productId: payload.id,
          productTitle: payload.title,
          sku: payload.variant_sku,
          weightPerSqm: Number.parseFloat(payload.metafields.gewicht_ohne_massangabe),
          handle: payload.handle,
          variantId: payload.variant_id,
          imageUrl: payload.image ?
            payload.image.url : '',
          glassColor: payload.metafields.glas_farbe || undefined,
          glassDesign: payload.metafields.glas_design && JSON.parse(payload.metafields.glas_design),
          glassThickness: Number.parseInt(payload.metafields.einbaudicke),
          glassKind: kind,
          glassProcessing: payload.metafields.farbe && payload.metafields.glas_design && payload.metafields.farbe,
          farbig: payload.handle.includes("farbig"),
          farbe: undefined,
          minWidth: Number.parseInt(payload.metafields.mindestbreite),
          minHeight: Number.parseInt(payload.metafields.mindesthoehe),
          length: Number.parseInt((payload.metafields.standardbreite || payload.metafields.mindestbreite)),
          length1: 75,
          length2: 75,
          height: Number.parseInt((payload.metafields.standardhoehe || payload.metafields.mindesthoehe)),
          height1: Number.parseInt((payload.metafields.standardhoehe || payload.metafields.mindesthoehe)) - 50,
          minArea: Number.parseFloat(
            payload.metafields.mindestberechnungsinhalt.replace(",", ".")
          ),
          maxWidth: state.isJson(payload.metafields.maximalbreite) ?
            JSON.parse(payload.metafields.maximalbreite) :
            Number.parseInt(payload.metafields.maximalbreite),
          maxHeight: Number.parseInt(payload.metafields.maximalhoehe),
          productionTime: payload.metafields.lieferzeit_normal,
          expressProductionTime: payload.metafields.lieferzeit_express,
          stamp:
            payload.metafields.stempel &&
            payload.metafields.stempel.toLowerCase() === i18n.t('boolTrueText').toLowerCase(),
          heatSoak:
            payload.metafields.heat_soak_test &&
            payload.metafields.heat_soak_test.toLowerCase() === "ja",
          lotus:
            payload.metafields.lotus ?
              payload.metafields.lotus.toLowerCase() === i18n.t('boolTrueText').toLowerCase() : true,
          edgeHemmed:
            payload.metafields.kante_gesäumt ?
              payload.metafields.kante_gesäumt.toLowerCase() === i18n.t('boolTrueText').toLowerCase() : true,
          addCustomImg:
            payload.metafields.bildupload &&
            payload.metafields.bildupload.toLowerCase() === i18n.t('boolTrueText').toLowerCase(),
          imgToLoad: payload.metafields.bildname && payload.metafields.bildname,
          selectedEdge: payload.metafields['Kantenbearbeitung Vorauswahl'] || "",
          pricePerSquaremeter: payload.metafields.grundpreis,
          priceCuttingEdge: payload.metafields.preis_kante_zuschnitt || false,
          priceHemmedEdge: payload.metafields.preis_kante_gesäumt || 0,
          priceHonedEdge: payload.metafields.preis_kante_geschliffen || false,
          pricePolishedEdge: payload.metafields.preis_kante_poliert || false,
          priceFacetEdge: payload.metafields.preis_kante_20mm_facette || false,
          priceWhiteGap: payload.metafields['abstanbdhalter-weiss'] || false,
          priceBlackGap: payload.metafields['abstanbdhalter-schwarz'] || false,
          discount: state.discount?.value > 0 ? state.discount : (payload.metafields.rabatt || 0),
          priceRoundedCorner: payload.metafields.preis_rundecke || false,
          priceHole: payload.metafields.preis_loch || false,
          priceColor: payload.metafields.prozentpreis_farbe || false,
          priceButton: state.isJson(payload.metafields.preis_griff) ?
            JSON.parse(payload.metafields.preis_griff) :
            payload.metafields.preis_griff || false,
          priceLock: state.isJson(payload.metafields.preis_verriegelung) ?
            JSON.parse(payload.metafields.preis_verriegelung) :
            payload.metafields.preis_verriegelung || false,
          priceOpeningDirection: state.isJson(payload.metafields.aufpreis_beidseitigeoeffnung) ?
            JSON.parse(payload.metafields.aufpreis_beidseitigeoeffnung) :
            payload.metafields.aufpreis_beidseitigeoeffnung || false,
          priceCutout: payload.metafields.preis_randausschnitt || false,
          priceFrame: state.isJson(payload.metafields.grundpreis_schienen) ?
            JSON.parse(payload.metafields.grundpreis_schienen) :
            payload.metafields.grundpreis_schienen || false,
          minPriceFrame: state.isJson(payload.metafields.mindestpreis_schienen) ?
            JSON.parse(payload.metafields.mindestpreis_schienen) :
            payload.metafields.mindestpreis_schienen || false,
          recommendedMaxWidthInMm: parseInt(payload.metafields.empf_maximalbreite),
          frames:
            payload.metafields.rahmen ?
              JSON.parse(payload.metafields.rahmen) : []
        };

        state.availableProducts.push(product);
        if (payload.initialProduct) {
          if (product.frames.length) {
            const frameTagLng = product.frames[0].name.replace(/\s/g, '');
            const frameSize = product.frames[0].size;
            state.selectedFrame = `${i18n.t(`frame${frameTagLng}`)} ${frameSize}`;
          }
          state.selectedFormat = i18n.t('formRectangle');
          state.glassThickness = Number.parseInt(payload.metafields.einbaudicke);
          Object.assign(state, product);
        }
      }
    },
    setActiveProduct(state, payload) {
      let height = state.height;
      let height1 = state.height1;
      let length = state.length;
      let length1 = state.length1;
      let length2 = state.length2;
      Object.assign(state, payload);
      height > state.height && (state.height = height);
      height1 != state.height1 && (state.height1 = height1);
      length > state.length && (state.length = length);
      length1 != state.length1 && (state.length1 = length1);
      length2 != state.length2 && (state.length2 = length2);
    },
    setAvailableProduct(state, glassThickness) {
      if (glassThickness && state.glassThickness != glassThickness) {
        let result = state.availableProducts.find(obj => {
          return obj.glassThickness === glassThickness;
        });

        Object.assign(state, result);
      }
    },
    setConfigurationDetails(state, config_id) {
      const url = window.location.href;
      state.config_url =
        url.indexOf('konfig_id') == -1
          ? `${url}&konfig_id=${config_id}`
          : url.replace(/(konfig_id=).*?(&|$)/, '$1' + config_id + '$2');
      state.configId = config_id;
    },
    setPaymentInformation(state, payload) {
      state.priceExpressProduction = payload.expressProduction;
      state.priceLotusCoating = payload.lotusbeschichtung;
      state.priceHeatSoak = payload.heatsoak;
      state.oversizeSurcharge = payload.oversizeSurcharge;
      state.discounts = payload.discount || state.discount;
    },
    //#endregion

    //#region Shower Stuff
    addAdditionalAccessoryProducts(state, payload) {
      state.accessories = payload.products;
      state.selectedEdge = 'Hochglanz poliert';
    },
    addHinge(state, pos) {
      let defaultHinge = {
        y: pos
      };
      state.hingePositions.push(defaultHinge);
    },
    //#endregion

    //#region Terrassendach Stuff
    addTerraceGlass(state) {
      let emptyTerraceGlass = {
        width: state.minWidth,
        height: state.terraceGlasses[0].height
      };
      state.terraceGlasses.push(emptyTerraceGlass);
    },
    removeTerraceGlass(state) {
      state.terraceGlasses.pop();
    },
    addAdditionalTerraceProduct(state, payload) {
      function addConfig(title, type, metafields, size) {
        const config = {
          title,
          color: metafields.color || "",
          price: parseFloat(metafields[`preis_zuschnittsmeter_${size}`]),
          size,
          sku: metafields[`sku_${size}`] || "",
          type
        };

        if (metafields.position) {
          Object.assign(config, {
            pos: metafields.position,
          });
        }

        state.accessories.push(config);
      }

      [60, 80].forEach(function (size) {
        if (payload.title.toLowerCase().includes("verlegeprofil")) {
          addConfig(payload.title, 'verlegeprofil', payload.metafields, size);
        } else if (payload.handle.includes("klemmdeckel")) {
          addConfig(payload.title, 'klemmdeckel', payload.metafields, size);
        } else {
          addConfig(payload.title, 'haltewinkel', payload.metafields, size);
        }
      });
    }
    //#endregion
  },
  actions: {}
});
