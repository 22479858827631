<template>
  <div>
    <v-group>
      <v-rect
        :config="{
          x: x + (frameWidth / 2),
          y: y + (frameWidth / 2),
          width: length - frameWidth,
          height: height - frameWidth,
          fillLinearGradientStartPoint: { x: (height / 2), y: 0 },
          fillLinearGradientEndPoint: { x: 20, y: (length / 2) },
          fillLinearGradientColorStops: [
            0, lighterColor,
            0.13, color,
            0.37, lighterColor,
            0.57, lighterColor,
            0.79, color,
            1, color
          ],
          stroke: lines.color,
          strokeWidth: frameWidth,
        }"
      />
    
      <v-line
        v-for="index in lines.vertical"
        :key="'lv-'+index"
        :config="{
          x: x + length / (lines.vertical + 1) * (index),
          y: y,
          points: [0, 0, 0, height],
          stroke: lines.color,
          strokeWidth: lines.width * scale,
        }"
      />
      <v-line
        v-for="index in lines.horizontal"
        :key="'lh-'+index"
        :config="{
          x: x,
          y: y + height / (lines.horizontal + 1) * (index),
          points: [0, 0, length, 0],
          stroke: lines.color,
          strokeWidth: lines.width * scale,
        }"
      />
    </v-group>

    <v-group>
      <v-line
        v-if="active"
        :config="{
          x: lineX - 25,
          y: y,
          points: [5, 0, 0, 0, 0, height, 5, height],
          stroke: 'blue',
          strokeWidth: 1,
          strokeScaleEnabled: false
        }"
      />
      <v-label
        v-if="active"
        :config="{
          x: lineX - 30,
          y: 20 + (height / 2) + 50
        }"
      >
        <v-tag :config="{ fill: '#fff', rotation: 270 }" />
        <v-text
          :config="{
            text: `${inputHeight} mm Glasmaß`,
            fontSize: 10,
            align: 'center',
            verticalAlign: 'middle',
            rotation: 270,
            width: 100
          }"
        />
      </v-label>

      <v-line
        :config="{
          x: x,
          y: height + y + 25,
          points: [ length, -5, length, 0, 0, 0, 0, -5 ],
          stroke: 'blue',
          strokeWidth: 1,
          strokeScaleEnabled: false
        }"
      />
      <v-label
        :config="{
          x: x + (length) / 2 - 25,
          y: height + y + 20
        }"
      >
        <v-tag :config="{ fill: '#fff' }" />
        <v-text
          :config="{
            text: `${Math.floor(inputLength)} mm`,
            fontSize: 10,
            align: 'center',
            verticalAlign: 'middle',
            width: 50
          }"
        />
      </v-label>
    </v-group>
  </div>
</template>
<script>
export default {
  name: "Glass",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "rgba(132, 145, 166, .5)"
    },
    length: {
      type: Number,
      default: 0
    },
    lineX: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    inputLength: {
      type: Number,
      default: 0
    },
    inputHeight: {
      type: Number,
      default: 0
    },
    lines: {
      type: Object,
      default: () => ({
        color: 'rgba(0,0,0,1)',
        horizontal: 0, 
        frame: false,
        vertical: 0,
        width: 10
      }),
    },
    scale: {
      type: Number,
      default: 1
    }
  },
  computed: {
    lighterColor() {
      return this.rgb_lighter(this.color, 80);
    },
    frameWidth() {
      return this.lines.frame ? this.lines.width * this.scale : 0;
    }
  },
  methods: {
    rgb_lighter(color, value) {
      const [t,g,b,a] = color.split(",");
      const r = t[3]=="a" ? t.slice(5) : t.slice(4);
      const i = parseInt, c = this.clamp;

      return"rgb"+(a?"a(":"(")+c(i(r)+value)+","+c(i(g)+value)+","+c(i(b)+value)+(a?","+a:")");
    },
    clamp(num) {
      return Math.min(Math.max(num, 0), 255)
    }

  }
}
</script>