var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-stage',{ref:"stage",attrs:{"config":_vm.stageSize}},[(_vm.showStage())?_c('v-layer',[(_vm.showStage())?_c('Cabin',{attrs:{"x":_vm.x + _vm.offset.x,"y":_vm.y + _vm.offset.y,"length":_vm.length,"height":_vm.height,"wall":_vm.wall,"ratio":_vm.ratio,"farbe":_vm.farbe,"input-length":_vm.length,"input-height":_vm.height}}):_vm._e(),_c('Glass',{attrs:{"x":_vm.sidePlate == 'left' ?
          _vm.x + _vm.offset.x + ((_vm.wall.thickness + _vm.hinge.offset + _vm.midGasket.offset + _vm.realLength1) * _vm.ratio) : 
          _vm.x + _vm.offset.x + ((_vm.wall.thickness + _vm.hinge.offset + _vm.sideGasket.offset) * _vm.ratio),"y":_vm.y + _vm.offset.y,"length":_vm.realLength / _vm.getDoorCount * _vm.ratio,"height":_vm.realHeight * _vm.ratio,"input-length":_vm.realLength / _vm.getDoorCount,"input-height":_vm.realHeight,"active":!_vm.activeInput.startsWith('hinge_'),"color":_vm.glassColor,"lines":_vm.glassDesign,"line-x":_vm.x + _vm.offset.x + ((_vm.wall.thickness + _vm.hinge.offset + _vm.sideGasket.offset) * _vm.ratio),"scale":_vm.ratio}}),(_vm.getDoorCount > 1)?_c('Glass',{attrs:{"x":_vm.sidePlate == 'left' ?
          _vm.x + _vm.offset.x + ((_vm.wall.thickness + _vm.hinge.offset + _vm.sideGasket.offset + 10 + ((_vm.realLength + _vm.realLength1) / _vm.getDoorCount)) * _vm.ratio) :
          _vm.x + _vm.offset.x + ((_vm.wall.thickness + _vm.hinge.offset + _vm.sideGasket.offset + _vm.midGasket.offset + 10 + (_vm.realLength / _vm.getDoorCount)) * _vm.ratio),"y":_vm.y + _vm.offset.y,"length":_vm.realLength / (_vm.getDoorCount) * _vm.ratio,"height":_vm.realHeight * _vm.ratio,"input-length":_vm.realLength / _vm.getDoorCount,"input-height":_vm.realHeight,"active":false,"color":_vm.glassColor,"lines":_vm.glassDesign,"scale":_vm.ratio}}):_vm._e(),(_vm.getSidePlateCount > 0)?_c('Glass',{attrs:{"x":_vm.sidePlate == 'left' ? 
          _vm.x + _vm.offset.x + ((_vm.wall.thickness + 1) * _vm.ratio) : 
          _vm.x + _vm.offset.x + ((_vm.wall.thickness + _vm.hinge.offset + _vm.sideGasket.offset + _vm.midGasket.offset + _vm.realLength) * _vm.ratio),"y":_vm.y + _vm.offset.y,"length":_vm.realLength1 * _vm.ratio,"height":_vm.sideHeight * _vm.ratio,"input-length":_vm.realLength1,"input-height":_vm.sideHeight,"active":false,"color":_vm.glassColor,"lines":_vm.glassDesign,"scale":_vm.ratio}}):_vm._e(),_c('v-group',[(_vm.selectedBottomGasket && _vm.selectedBottomGasket.title != 'Keine Dichtung')?_c('Gasket',{attrs:{"x":_vm.x + _vm.offset.x + ((_vm.wall.thickness + (_vm.sidePlate == 'left' ? _vm.length1 : 0) + _vm.hinge.offset) * _vm.ratio),"y":_vm.y + _vm.offset.y - (8 * _vm.ratio),"length":(_vm.realLength + _vm.midGasket.offset - _vm.hinge.offset) * _vm.ratio - 2,"height":_vm.height * _vm.ratio,"color":_vm.selectedBottomGasket.color,"thickness":18 * _vm.ratio,"position":"bottom"}}):_vm._e(),(_vm.selectedMidGasket)?_c('Gasket',{attrs:{"x":_vm.sidePlate == 'left' ?
            _vm.x + _vm.offset.x + (_vm.wall.thickness * _vm.ratio) + ((_vm.length1 - 10) * _vm.ratio) :
            _vm.x + _vm.offset.x + (_vm.wall.thickness * _vm.ratio) + ((_vm.length - _vm.length1 - 10) / _vm.getDoorCount * _vm.ratio),"y":_vm.y + _vm.offset.y,"length":_vm.hinge.offset * _vm.ratio,"height":_vm.height * _vm.ratio,"color":_vm.selectedMidGasket.color,"thickness":45 * _vm.ratio}}):_vm._e(),(_vm.selectedLipGasket)?_c('Gasket',{attrs:{"x":_vm.sidePlate == 'left' ?
            _vm.x + _vm.offset.x + (_vm.wall.thickness * _vm.ratio) + ((_vm.hinge.offset + _vm.realLength1) * _vm.ratio) :
            _vm.x + _vm.offset.x + (_vm.wall.thickness * _vm.ratio) + ((_vm.length - _vm.length1 - 10) / _vm.getDoorCount * _vm.ratio),"y":_vm.y + _vm.offset.y,"length":_vm.hinge.offset * _vm.ratio,"height":_vm.height * _vm.ratio,"color":_vm.selectedLipGasket.color,"thickness":12 * _vm.ratio}}):_vm._e(),(_vm.selectedSideGasket && _vm.selectedSideGasket.title != 'Keine Dichtung')?_c('Gasket',{attrs:{"x":_vm.sidePlate == 'left' ?
            _vm.x + _vm.offset.x + (_vm.wall.thickness * _vm.ratio) + ((_vm.hinge.offset + (_vm.fitting == 'right' || _vm.selectedLipGasket ? _vm.realLength : 0) + _vm.length1) * _vm.ratio) :
            _vm.x + _vm.offset.x + (_vm.wall.thickness * _vm.ratio) + (_vm.hinge.offset * _vm.ratio),"y":_vm.y + _vm.offset.y,"length":_vm.getSideGasketAmount === 1 ?
            0 :
            (_vm.realLength + _vm.midGasket.offset - _vm.hinge.offset / 2) * _vm.ratio,"height":_vm.height * _vm.ratio,"color":_vm.selectedSideGasket.color,"thickness":18 * _vm.ratio}}):_vm._e()],1),_vm._l((_vm.hingePositions),function(h,index){return _c('v-group',{key:`hinge_${index}`},[_c('Hinge',{attrs:{"x":_vm.sidePlate == 'right' && _vm.fitting == 'right' ? 
            _vm.x + _vm.offset.x + (_vm.wall.thickness * _vm.ratio) + ((_vm.realLength - 64) * _vm.ratio) :
            _vm.x + _vm.offset.x + (_vm.wall.thickness * _vm.ratio) + (_vm.fitting === 'left' || _vm.sidePlate !== undefined ? 0 : _vm.length * _vm.ratio),"y":_vm.y + _vm.offset.y + (h.y * _vm.ratio),"label":h.y,"offset-y":_vm.offset.y,"active":_vm.activeInput == `hinge_${index}`,"flip":_vm.fitting !== 'left' && _vm.sidePlate === undefined,"scale":_vm.ratio,"form":_vm.getSidePlateCount && _vm.sidePlate == 'left' ? _vm.angle.form : _vm.hinge.form,"material":_vm.farbe,"type":_vm.getSidePlateCount && _vm.sidePlate == 'left' ? 'angle' : 'hinge',"connection":_vm.sidePlate == 'right' && _vm.fitting == 'right' ? 'glass' : 'wall'}}),(_vm.getDoorCount > 1)?_c('Hinge',{attrs:{"x":_vm.x + _vm.offset.x - 1 + (_vm.wall.thickness * _vm.ratio) + (_vm.length * _vm.ratio),"y":_vm.y + _vm.offset.y + (h.y * _vm.ratio),"label":h.y,"offset-y":_vm.offset.y,"active":_vm.activeInput == `hinge_${index}`,"flip":true,"scale":_vm.ratio,"form":_vm.hinge.form,"material":_vm.farbe}}):_vm._e(),(_vm.getSidePlateCount)?_c('Hinge',{attrs:{"x":_vm.sidePlate == 'left' && _vm.fitting == 'left' ? 
            _vm.x + _vm.offset.x - 1 + (_vm.wall.thickness * _vm.ratio) + ((_vm.realLength1 + 70) * _vm.ratio) :
            (_vm.getDoorCount > 1 ?
              _vm.x + _vm.offset.x - 1 + (_vm.wall.thickness * _vm.ratio) + ((_vm.realLength + 70) * _vm.ratio) :
              _vm.x + _vm.offset.x - 1 + (_vm.wall.thickness * _vm.ratio) + (_vm.length * _vm.ratio)),"y":_vm.y + _vm.offset.y + (h.y * _vm.ratio),"label":h.y,"offset-y":_vm.offset.y,"active":_vm.activeInput == `hinge_${index}`,"flip":true,"scale":_vm.ratio,"form":_vm.sidePlate == 'right' ? _vm.angle.form : _vm.hinge.form,"material":_vm.farbe,"type":_vm.sidePlate == 'right' ? 'angle' : 'hinge',"connection":(_vm.sidePlate == 'left' || _vm.getDoorCount > 1) && _vm.fitting == 'left' ? 'glass' : 'wall'}}):_vm._e()],1)}),(_vm.drillings.length)?_c('v-group',_vm._l((_vm.getDoorCount),function(n,index){return _c('Button',{key:`button_${index}`,attrs:{"x":_vm.x + _vm.offset.x + ((_vm.wall.thickness + _vm.hinge.offset + _vm.drillings[index].x + (index > 0 ? _vm.length / _vm.drillings.length : 0) + (_vm.sidePlate == 'left' ? _vm.length1 : 0)) * _vm.ratio),"y":_vm.y + _vm.offset.y + ((_vm.drillings[index].y) * _vm.ratio),"durchmesser":_vm.drillings[index].durchmesser * _vm.ratio,"length":_vm.x + _vm.offset.x + (_vm.length * _vm.ratio),"real-height":_vm.height - _vm.drillings[index].y,"active":_vm.activeInput == `button_0`,"scale":_vm.ratio,"material":_vm.farbe}})}),1):_vm._e()],2):_vm._e()],1),(!_vm.getSidePlateCount)?_c('el-alert',{staticStyle:{"margin-top":"12px"},attrs:{"type":"info","center":"","show-icon":"","closable":false}},[_vm._v(" "+_vm._s(_vm.$t('glassDoorDimensision'))+" "+_vm._s(_vm.realLength)+" x "+_vm._s(_vm.realHeight)+" mm ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }