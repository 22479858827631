<template>
  <el-row :gutter="20">
    <el-col
      :sm="10"
      class="sticky"
    >
      <Shower
        :active-input="activeInput"
      />
    </el-col>
    <el-col :sm="14">
      <el-form>
        <el-collapse
          v-model="activeStep"
          accordion
        >
          <el-collapse-item
            :name="componentList.Format"
          >
            <template slot="title">
              <h3>{{ $t('format') }}</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.Format"
                class="status-icon"
                icon="check"
              />
            </template>

            <div v-if="getSidePlateCount == 1">
              <span>Wählen Sie die Position des Seitenteils:</span>
              <div style="margin-bottom: 12px">
                <el-radio 
                  v-model="sidePlate" 
                  label="left" 
                  border 
                  size="medium"
                >
                  {{ $t('left') }}
                </el-radio>
                <el-radio 
                  v-model="sidePlate" 
                  label="right" 
                  border 
                  size="medium"
                >
                  {{ $t('right') }}
                </el-radio>
              </div>
            </div>

            <div v-if="getDoorCount == 1">
              <span>{{ $t('fittingTitle') }}:</span>
              <div style="margin-bottom: 12px">
                <el-radio 
                  v-model="fitting" 
                  label="left" 
                  border 
                  size="medium"
                >
                  {{ $t('left') }}
                </el-radio>
                <el-radio 
                  v-model="fitting" 
                  label="right" 
                  border 
                  size="medium"
                >
                  {{ $t('right') }}
                </el-radio>
              </div>
            </div>

            <validation-observer
              ref="formatObserver"
              v-slot="{ invalid }"
            >
              <span>
                {{ $t('formatInfo') }}:
              </span>
              <el-alert
                style="margin-top: 12px"
                type="info"
                center
                show-icon
              >
                <p class="el-alert__title is-bold">
                  {{ $t('formatInfoMessure') }}
                </p>
                <i18n 
                  path="formatInfoMessureText" 
                  tag="p"
                >
                  <template v-slot:action>
                    <el-button 
                      type="text" 
                      @click="openGuide"
                    >
                      {{ $t('formatInfoGuide') }}
                    </el-button>
                  </template>
                </i18n>
              </el-alert>
              <Format
                :possible-formats="[]"
                :show-thickness="!(getSidePlateCount > 0)"
                :show-weight="false"
                @validate="validateGlassWidth()"
              />

              <div v-if="getSidePlateCount > 0">
                <Format
                  height-name="length1"
                  length-name="length2"
                  height-text="Breite Seitenteil"
                  length-text="Breite Tür"
                  :overwrite-min-height="length > 1200 ? length - 950 : 250"
                  :overwrite-max-height="Math.min(length - (minWidth - 250), 1500)"
                  :overwrite-min-width="Math.max(length - 1500, 500)"
                  :overwrite-max-width="Math.min(length - 250, 950)"
                  :possible-formats="[]"
                  @changeHeight="length1Changed()"
                  @changeLength="length2Changed()"
                  @validate="validateGlassWidth()"
                />
              </div>

              <div align="right">
                <el-button
                  type="primary"
                  :disabled="invalid"
                  @click="nextStep(1)"
                >
                  {{ $t('nextBtn') }}
                </el-button>
              </div>
            </validation-observer>
          </el-collapse-item>
          <el-collapse-item
            :name="componentList.Color"
            :disabled="activeStep < componentList.Color"
          >
            <template slot="title">
              <h3>{{ $t('color') }}</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.Color"
                class="status-icon"
                icon="check"
              />
            </template>
            <span>{{ $t('colorInfo') }}:</span>
            <Farbe
              :colors="colors"
              @nextStep="nextStep($event)"
            />
          </el-collapse-item>
          <el-collapse-item
            :name="componentList.Hinge"
            :disabled="activeStep < componentList.Hinge"
          >
            <template slot="title">
              <h3>{{ $t('hinge') }}</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.Hinge"
                class="status-icon"
                icon="check"
              />
            </template>
            <Scharnier
              :connection="getSidePlateCount && fitting === sidePlate ? 'Glas-Glas' : 'Glas-Wand'"
              @nextStep="nextStep($event)"
              @activeInput="setActiveInput"
            />
          </el-collapse-item>
          <el-collapse-item
            :name="componentList.Button"
            :disabled="activeStep < componentList.Button"
          >
            <template slot="title">
              <h3>{{ $t('button') }}</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.Button"
                class="status-icon"
                icon="check"
              />
            </template>
            <Griff
              @nextStep="nextStep($event)"
              @activeInput="setActiveInput"
            />
          </el-collapse-item>
          <el-collapse-item
            v-if="getSidePlateCount"
            :name="componentList.StabilizationBar"
            :disabled="activeStep < componentList.StabilizationBar"
          >
            <template slot="title">
              <h3>Stabilisierungsstange</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.StabilizationBar"
                class="status-icon"
                icon="check"
              />
            </template>
            <Stabilisierungsstange
              @nextStep="nextStep($event)"
            />
          </el-collapse-item>
          <el-collapse-item
            :name="componentList.Gasket"
            :disabled="activeStep < componentList.Gasket"
          >
            <template slot="title">
              <h3>{{ $t('gasket') }}</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.Gasket"
                class="status-icon"
                icon="check"
              />
            </template>
            <Dichtung
              @nextStep="nextStep($event)"
            />
          </el-collapse-item>
          <el-collapse-item
            :name="componentList.Extras"
            :disabled="activeStep < componentList.Extras"
          >
            <template slot="title">
              <h3>{{ $t('extras') }}</h3>
            </template>
            <Extras />
            <div align="right">
              <el-button
                type="primary"
                data-iframe-height
                :loading="loading"
                @click="checkout"
              >
                {{ chkoutBtnText }}
              </el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import Shower from "@/components/Layout/Shower/Index.vue";
import Format from '@/components/Layout/Format.vue';
import Farbe from '@/components/Gestaltung/Farbe.vue';
import Scharnier from '@/components/Shower/Scharnier.vue';
import Griff from '@/components/Shower/Griff.vue';
import Stabilisierungsstange from "@/components/Shower/Stabilisierungsstange.vue";
import Dichtung from '@/components/Shower/Dichtung.vue';
import Extras from "@/components/Extras.vue";
import i18n from "@/js/plugins/i18n";

export default {
  name: "DuschkabinenKonfigurator",
  components: {
    Shower,
    Format,
    Farbe,
    Scharnier,
    Griff,
    Stabilisierungsstange,
    Dichtung,
    Extras,
  },
  props: {
    collectionId: {
      type: String,
      default: ""
    },
    whatIf: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      componentList: {
        Format: 1,
        Color: 2,
        Hinge: 3,
        Button: 4,
        StabilizationBar: 5,
        Gasket: 6,
        Extras: 7
      },
      chkoutBtnText: this.$t('chkoutDialogBtn'),
      errorDialog: false,
      loading: false,
      activeInput: ""
    };
  },
  computed: {
    ...mapGetters([
      "getDoorCount",
      "getMaxWidth",
      "getSidePlateCount"
    ]),
    ...mapFields([
      "activeStep",
      "configId",
      "fitting",
      "length",
      "length1",
      "length2",
      "height",
      "minWidth",
      "productTitle",
      "sidePlate"
    ]),
    colors() {
      const tuple = [['type', 'Scharnier']];
      const accessories = this.$store.getters.filterAccessoriesBy(tuple);
      
      return this.$store.getters.getAccessorySubsetBy('color', accessories);
    }
  },
  watch: {
    activeStep() {
      this.$emit("datachange");
    },
    length() {
      this.setSize();
    }
  },
  created() {
    this.length1 = Math.max(this.length - 750, 250);
    this.setSize();
    this.getAdditionalAccessoryProducts();

    if (!this.getSidePlateCount) {
      delete this.componentList.StabilizationBar;
      this.componentList.Gasket--;
      this.componentList.Extras--;
    }

    this.$store.commit("addHinge", 250);
    this.$store.commit("addHinge", this.height - 250);
  },
  methods: {
    length1Changed() {
      this.length2 = Math.min(Math.max(this.length - this.length1, 500), 950);
    },
    length2Changed() {
      this.length1 = Math.max(this.length - this.length2, 250);
    },
    getAdditionalAccessoryProducts() {
      if (this.collectionId) {
        this.errorDialog = false;
        this.$http
          .get(`${process.env.VUE_APP_GET_PRODUCTS_BY_COLID}${this.collectionId}`)
          .then(response => {
            this.$store.commit("addAdditionalAccessoryProducts", response.data);
            this.$emit("loadSavedConfig");
          })
          .catch(() => {
            this.errorDialog = true;
          });
      }
    },
    openGuide() {
      let list = '';
      const needs = `<p>${i18n.t('formatInfoNeeds')}</p>`;
      const video = `<a class="el-link el-button el-button--small is-plain" href="https://youtu.be/gLFRODg8LnQ" target="_blank">
        <i class="el-icon-video-play"></i><span class="el-link--inner">${i18n.t('formatInfoVideoText')}</span>
      </a>`;

      for (let item of i18n.t('formatInfoGuideList')) {
        if (item.includes('{mailto}')) {
          const encodedProduct = encodeURI(this.productTitle);
          const subject = i18n.t('formatInfoMailSubjectEncoded').replace('{product}', encodedProduct);
          const body = i18n.t('formatInfoMailBodyEncoded').replace('{product}', encodedProduct);
          const linkText = i18n.t('formatInfoOffer');

          item = item.replace('{mailto}', `<a target="_top" href="mailto:support@glas-star.de?subject=${subject}&body=${body}">${linkText}</a>`);
        }
        
        list += `<li>${item}</li>`;
      }
      list = `<p>${i18n.t('formatInfoInstruction')}: <ol>${list}</ol></p>`;      
      
      this.$prompt(`<div>${needs} ${video} ${list}</div>`, i18n.t('formatInfoGuide'), {
        dangerouslyUseHTMLString: true,
        confirmButtonText: i18n.t('doneBtn'),
        cancelButtonText: i18n.t('cancelBtn'),
        inputValidator: value => {
          return value >= this.minWidth && value <= this.getMaxWidth;
        },
        inputErrorMessage: `${ i18n.t('formatInfoLength', {min: this.minWidth, max: this.getMaxWidth}) }`,
        inputPlaceholder: `${i18n.t('width')} (mm)`
      }).then(({ value }) => {
        this.length = parseInt(value);
      });
    },
    setActiveInput(name) {
      this.activeInput = name;
    },
    setSize() {
      this.length2 = Math.min(Math.max(this.length - this.length1, 500), 950);
      this.length1 = Math.max(this.length - this.length2, 250);
    },
    nextStep() {
      this.activeStep = Object.values(this.componentList)[this.activeStep];
    },
    checkout() {
      this.loading = true;
      this.chkoutBtnText = this.$t('chkoutDialogBtnLoading');
      const items = this.$store.getters.getShowerGlassLineItems;

      if (this.whatIf) {
        this.$emit("postLineItems", null, items);
        this.chkoutBtnText = this.$t('chkoutDialogBtn'),
        this.loading = false;
      } else {
        this.$http
          .post(
            process.env.VUE_APP_CHECKOUT,
            this.$store.getters.getShowerGlassProduct
          )
          .then(response => {
            this.$emit("postLineItems", response.data, items);
          }).catch(() => {
            this.$emit('notify', {
              title: this.$t('cartError'),
              message: this.$t('addToCartError'),
              type: 'error'
            });
          }).finally(() => {
            this.chkoutBtnText = this.$t('chkoutDialogBtn'),
            this.loading = false;
          });
      }
    },
    validateGlassWidth() {
      this.$nextTick(() => {
        this.$refs.formatObserver.refs.widthValidator.validate();
      })
    }
  }
}
</script>
