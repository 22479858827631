var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-group',[(_vm.position == 'side')?_c('v-line',{attrs:{"config":{
      x: _vm.x,
      y: _vm.y,
      points: [
        0, 0,
        0, _vm.height
      ],
      closed: false,
      stroke: _vm.getColor(),
      strokeWidth: _vm.thickness,
    }}}):_vm._e(),(_vm.position == 'side')?_c('v-line',{attrs:{"config":{
      x: _vm.x,
      y: _vm.y,
      points: [
        _vm.length, _vm.height,
        _vm.length, 0
      ],
      closed: false,
      stroke: _vm.getColor(),
      strokeWidth: _vm.thickness,
    }}}):_vm._e(),(_vm.position == 'bottom')?_c('v-line',{attrs:{"config":{
      x: _vm.x,
      y: _vm.y,
      points: [
        0, _vm.height,
        _vm.length, _vm.height
      ],
      closed: false,
      stroke: _vm.getColor(),
      strokeWidth: _vm.thickness,
    }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }