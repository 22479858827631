var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-group',[_c('v-rect',{attrs:{"config":{
        x: _vm.x + (_vm.frameWidth / 2),
        y: _vm.y + (_vm.frameWidth / 2),
        width: _vm.length - _vm.frameWidth,
        height: _vm.height - _vm.frameWidth,
        fillLinearGradientStartPoint: { x: (_vm.height / 2), y: 0 },
        fillLinearGradientEndPoint: { x: 20, y: (_vm.length / 2) },
        fillLinearGradientColorStops: [
          0, _vm.lighterColor,
          0.13, _vm.color,
          0.37, _vm.lighterColor,
          0.57, _vm.lighterColor,
          0.79, _vm.color,
          1, _vm.color
        ],
        stroke: _vm.lines.color,
        strokeWidth: _vm.frameWidth,
      }}}),_vm._l((_vm.lines.vertical),function(index){return _c('v-line',{key:'lv-'+index,attrs:{"config":{
        x: _vm.x + _vm.length / (_vm.lines.vertical + 1) * (index),
        y: _vm.y,
        points: [0, 0, 0, _vm.height],
        stroke: _vm.lines.color,
        strokeWidth: _vm.lines.width * _vm.scale,
      }}})}),_vm._l((_vm.lines.horizontal),function(index){return _c('v-line',{key:'lh-'+index,attrs:{"config":{
        x: _vm.x,
        y: _vm.y + _vm.height / (_vm.lines.horizontal + 1) * (index),
        points: [0, 0, _vm.length, 0],
        stroke: _vm.lines.color,
        strokeWidth: _vm.lines.width * _vm.scale,
      }}})})],2),_c('v-group',[(_vm.active)?_c('v-line',{attrs:{"config":{
        x: _vm.lineX - 25,
        y: _vm.y,
        points: [5, 0, 0, 0, 0, _vm.height, 5, _vm.height],
        stroke: 'blue',
        strokeWidth: 1,
        strokeScaleEnabled: false
      }}}):_vm._e(),(_vm.active)?_c('v-label',{attrs:{"config":{
        x: _vm.lineX - 30,
        y: 20 + (_vm.height / 2) + 50
      }}},[_c('v-tag',{attrs:{"config":{ fill: '#fff', rotation: 270 }}}),_c('v-text',{attrs:{"config":{
          text: `${_vm.inputHeight} mm Glasmaß`,
          fontSize: 10,
          align: 'center',
          verticalAlign: 'middle',
          rotation: 270,
          width: 100
        }}})],1):_vm._e(),_c('v-line',{attrs:{"config":{
        x: _vm.x,
        y: _vm.height + _vm.y + 25,
        points: [ _vm.length, -5, _vm.length, 0, 0, 0, 0, -5 ],
        stroke: 'blue',
        strokeWidth: 1,
        strokeScaleEnabled: false
      }}}),_c('v-label',{attrs:{"config":{
        x: _vm.x + (_vm.length) / 2 - 25,
        y: _vm.height + _vm.y + 20
      }}},[_c('v-tag',{attrs:{"config":{ fill: '#fff' }}}),_c('v-text',{attrs:{"config":{
          text: `${Math.floor(_vm.inputLength)} mm`,
          fontSize: 10,
          align: 'center',
          verticalAlign: 'middle',
          width: 50
        }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }