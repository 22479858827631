<template>
  <div>
    <v-stage
      ref="stage"
      :config="stageSize"
    >
      <v-layer v-if="showStage()">
        <Cabin
          v-if="showStage()"
          :x="x + offset.x"
          :y="y + offset.y"
          :length="length"
          :height="height"
          :wall="wall"
          :ratio="ratio"
          :farbe="farbe"
          :input-length="length"
          :input-height="height"
        />
      
        <!--
          -------------------------------------------------------------------------
          D O O R   S T A R T
        -->
        <Glass
          :x="sidePlate == 'left' ?
            x + offset.x + ((wall.thickness + hinge.offset + midGasket.offset + realLength1) * ratio) : 
            x + offset.x + ((wall.thickness + hinge.offset + sideGasket.offset) * ratio)"
          :y="y + offset.y"
          :length="realLength / getDoorCount * ratio"
          :height="realHeight * ratio"
          :input-length="realLength / getDoorCount"
          :input-height="realHeight"
          :active="!activeInput.startsWith('hinge_')"
          :color="glassColor"
          :lines="glassDesign"
          :line-x="x + offset.x + ((wall.thickness + hinge.offset + sideGasket.offset) * ratio)"
          :scale="ratio"
        />
        <Glass
          v-if="getDoorCount > 1"
          :x="sidePlate == 'left' ?
            x + offset.x + ((wall.thickness + hinge.offset + sideGasket.offset + 10 + ((realLength + realLength1) / getDoorCount)) * ratio) :
            x + offset.x + ((wall.thickness + hinge.offset + sideGasket.offset + midGasket.offset + 10 + (realLength / getDoorCount)) * ratio)"
          :y="y + offset.y"
          :length="realLength / (getDoorCount) * ratio"
          :height="realHeight * ratio"
          :input-length="realLength / getDoorCount"
          :input-height="realHeight"
          :active="false"
          :color="glassColor"
          :lines="glassDesign"
          :scale="ratio"
        />
        <Glass
          v-if="getSidePlateCount > 0"
          :x="sidePlate == 'left' ? 
            x + offset.x + ((wall.thickness + 1) * ratio) : 
            x + offset.x + ((wall.thickness + hinge.offset + sideGasket.offset + midGasket.offset + realLength) * ratio)"
          :y="y + offset.y"
          :length="realLength1 * ratio"
          :height="sideHeight * ratio"
          :input-length="realLength1"
          :input-height="sideHeight"
          :active="false"
          :color="glassColor"
          :lines="glassDesign"
          :scale="ratio"
        />

        <v-group>
          <Gasket
            v-if="selectedBottomGasket && selectedBottomGasket.title != 'Keine Dichtung'"
            :x="x + offset.x + ((wall.thickness + (sidePlate == 'left' ? length1 : 0) + hinge.offset) * ratio)"
            :y="y + offset.y - (8 * ratio)"
            :length="(realLength + midGasket.offset - hinge.offset) * ratio - 2"
            :height="height * ratio"
            :color="selectedBottomGasket.color"
            :thickness="18 * ratio"
            position="bottom"
          />
          <Gasket
            v-if="selectedMidGasket"
            :x="sidePlate == 'left' ?
              x + offset.x + (wall.thickness * ratio) + ((length1 - 10) * ratio) :
              x + offset.x + (wall.thickness * ratio) + ((length - length1 - 10) / getDoorCount * ratio)"
            :y="y + offset.y"
            :length="hinge.offset * ratio"
            :height="height * ratio"
            :color="selectedMidGasket.color"
            :thickness="45 * ratio"
          />
          <Gasket
            v-if="selectedLipGasket"
            :x="sidePlate == 'left' ?
              x + offset.x + (wall.thickness * ratio) + ((hinge.offset + realLength1) * ratio) :
              x + offset.x + (wall.thickness * ratio) + ((length - length1 - 10) / getDoorCount * ratio)"
            :y="y + offset.y"
            :length="hinge.offset * ratio"
            :height="height * ratio"
            :color="selectedLipGasket.color"
            :thickness="12 * ratio"
          />
          <Gasket
            v-if="selectedSideGasket && selectedSideGasket.title != 'Keine Dichtung'"
            :x="sidePlate == 'left' ?
              x + offset.x + (wall.thickness * ratio) + ((hinge.offset + (fitting == 'right' || selectedLipGasket ? realLength : 0) + length1) * ratio) :
              x + offset.x + (wall.thickness * ratio) + (hinge.offset * ratio)"
            :y="y + offset.y"
            :length="getSideGasketAmount === 1 ?
              0 :
              (realLength + midGasket.offset - hinge.offset / 2) * ratio"
            :height="height * ratio"
            :color="selectedSideGasket.color"
            :thickness="18 * ratio"
          />
        </v-group>

        <v-group
          v-for="(h, index) in hingePositions"
          :key="`hinge_${index}`"
        >
          <Hinge
            :x="sidePlate == 'right' && fitting == 'right' ? 
              x + offset.x + (wall.thickness * ratio) + ((realLength - 64) * ratio) :
              x + offset.x + (wall.thickness * ratio) + (fitting === 'left' || sidePlate !== undefined ? 0 : length * ratio)"
            :y="y + offset.y + (h.y * ratio)"
            :label="h.y"
            :offset-y="offset.y"
            :active="activeInput == `hinge_${index}`"
            :flip="fitting !== 'left' && sidePlate === undefined"
            :scale="ratio"
            :form="getSidePlateCount && sidePlate == 'left' ? angle.form : hinge.form"
            :material="farbe"
            :type="getSidePlateCount && sidePlate == 'left' ? 'angle' : 'hinge'"
            :connection="sidePlate == 'right' && fitting == 'right' ? 'glass' : 'wall'"
          />
          <Hinge
            v-if="getDoorCount > 1"
            :x="x + offset.x - 1 + (wall.thickness * ratio) + (length * ratio)"
            :y="y + offset.y + (h.y * ratio)"
            :label="h.y"
            :offset-y="offset.y"
            :active="activeInput == `hinge_${index}`"
            :flip="true"
            :scale="ratio"
            :form="hinge.form"
            :material="farbe"
          />
          <Hinge
            v-if="getSidePlateCount"
            :x="sidePlate == 'left' && fitting == 'left' ? 
              x + offset.x - 1 + (wall.thickness * ratio) + ((realLength1 + 70) * ratio) :
              (getDoorCount > 1 ?
                x + offset.x - 1 + (wall.thickness * ratio) + ((realLength + 70) * ratio) :
                x + offset.x - 1 + (wall.thickness * ratio) + (length * ratio))"
            :y="y + offset.y + (h.y * ratio)"
            :label="h.y"
            :offset-y="offset.y"
            :active="activeInput == `hinge_${index}`"
            :flip="true"
            :scale="ratio"
            :form="sidePlate == 'right' ? angle.form : hinge.form"
            :material="farbe"
            :type="sidePlate == 'right' ? 'angle' : 'hinge'"
            :connection="(sidePlate == 'left' || getDoorCount > 1) && fitting == 'left' ? 'glass' : 'wall'"
          />
        </v-group>

        <v-group v-if="drillings.length">
          <Button 
            v-for="(n, index) in getDoorCount"
            :key="`button_${index}`"
            :x="x + offset.x + ((wall.thickness + hinge.offset + drillings[index].x + (index > 0 ? length / drillings.length : 0) + (sidePlate == 'left' ? length1 : 0)) * ratio)"
            :y="y + offset.y + ((drillings[index].y) * ratio)"
            :durchmesser="drillings[index].durchmesser * ratio"
            :length="x + offset.x + (length * ratio)"
            :real-height="height - drillings[index].y"
            :active="activeInput == `button_0`"
            :scale="ratio"
            :material="farbe"
          />
        </v-group>
        <!--
          -------------------------------------------------------------------------
          D O O R   E N D
        -->
      </v-layer>
    </v-stage>
    <el-alert
      v-if="!getSidePlateCount"
      style="margin-top: 12px"
      type="info"
      center
      show-icon
      :closable="false"
    >
      {{ $t('glassDoorDimensision') }} {{ realLength }} x {{ realHeight }} mm
    </el-alert>
  </div>
</template>
<script>
import Cabin from './Cabin.vue';
import Glass from './Parts/Glass.vue';
import Button from './Parts/Button.vue';
import Hinge from './Parts/Hinge.vue';
import Gasket from './Parts/Gasket.vue';
import { mapGetters } from "vuex";
import { mapMultiRowFields, mapFields } from "vuex-map-fields";

export default {
  name: "Shower",
  components: {
    Cabin,
    Glass,
    Button,
    Hinge,
    Gasket
  },
  props: {
    activeInput: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      timeout: null,
      x: 0,
      y: 0,
      offset: {
        x: 20,
        y: 20
      },
      wall: {
        thickness: 40,
        dimensision: 100
      },
      angle: {
        form: 'quadrat',
        offset: 2
      },
      hinge: {
        form: 'quadrat',
        offset: 2
      },
      sideGasket: {
        offset: 0
      },
      midGasket: {
        offset: 0
      },
      stageSize: {
        width: 0,
        height: 0,
      },
      ratio: 1,
      weight: 0,
    };
  },
  computed: {
    ...mapGetters([
      "calculateShowerGlassWidth",
      "calculateShowerSidePlateWidth",
      "calculateShowerGlassHeight",
      "calculateShowerSidePlateHeight",
      "getDoorCount",
      "getMaxWidth",
      "getSideGasketAmount",
      "getSidePlateCount",
      "lipGasketNeeded"
    ]),
    ...mapMultiRowFields(["drillings"]),
    ...mapFields([
      "farbe",
      "fitting",
      "glassColor",
      "glassDesign",
      "minWidth",
      "minHeight",
      "maxHeight",
      "length",
      "height",
      "length1",
      "height1",
      "selectedAngle",
      "selectedHinge",
      "hingePositions",
      "selectedBottomGasket",
      "selectedLipGasket",
      "selectedMidGasket",
      "selectedSideGasket",
      "sidePlate"
    ]),
    realLength() {
      return this.calculateShowerGlassWidth;
    },
    realLength1() {
      return this.calculateShowerSidePlateWidth;
    },
    realHeight() {
      return this.calculateShowerGlassHeight;
    },
    sideHeight() {
      return this.calculateShowerSidePlateHeight;
    }
  },
  watch: {
    length() {
      this.lazyCaller(this.updateCanvas());
    },
    height() {
      this.lazyCaller(this.updateCanvas());
    },
    selectedAngle() {
      this.angle.offset = this.selectedAngle?.offset || 2;
      this.angle.form = this.selectedAngle?.format || 'quadrat';
    },
    selectedHinge() {
      this.hinge.offset = this.selectedHinge?.offset || 2;
      this.hinge.form = this.selectedHinge?.format || 'quadrat';
    },
    selectedSideGasket() {
      this.sideGasket.offset = (this.selectedSideGasket?.offset || 0);
    },
    selectedMidGasket() {
      this.midGasket.offset = (this.selectedMidGasket?.offset || 0);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setStageWidth();
      this.lazyCaller(this.updateCanvas());
    });
  },
  created() {
    window.addEventListener("resize", this.resizeCanvas);
    this.resizeCanvas();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
  methods: {
    lazyCaller(callback, time = 150) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(callback, time)
    },
    showStage() {
      const valid = this.length && this.minWidth && this.getMaxWidth &&
        this.height && this.minHeight && this.maxHeight &&
        parseInt(this.length) >= parseInt(this.minWidth) &&
        parseInt(this.length) <= parseInt(this.getMaxWidth) && 
        parseInt(this.height) >= parseInt(this.minHeight) && 
        parseInt(this.height) <= parseInt(this.maxHeight) && 
        parseFloat(this.ratio) > 0;

      return valid;
    },
    getStageHeight() {
      return window.innerWidth <= 460 ? 300 : 
        window.innerWidth <= 768 ? 450 : 580;
    },
    setStageWidth() {
      this.stageSize.width = this.$refs.stage ? this.$refs.stage.$el.clientWidth : 0;
    },
    updateCanvas() {
      const label = { height: 70, width: 120 };
      this.stageSize.height = this.getStageHeight();
      var frameWidth = parseInt(this.length);
      var frameHeight = parseInt(this.height);

      var wr = (this.stageSize.width - label.width) / frameWidth || 0;
      var hr = (this.stageSize.height - label.height) / frameHeight || 0;

      var ratio = Math.min(wr, hr);
      this.ratio = Math.abs(ratio);
      this.stageSize.height = this.height * this.ratio + label.height;

      const drawing_width = (this.length + (this.wall.thickness * 2)) * this.ratio;
      this.offset.x = (this.stageSize.width - drawing_width) / 2;
    },
    resizeCanvas() {
      this.setStageWidth();
      this.lazyCaller(this.updateCanvas());
    }
  }
}
</script>
