<template>
  <validation-observer
    ref="hingeObserver"
    v-slot="{ valid }"
  >
    <div>
      <el-row
        :gutter="12"
      >
        <el-col
          v-for="(h, index) in hingePositions"
          :key="index"
          :xs="12"
          :sm="6"
          :md="5"
          :lg="4"
          :xl="3"
        >
          <form-item-wrapper
            label="Abstand"
            :rules="{
              required: true,
              between: { min: (height / hingePositions.length * index) + 30, max: height / (hingePositions.length - index) - 30 }
            }"
            use-slot
          >
            <span slot="label">
              <i class="el-icon-bottom" />
              {{ $t('distance') }}
            </span>
            <div v-if="index % 2">
              <el-popover
                ref="popover"
                placement="bottom-start"
                width="150"
                trigger="focus"
              >
                <span>{{ $t('assistance') }}:<br><i class="el-icon-top" /> {{ $t('distance') }} {{ height - h.y }} mm</span>
              </el-popover>
              <el-input
                v-model="h.y"
                v-popover:popover
                :name="`hinge_${index}`"
                @focus="focusInput"
                @blur="blurInput"
                @change="calculateDrillings"
              >
                <span slot="suffix">mm</span>
              </el-input>
            </div>
            <div v-else>
              <el-input
                v-model="h.y"
                :name="`hinge_${index}`"
                @focus="focusInput"
                @blur="blurInput"
                @change="calculateDrillings"
              >
                <span slot="suffix">mm</span>
              </el-input>
            </div>
          </form-item-wrapper>
        </el-col>
      </el-row>
      <el-radio-group
        v-model="selectedHinge"
        fill="#1bafd6"
        style="margin-bottom: 8px"
        @change="onChange"
      >
        <el-radio-button
          v-for="hinge in hinges"
          :key="hinge.id"
          :label="getLimitedHinge(hinge)"
          :disabled="loadReached(hinge, calculateArea(calculateShowerGlassWidth), hingeCount())"
          class="card"
          rules="required"
        >
          <el-button
            class="info-box"
            size="xs"
            circle
            @click="openInfoBox(hinge.title, hinge.body_html)"
          >
            <i class="el-icon-info info-icon" />
          </el-button>
          <div class="img-box">
            <img
              :src="hinge.image?.src"
              class="image"
            >
          </div>
          <div class="caption">
            <span class="title">{{ getTitle(hinge.title) }}</span>
            <div class="bottom clearfix">
              <span class="info">{{ $t('select') }}</span>
              <span class="info price">{{ calculatePrice(hinge.variants, hingeCount()) }} €</span>
            </div>
          </div>
          <el-alert
            v-if="loadReached(hinge, calculateArea(calculateShowerGlassWidth), hingeCount())"
            style="position:absolute;top:0;padding:8px 10px;border-radius:unset;"
            class="message"
            type="info"
            :title="$t('hinge_maxload_msg', {kg: calculateMaxLoad(hinge)})"
            show-icon
            :closable="false"
          />
          <div class="overlay" />
        </el-radio-button>
      </el-radio-group>

      <h4
        v-if="getSidePlateCount"
      >
        Passende Winkelverbinder:
      </h4>
      <el-radio-group
        v-if="getSidePlateCount"
        v-model="selectedAngle"
        fill="#1bafd6"
        style="margin-bottom: 8px"
      >
        <el-radio-button
          v-for="angle in angles"
          :key="angle.id"
          :label="getLimitedHinge(angle)"
          :disabled="loadReached(angle, calculateArea(calculateShowerSidePlateWidth), angleCount())"
          class="card"
          rules="required"
        >
          <el-button
            class="info-box"
            size="xs"
            circle
            @click="openInfoBox(angle.title, angle.body_html)"
          >
            <i class="el-icon-info info-icon" />
          </el-button>
          <div class="img-box">
            <img
              :src="angle.image?.src"
              class="image"
            >
          </div>
          <div class="caption">
            <span class="title">{{ getTitle(angle.title) }}</span>
            <div class="bottom clearfix">
              <span class="info">{{ $t('select') }}</span>
              <span class="info price">{{ calculatePrice(angle.variants, angleCount()) }} €</span>
            </div>
          </div>
          <el-alert
            v-if="loadReached(angle, calculateArea(calculateShowerSidePlateWidth), angleCount())"
            style="position:absolute;top:0;padding:8px 10px;border-radius:unset;"
            class="message"
            type="info"
            :title="$t('angle_maxload_msg', {kg: calculateMaxLoad(angle)})"
            show-icon
            :closable="false"
          />
          <div class="overlay" />
        </el-radio-button>
      </el-radio-group>
      <div
        align="right"
      >
        <el-button
          type="primary"
          :disabled="!valid || 
            !selectedHinge || selectedHinge.color.toLowerCase() != farbe.toLowerCase() || loadReached(selectedHinge) ||
            (getSidePlateCount > 0 && (!selectedAngle || selectedAngle.color.toLowerCase() != farbe.toLowerCase() || loadReached(selectedAngle)))"
          @click="next()"
        >
          {{ $t('nextBtn') }}
        </el-button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
import i18n from "@/js/plugins/i18n";

export default {
  name: "Scharnier",
  props: {
    connection: {
      type: String,
      default: 'Glas-Wand'
    },
    nextIndex: {
      type: Number,
      default: 3
    }
  },
  computed: {
    ...mapGetters([
      "calculateShowerGlassWidth",
      "calculateShowerSidePlateWidth",
      "getDoorCount",
      "getSidePlateCount",
      "selectVariantByOption"
    ]),
    ...mapFields([
      "height",
      "selectedAngle",
      "selectedHinge",
      "selectedSideGasket",
      "sidePlate",
      "farbe",
      "hingePositions"
    ]),
    ...mapMultiRowFields(["drillings"]),
    angles() {
      const filter = [
        ['color', this.farbe],
        ['type', 'Winkelverbinder']
      ];

      if (this.selectedHinge) {
        filter.push(['set', this.selectedHinge.set]);
      }

      return this.$store.getters.filterAccessoriesBy(filter);
    },
    hinges() {
      return this.$store.getters.filterAccessoriesBy([
        ['color', this.farbe],
        ['type', 'Scharnier'],
        ['connection', this.connection]
      ]);
    }
  },
  watch: {
    angles() {
      const angles = this.angles;

      if (angles.length === 1) {
        this.selectedAngle = this.getLimitedHinge(angles[0]);
      } else if (angles.length < 1) {
        this.selectedAngle = undefined;
      }
    },
    hinges() {
      const hinges = this.hinges;
      // auto select if only one button
      if (hinges.length === 1) {
        this.selectedHinge = this.getLimitedHinge(hinges[0]);
      } else {
        this.selectedHinge = undefined;
      }
    },
    height() {
      for (let i = 0; i < this.hingePositions.length; i += 2) {
        this.hingePositions[i + 1].y = this.height - this.hingePositions[i].y;
      }
      this.calculateDrillings();
    },
    selectedAngle() {
      this.calculateDrillings();
    }
  },
  methods: {
    next() {
      this.$emit("nextStep", this.nextIndex);
    },
    addDrilling(durchmesser, x, y) {
      const drilling = {
        durchmesser,
        x,
        y
      };
      
      this.$store.commit("addDrilling", drilling);
    },
    angleCount() {
      return 2 * this.getSidePlateCount;
    },
    blurInput() {
      this.$emit("activeInput", '');
    },
    calculateArea(length) {
      return length / 1000 * this.height / 1000;
    },
    calculateDrillings() {
      if (this.selectedAngle) {

        const buttonDrillings = this.getDoorCount;
        this.$store.commit("removeDrilling", buttonDrillings);
        this.$store.commit("removeDrilling", buttonDrillings);

        if (this.selectedAngle.diameter) {
          const diameter = this.selectedAngle.diameter;
          const x_pos = this.sidePlate == 'left' ?
            this.selectedAngle.diameter_pos :
            this.calculateShowerSidePlateWidth - this.selectedAngle.diameter_pos;
          
          this.addDrilling(diameter, x_pos, parseFloat(this.hingePositions[0].y));
          this.addDrilling(diameter, x_pos, parseFloat(this.hingePositions[1].y));
        }
      }
    },
    calculateMaxLoad(hinge) {
      return hinge.max_load * this.hingePositions.length;
    },
    getVariant(variants) {
      let variant = this.selectVariantByOption(variants, [
        ['options.name', '==', 'Glasstärke'],
        ['options.value', '==', '8mm']
      ]);
      
      return variant.length ? variant : variants;
    },
    calculatePrice(variants, amount = 2) {
      const variant = this.getVariant(variants);

      return parseFloat(variant[0]?.price * amount).toFixed(2);
    },
    focusInput(evt) {
      evt.target.select();
      this.$emit("activeInput", evt.target.name);
    },
    getLimitedHinge(hinge) {
      const copy = Object.assign({}, hinge);
      delete copy.body_html;
      delete copy.tags;
      delete copy.image;
      copy.variants = this.getVariant(copy.variants);
      return copy;
    },
    getTitle(title) {
      const segments = title.split(' ');
      segments.forEach((el, i) => { 
        const key = `hinge_${el.replace(/\s/g, '')}`;
        segments[i] = i18n.te(key) ? `${i18n.t(key)}` : key.replace('hinge_', '');
      });

      return segments.join(' ');
    },
    hingeCount() {
      return 2 * this.getDoorCount;
    },
    loadReached(hinge, area, count) {
      return hinge.max_load != 0 && this.calculateMaxLoad(hinge) < (area / count);
    },
    onChange() {
      // reset gasket
      this.selectedSideGasket = "";
    },
    openInfoBox(title, html) {
      this.$alert(html, this.getTitle(title), {
        dangerouslyUseHTMLString: true
      });
    }
  }
};
</script>