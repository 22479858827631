<template>
  <validation-observer
    ref="colorObserver"
    v-slot="{ valid }"
  >
    <div>
      <el-radio-group
        v-if="showSurfaces"
        v-model="surface"
        fill="#1bafd6"
        style="margin-bottom: 8px"
        size="small"
      >
        <el-radio-button
          v-for="item in surfaces"
          :key="item"
          :label="item"
          class="surface"
        >
          {{ $t(item) }}
        </el-radio-button>
      </el-radio-group>

      <div v-if="surface !== 'natural_anodized'">
        <el-radio-group
          v-model="farbe"
          fill="#1bafd6"
          style="margin-bottom: 8px"
        >
          <el-radio-button
            v-for="color in defaults"
            :key="color.value"
            :label="color.value"
            class="card color"
            rules="required"
          >
            <div
              :class="getSurfaceEffect(color.hex_code)"
              :style="`background: ${color.hex_code}`"
              style="height:100px"
            />
            <div class="caption">
              <span class="title">{{ color.name }}</span>
            </div>
            <div class="bottom clearfix">
              <span class="info">{{ $t('select') }}</span>
            </div>
            <div class="overlay" />
          </el-radio-button>
        </el-radio-group>

        <form-item-wrapper
          :label="label"
          :required="true"
          :rules="{
            required: true,
            exists_in_json: { selector: selector, json: colors }
          }"
        >
          <el-autocomplete
            v-model="farbe"
            popper-class="color-autocomplete"
            :fetch-suggestions="querySearch"
            :placeholder="
              colorCatalog === 'ral' ? 'RAL' : 
              colorCatalog ? 'NCS' : 'RAL / NCS'
            "
            @input.native="handleChange"
            @select="handleSelect"
          >
            <i
              slot="suffix"
              class="picked_color"
              :class="getSurfaceEffect(selectedHex)"
              :style="'background-color:' + selectedHex"
            />
            <template slot-scope="{ item }">
              <div class="ral_ncs">
                <span
                  :style="'background-color:' + item.hex_code"
                  class="color"
                />
                <span class="name">{{ item.value }}</span>
              </div>
            </template>
          </el-autocomplete>
        </form-item-wrapper>
      </div>
      <div align="right">
        <el-button
          type="primary"
          :disabled="!valid"
          @click="$emit('nextStep', 1)"
        >
          {{ $t('nextBtn') }}
        </el-button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import json_ral_ncs from "./ral_ncs_colors.json";
import { mapFields } from "vuex-map-fields";
import i18n from "@/js/plugins/i18n";

export default {
  name: "FilmColor",
  props: {
    colorCatalog: {
      type: String,
      default: '',
      validator(value) {
        const acceptedValues = ['', 'ral', '\\d{4}-'];
        return acceptedValues.includes(value.toLowerCase());
      },
    },
    showSurfaces: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: i18n.t('varnishing'),
    },
    surfaces: {
      type: Array,
      default: () => (['natural_anodized', 'ral_matt', 'ral_shiny'])
    },
  },
  data() {
    return {
      selector: "value",
      defaults: [
        { name: "Anthrazit", value: "RAL 7016", hex_code: "#373F43" },
        { name: "Schwarz", value: "RAL 9005", hex_code: "#0A0A0A" },
        { name: "Weiß", value: "RAL 9010", hex_code: "#F1EDE1" }
      ],
      colors: json_ral_ncs.filter(this.createFilter(this.colorCatalog)),
    };
  },
  computed: {
    ...mapFields([
      "farbe",
      "selectedHex",
      "surface"
    ]),
  },
  watch: {
    farbe() {
      var filtered_color = this.colors.filter(this.createFilter(this.farbe));
      this.selectedHex = filtered_color[0]
        ? filtered_color[0].hex_code
        : "#a5a7ad";
    },
    surface() {
      if (this.surface === 'natural_anodized') {
        this.farbe = null;
      }
    }
  },
  mounted() {
    if (this.showSurfaces) {
      this.surface = this.surfaces[0];
    }
    setTimeout(() => this.$refs.colorObserver.validate({ silent: true }), 5);
  },
  methods: {
    getSurfaceEffect(hex) {
      let effect = '';
      if (this.surface === 'ral_shiny') {
        effect = 'shiny';
        if (this.isLight(hex)) {
          effect += ' light';
        }
      }
      return effect;
    },
    querySearch(queryString, cb) {
      var colors = this.colors;
      var results = queryString
        ? colors.filter(this.createFilter(queryString))
        : colors;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      var regex = queryString === undefined ? null : new RegExp(queryString, "i");
      return color => {
        return (
          color.value.toLowerCase().search(regex) === 0
        );
      };
    },
    async handleChange(item) {
      this.$emit(
        "updateValidation",
        await this.$refs.colorObserver.validate({ silent: true })
      );
      var filtered_color = this.colors.filter(
        this.createFilter(item.target.value)
      );
      this.selectedHex = filtered_color[0]
        ? filtered_color[0].hex_code
        : "#fff";
    },
    async handleSelect(item) {
      this.$emit(
        "updateValidation",
        await this.$refs.colorObserver.validate({ silent: true })
      );
      this.selectedHex = item.hex_code;
      this.farbe = item.value;
    }
  }
};
</script>

<style lang="scss">
.el-radio-button.surface {
  width: 33%;
}
.el-radio-button.surface span {
  width: 100%;
}

.el-autocomplete {
  width: 60%;
  max-width: 216px;
}
.color-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .ral_ncs {
      text-overflow: ellipsis;
      overflow: hidden;

      .color {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
    }
  }
}

.picked_color {
  display: inline-block;
  width: 24px;
  background-color: transparent;
  position: absolute;
  right: 5px;
  top: 8px;
  bottom: 8px;
  border-radius: 5px;
}

.shiny::before{
  display: block;
  content: '';
  width: 100%;
  height:100%;
  background-image: linear-gradient(145deg, transparent 0%, #eee 50%, transparent 51%, transparent 100%);
  opacity: .3
}
.shiny.light::before{
  background-image: linear-gradient(145deg, transparent 0%, #222 50%, transparent 51%, transparent 100%);
  opacity: .1
}
</style>
