<template>
  <validation-observer
    ref="formatObserver"
    v-slot="{ invalid }"
  >
    <div v-loading="loading">
      <div
        v-if="uloading"
        id="upload-progress"
      >
        <el-progress
          type="circle"
          :percentage="percentage"
          color="#1bafd6"
        />
      </div>
      <el-row
        v-if="!editor"
        style="margin-bottom: 8px;"
      >
        <el-radio-group
          v-model="selectedFormat"
          fill="#1bafd6"
        >
          <el-radio-button
            v-for="format of possibleFormats"
            :key="format.title"
            :label="format.title"
          >
            <img
              :src="getImgUrl(format)"
              style="width: 24px"
              :style="flipped && '-webkit-transform: scaleX(-1);transform: scaleX(-1);'"
            >
          </el-radio-button>
        </el-radio-group>
      </el-row>
      <el-row
        v-if="!editor"
        style="margin-bottom: 8px"
        :class="handle && handle.includes('isolierglas') ? 'iso' : ''"
        :gutter="12"
      >
        <el-col
          :xs="12"
          :sm="5"
          :md="5"
          :lg="3"
          :xl="2"
        >
          <form-item-wrapper
            :label="$t('width')"
            :rules="{
              required: true,
              numeric: true,
              lower_dimension: { y: height, minX: minWidth, minY: minHeight },
              greater_dimension: { y: height, maxX: maxWidth, maxY: maxHeight }
            }"
          >
            <el-input
              v-model="length"
              :disabled="minWidth == maxWidth"
            >
              <span slot="suffix">mm</span>
            </el-input>
          </form-item-wrapper>
        </el-col>
        <el-col
          :xs="12"
          :sm="5"
          :md="5"
          :lg="3"
          :xl="2"
        >
          <form-item-wrapper
            :label="$t('height')"
            :rules="{
              required: true,
              numeric: true,
              lower_dimension: { y: length, minX: minWidth, minY: minHeight },
              greater_dimension: { y: length, maxX: maxWidth, maxY: maxHeight }
            }"
          >
            <el-input
              v-model="height"
              :disabled="minHeight == maxHeight"
            >
              <span slot="suffix">mm</span>
            </el-input>
          </form-item-wrapper>
        </el-col>
        <el-col
          v-if="[$t('formSpecRect'), $t('formPentagon')].includes(selectedFormat)"
          :xs="12"
          :sm="3"
          :md="3"
          :lg="2"
          :xl="2"
        >
          <form-item-wrapper
            :label="$t('height1')"
            :rules="{
              required: true,
              numeric: true,
              between: { min: 1, max: height - 1 }
            }"
          >
            <el-input v-model="height1">
              <span slot="suffix">mm</span>
            </el-input>
          </form-item-wrapper>
        </el-col>
        <el-col
          v-if="[$t('formTriangle'), $t('formParallelogram'), $t('formTrapezium'), $t('formPentagon')].includes(selectedFormat)
          "
          :xs="12"
          :sm="3"
          :md="3"
          :lg="2"
          :xl="2"
        >
          <form-item-wrapper
            :label="$t('width1')"
            :rules="{
              required: true,
              numeric: true,
              between: $t('formTriangle') == selectedFormat ? { min: 0, max: length / 2 } : { min: 1, max: length - 1 }
            }"
          >
            <el-input v-model="length1">
              <span slot="suffix">mm</span>
            </el-input>
          </form-item-wrapper>
        </el-col>
        <el-col
          v-if="selectedFormat == $t('formTrapezium')"
          :xs="12"
          :sm="3"
          :md="3"
          :lg="2"
          :xl="2"
        >
          <form-item-wrapper
            :label="$t('width2')"
            :rules="{
              required: true,
              numeric: true,
              between: { min: 1, max: length - length1 - 1 }
            }"
          >
            <el-input v-model="length2">
              <span slot="suffix">mm</span>
            </el-input>
          </form-item-wrapper>
        </el-col>
        <el-col
          :xs="8"
          :sm="3"
          :md="3"
          :lg="2"
          :xl="2"
        >
          <form-item-wrapper
            :label="$t('weight')"
            :rules="{ required: true }"
          >
            <el-input
              v-model="weight"
              :disabled="true"
            >
              <span slot="suffix">kg</span>
            </el-input>
          </form-item-wrapper>
        </el-col>
        <el-col
          v-if="![$t('formRectangle'), $t('formEllipse')].includes(selectedFormat)"
          :xs="9"
          :sm="4"
          :md="3"
          :lg="3"
          :xl="2"
        >
          <form-item-wrapper
            :label="$t('flip')"
            :rules="{ required: false }"
            use-slot
          >
            <span slot="label">
              {{ $t('flip') }}
              <el-popover
                placement="top"
                width="200"
                trigger="click"
                :title="$t('flip')"
                :content="$t('flipInfo')"
              >
                <i
                  slot="reference"
                  class="el-icon-info info-icon"
                  style="position:relative;z-index:1"
                />
              </el-popover>
            </span>
            <span
              style="width:100%;display:inline-block;"
              @click="flipped = !flipped"
            >
              <img
                :src="getImgUrl({ image: 'flip' })"
                style="width:24px;vertical-align:middle;cursor:pointer"
              >
            </span>
          </form-item-wrapper>
        </el-col>
      </el-row>
      <div
        id="editor"
        style="background-color: #fff;"
      >
        <el-row
          v-if="image && editor"
          class="nav"
        >
          <el-button
            v-if="addCustomImg"
            style="float:left"
            size="small"
            icon="el-icon-delete"
            @click="goOutEditorFullscreen(true)"
          >
            Löschen
          </el-button>
          <el-button
            style="float:right"
            type="primary"
            size="small"
            icon="el-icon-check"
            @click="goOutEditorFullscreen(false)"
          >
            {{ $t('acceptDesignBtn') }}
          </el-button>
        </el-row>
        <el-row>
          <el-button
            v-if="image"
            :style="{
              top: (height / 2) * ratio + 5 + 'px',
              left: (length / 2) * ratio - 3 + 'px',
              position: 'absolute',
              zIndex: 1
            }"
            :type="getPpiType()"
            :icon="getPpiButtonIcon()"
            size="mini"
            round
            @click="showPPIWarning = 'flex'"
          />
          <el-alert
            v-if="image"
            class="message"
            :style="{ display: showPPIWarning }"
            :type="getPpiType(true)"
            :description="getPpiMessage()"
            show-icon
            @close="showPPIWarning = 'none'"
          />
          <v-stage
            v-if="!isNaN(length) && !isNaN(height) && !isNaN(ratio)"
            ref="stage"
            :config="stageSize"
          >
            <!--
              S T A R T
              R E C T A N G L E
              -------------------------------------------------------------------------
            -->
            <v-layer v-if="selectedFormat == $t('formRectangle')">
              <v-group
                :config="{
                  x: 20 + (length * ratio / 2),
                  y: 20,
                  clipFunc: function (ctx) {
                    ctx.lineTo(0, 0);
                    ctx.lineTo(0, height * ratio);
                    ctx.lineTo(length * ratio, height * ratio);
                    ctx.lineTo(length * ratio, 0);
                    ctx.lineTo(0, 0);
                    ctx.closePath();
                    ctx.stroke();
                  },
                  offsetX: length * ratio / 2,
                  scaleX: flipped ? -1 : 1
                }"
              >
                <v-rect :config="bgConfig()" />
                <v-image
                  v-if="image"
                  ref="image"
                  :config="imageConfig()"
                  @dragmove="updatePosition"
                />
              </v-group>
            </v-layer>
            <!--
              -------------------------------------------------------------------------
              R E C T A N G L E
              E N D
            -->
            <!--
              S T A R T
              E L L I P S E
              -------------------------------------------------------------------------
            -->
            <v-layer v-if="selectedFormat == $t('formEllipse')">
              <v-group
                :config="{
                  x: 20 + (length * ratio / 2),
                  y: 20,
                  clipFunc: function (ctx) {
                    ctx.ellipse(
                      (length * ratio) / 2,
                      (height * ratio) / 2,
                      (length * ratio) / 2,
                      (height * ratio) / 2,
                      Math.PI,
                      0,
                      2 * Math.PI
                    );
                    ctx.stroke();
                  },
                  offsetX: length * ratio / 2,
                  scaleX: flipped ? -1 : 1
                }"
              >
                <v-rect :config="bgConfig()" />
                <v-image
                  v-if="image"
                  ref="image"
                  :config="imageConfig()"
                  @dragmove="updatePosition"
                />
              </v-group>
            </v-layer>
            <!--
              -------------------------------------------------------------------------
              E L L I P S E
              E N D
            -->
            <!--
              S T A R T
              T R I A N G L E
              -------------------------------------------------------------------------
            -->
            <v-layer v-if="selectedFormat == $t('formTriangle')">
              <v-group
                :config="{
                  x: 20 + (length * ratio / 2),
                  y: 20,
                  clipFunc: function (ctx) {
                    ctx.lineTo(length1 * ratio, 0);
                    ctx.lineTo(0, height * ratio);
                    ctx.lineTo(length * ratio, height * ratio);
                    ctx.lineTo(length1 * ratio, 0);
                    ctx.closePath();
                    ctx.stroke();
                  },
                  offsetX: length * ratio / 2,
                  scaleX: flipped ? -1 : 1
                }"
              >
                <v-rect :config="bgConfig()" />
                <v-image
                  v-if="image"
                  ref="image"
                  :config="imageConfig()"
                  @dragmove="updatePosition"
                />
              </v-group>
            </v-layer>
            <!--
              -------------------------------------------------------------------------
              T R I A N G L E
              E N D
            -->
            <!--
              S T A R T
              S P E C I A L  R E C T A N G L E
              -------------------------------------------------------------------------
            -->
            <v-layer v-if="selectedFormat == $t('formSpecRect')">
              <v-group
                :config="{
                  x: 20 + (length * ratio / 2),
                  y: 20,
                  clipFunc: function (ctx) {
                    ctx.lineTo(0, 0);
                    ctx.lineTo(0, height * ratio);
                    ctx.lineTo(length * ratio, height * ratio);
                    ctx.lineTo(length * ratio, (height - height1) * ratio);
                    ctx.lineTo(0, 0);
                    ctx.closePath();
                    ctx.stroke();
                  },
                  offsetX: length * ratio / 2,
                  scaleX: flipped ? -1 : 1
                }"
              >
                <v-rect :config="bgConfig()" />
                <v-image
                  v-if="image"
                  ref="image"
                  :config="imageConfig()"
                  @dragmove="updatePosition"
                />
              </v-group>
            </v-layer>
            <!--
              -------------------------------------------------------------------------
              S P E C I A L  R E C T A N G L E
              E N D
            -->
            <!--
              S T A R T
              P A R A L L E L O G R A M
              -------------------------------------------------------------------------
            -->
            <v-layer v-if="selectedFormat == $t('formParallelogram')">
              <v-group
                :config="{
                  x: 20 + (length * ratio / 2),
                  y: 20,
                  clipFunc: function (ctx) {
                    ctx.lineTo(length1 * ratio, 0);
                    ctx.lineTo(0, height * ratio);
                    ctx.lineTo((length - length1) * ratio, height * ratio);
                    ctx.lineTo(length * ratio, 0);
                    ctx.closePath();
                    ctx.stroke();
                  },
                  offsetX: length * ratio / 2,
                  scaleX: flipped ? -1 : 1
                }"
              >
                <v-rect :config="bgConfig()" />
                <v-image
                  v-if="image"
                  ref="image"
                  :config="imageConfig()"
                  @dragmove="updatePosition"
                />
              </v-group>
            </v-layer>
            <!--
              -------------------------------------------------------------------------
              P A R A L L E L O G R A M
              E N D
            -->
            <!--
              S T A R T
              T R A P E Z I U M
              -------------------------------------------------------------------------
            -->
            <v-layer v-if="selectedFormat == $t('formTrapezium')">
              <v-group
                :config="{
                  x: 20 + (length * ratio / 2),
                  y: 20,
                  clipFunc: function (ctx) {
                    ctx.lineTo(length1 * ratio, 0);
                    ctx.lineTo(0, height * ratio);
                    ctx.lineTo(length * ratio, height * ratio);
                    ctx.lineTo((length - length2) * ratio, 0);
                    ctx.closePath();
                    ctx.stroke();
                  },
                  offsetX: length * ratio / 2,
                  scaleX: flipped ? -1 : 1
                }"
              >
                <v-rect :config="bgConfig()" />
                <v-image
                  v-if="image"
                  ref="image"
                  :config="imageConfig()"
                  @dragmove="updatePosition"
                />
              </v-group>
            </v-layer>
            <!--
              -------------------------------------------------------------------------
              T R A P E Z I U M
              E N D
            -->
            <!--
              -------------------------------------------------------------------------
              P E N T A G O N
              E N D
            -->
            <v-layer v-if="selectedFormat == $t('formPentagon')">
              <v-group
                :config="{
                  x: 20 + (length * ratio / 2),
                  y: 20,
                  clipFunc: function (ctx) {
                    ctx.lineTo(0, 0);
                    ctx.lineTo(0, height * ratio);
                    ctx.lineTo(length * ratio, height * ratio);
                    ctx.lineTo(length * ratio, (height - height1) * ratio);
                    ctx.lineTo(length1 * ratio, 0);
                    ctx.lineTo(0, 0);
                    ctx.closePath();
                    ctx.stroke();
                  },
                  offsetX: length * ratio / 2,
                  scaleX: flipped ? -1 : 1
                }"
              >
                <v-rect :config="bgConfig()" />
                <v-image
                  v-if="image"
                  ref="image"
                  :config="imageConfig()"
                  @dragmove="updatePosition"
                />
              </v-group>
            </v-layer>
            <!--
              -------------------------------------------------------------------------
              P E N T A G O N
              E N D
            -->
            <v-layer>
              <v-line
                :config="{
                  x: 5,
                  y: 20,
                  points: [10, 0, 0, 0, 0, height * ratio, 10, height * ratio],
                  stroke: 'black',
                  strokeWidth: 1,
                  strokeScaleEnabled: false,
                  offsetX: flipped ? 30 + length * ratio : 0,
                  scaleX: flipped ? -1 : 1
                }"
              />
              <v-line
                :config="{
                  x: 20,
                  y: height * ratio + 20 + 15,
                  points: [
                    length * ratio,
                    -10,
                    length * ratio,
                    0,
                    0,
                    0,
                    0,
                    -10
                  ],
                  stroke: 'black',
                  strokeWidth: 1,
                  strokeScaleEnabled: false
                }"
              />
              <v-line
                v-if="[$t('formSpecRect'), $t('formPentagon')].includes(selectedFormat)
                "
                :config="{
                  x: length * ratio + 20 + 15,
                  y: 20 + (height - height1) * ratio,
                  points: [
                    -10,
                    0,
                    0,
                    0,
                    0,
                    height1 * ratio,
                    -10,
                    height1 * ratio
                  ],
                  stroke: 'black',
                  strokeWidth: 1,
                  strokeScaleEnabled: false,
                  offsetX: flipped ? -(length * ratio + 30) : 0,
                  scaleX: flipped ? -1 : 1
                }"
              />
              <v-line
                v-if="[$t('formTriangle'), $t('formParallelogram'), $t('formTrapezium'), $t('formPentagon')].includes(
                  selectedFormat
                )
                "
                :config="{
                  x: 20,
                  y: 5,
                  points: [
                    length1 * ratio,
                    10,
                    length1 * ratio,
                    0,
                    0,
                    0,
                    0,
                    10
                  ],
                  stroke: 'black',
                  strokeWidth: 1,
                  strokeScaleEnabled: false,
                  offsetX: flipped ? -((length - length1) * ratio) : 0,
                }"
              />
              <v-line
                v-if="selectedFormat == $t('formTrapezium')"
                :config="{
                  x: 20 + (length - length2) * ratio,
                  y: 5,
                  points: [
                    length2 * ratio,
                    10,
                    length2 * ratio,
                    0,
                    0,
                    0,
                    0,
                    10
                  ],
                  stroke: 'black',
                  strokeWidth: 1,
                  strokeScaleEnabled: false,
                  offsetX: flipped ? ((length - length2) * ratio) : 0,
                }"
              />
              <v-label
                :config="{
                  x: 1,
                  y: 20 + (height / 2) * ratio + 15,
                  offsetX: flipped ? -(30 + length * ratio) : 0
                }"
              >
                <v-tag :config="{ fill: '#fff', rotation: 270 }" />
                <v-text
                  :config="{
                    text: height,
                    fontSize: 10,
                    align: 'center',
                    verticalAlign: 'middle',
                    rotation: 270,
                    width: 30
                  }"
                />
              </v-label>
              <v-label
                :config="{
                  x: 20 + (length * ratio) / 2 - 15,
                  y: height * ratio + 20 + 10,
                }"
              >
                <v-tag :config="{ fill: '#fff' }" />
                <v-text
                  :config="{
                    text: length,
                    fontSize: 10,
                    align: 'center',
                    verticalAlign: 'middle',
                    width: 30
                  }"
                />
              </v-label>
              <v-label
                v-if="[$t('formSpecRect'), $t('formPentagon')].includes(selectedFormat)
                "
                :config="{
                  x: length * ratio + 20 + 15 - 5,
                  y: 20 + (height * ratio - (height1 / 2) * ratio) + 15,
                  offsetX: flipped ? (30 + length * ratio) : 0
                }"
              >
                <v-tag :config="{ fill: '#fff', rotation: 270 }" />
                <v-text
                  :config="{
                    text: height1,
                    fontSize: 10,
                    align: 'center',
                    verticalAlign: 'middle',
                    rotation: 270,
                    width: 30
                  }"
                />
              </v-label>
              <v-label
                v-if="[$t('formTriangle'), $t('formParallelogram'), $t('formTrapezium'), $t('formPentagon')].includes(
                  selectedFormat
                )
                "
                :config="{
                  x: 20 + (length1 * ratio) / 2 - 15,
                  y: 0,
                  offsetX: flipped ? -((length - length1) * ratio) : 0
                }"
              >
                <v-tag :config="{ fill: '#fff' }" />
                <v-text
                  :config="{
                    text: length1,
                    fontSize: 10,
                    align: 'center',
                    verticalAlign: 'middle',
                    width: 30
                  }"
                />
              </v-label>
              <v-label
                v-if="selectedFormat == $t('formTrapezium')"
                :config="{
                  x:
                    20 +
                    (length - length2) * ratio +
                    (length2 * ratio) / 2 -
                    15,
                  y: 0,
                  offsetX: flipped ? ((length - length1) * ratio) : 0
                }"
              >
                <v-tag :config="{ fill: '#fff' }" />
                <v-text
                  :config="{
                    text: length2,
                    fontSize: 10,
                    align: 'center',
                    verticalAlign: 'middle',
                    width: 30
                  }"
                />
              </v-label>
            </v-layer>
          </v-stage>
          <div
            v-if="!editor"
            class="view-help"
          >
            <div>
              <p><small>{{ $t('behind') }}</small></p>
              <i class="el-icon-top" /><br>
              <i class="el-icon-view" />
              <p><small>{{ $t('front') }}</small></p>
            </div>
          </div>
        </el-row>
        <el-row v-if="image && editor">
          <el-tabs
            v-model="activeTabName"
            stretch
          >
            <el-tab-pane name="move">
              <span
                slot="label"
                class="iconSize-20"
              ><i class="el-icon-rank" /></span>
              <el-col
                :xs="24"
                :sm="10"
                :md="8"
                :lg="6"
                :xl="4"
              >
                <form-item-wrapper
                  :label="$t('posX')"
                  rules=""
                >
                  <el-input-number
                    v-model="imgX"
                    size="small"
                    step-strictly
                    @change="inputOffsetChangeX"
                  />
                  <el-button
                    class="rotate-90"
                    size="small"
                    icon="el-icon-download"
                    circle
                    @click="setOffsetLeft"
                  />
                  <el-button
                    class="rotate-90"
                    size="small"
                    icon="el-icon-minus"
                    circle
                    @click="setOffsetVertical"
                  />
                  <el-button
                    class="rotate-270"
                    size="small"
                    icon="el-icon-download"
                    circle
                    @click="setOffsetRight"
                  />
                </form-item-wrapper>
              </el-col>
              <el-col
                :xs="24"
                :sm="10"
                :md="8"
                :lg="6"
                :xl="4"
              >
                <form-item-wrapper
                  :label="$t('posY')"
                  rules=""
                >
                  <el-input-number
                    v-model="imgY"
                    size="small"
                    step-strictly
                    @change="inputOffsetChangeY"
                  />
                  <el-button
                    class="rotate-180"
                    size="small"
                    icon="el-icon-download"
                    circle
                    @click="setOffsetTop"
                  />
                  <el-button
                    size="small"
                    icon="el-icon-minus"
                    circle
                    @click="setOffsetHorizontal"
                  />
                  <el-button
                    size="small"
                    icon="el-icon-download"
                    circle
                    @click="setOffsetBottom"
                  />
                </form-item-wrapper>
              </el-col>
            </el-tab-pane>
            <el-tab-pane name="resize">
              <span
                slot="label"
                class="iconSize-20"
              ><i class="el-icon-zoom-in" /></span>
              <el-slider
                v-model="sliderValue"
                :min="1"
                :show-tooltip="false"
                @input="handleSliderInput"
              />
            </el-tab-pane>
            <el-tab-pane name="rotate">
              <span
                slot="label"
                class="iconSize-20"
              ><i class="el-icon-refresh" /></span>
              <form-item-wrapper
                :label="$t('rotateImg')"
                rules=""
              >
                <el-button
                  size="small"
                  icon="el-icon-refresh-left"
                  circle
                  @click="rotateLeft()"
                />
                <el-button
                  size="small"
                  icon="el-icon-refresh-right"
                  circle
                  @click="rotateRight()"
                />
              </form-item-wrapper>
            </el-tab-pane>
          </el-tabs>
        </el-row>
        <el-row class="row-upload-image">
          <el-col
            v-if="!image && addCustomImg"
            style="margin: 0 auto"
          >
            <el-upload
              ref="upload"
              class="upload-image"
              accept="image/*"
              action=""
              :limit="1"
              :show-file-list="false"
              :file-list="fileList"
              :http-request="upload"
            >
              <el-button
                size="small"
                type="primary"
                icon="el-icon-picture"
              >
                {{ $t('addImg') }}
              </el-button>
            </el-upload>
          </el-col>
          <el-button
            v-if="image && !editor"
            size="small"
            type="primary"
            icon="el-icon-edit"
            plain
            @click="goInEditorFullscreen"
          >
            {{ $t('editImg') }}
          </el-button>
        </el-row>
      </div>
      <el-alert
        v-if="!editor && handle && handle.includes('isolierglas')"
        type="info"
        show-icon
        :closable="false"
      >
        <template>
          {{ $t('isoAlertMsg') }}
        </template>
      </el-alert>
      <GlassThickness v-if="!editor" />
      <div align="right">
        <el-button
          type="primary"
          :disabled="invalid || editor || (!image && addCustomImg) || (image && !isSvg && calculatePPI() <= 59)"
          @click="next()"
        >
          {{ $t('nextBtn') }}
        </el-button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import GlassThickness from "@/components/Glasart/Glasstaerke.vue";
import i18n from "@/js/plugins/i18n";

export default {
  name: "Format",
  components: {
    GlassThickness
  },
  data() {
    return {
      loading: false,
      uloading: false,
      percentage: 0,
      possibleFormats: [
        { title: i18n.t('formRectangle'), image: "rectangle" }
      ],
      stageSize: {
        width: this.frameWidth(window.innerWidth),
        height: this.frameHeight(window.outerHeight)
      },
      ratio: 1,
      editor: false,
      image: null,
      fileList: [],
      isSvg: false,
      imgScale: 1,
      sliderValue: 100,
      imgX: 0,
      imgY: 0,
      imgRotation: 0,
      activeTabName: "resize",
      showPPIWarning: "none",
    };
  },
  computed: {
    weight() {
      return this.$store.getters.calculateWeight();
    },
    ...mapFields([
      "flipped",
      "handle",
      "selectedFormat",
      "length",
      "length1",
      "length2",
      "height",
      "height1",
      "minWidth",
      "maxWidth",
      "minHeight",
      "maxHeight",
      "addCustomImg",
      "imgToLoad",
      "printPdf",
      "configId"
    ])
  },
  watch: {
    height() {
      this.updateCanvas();
    },
    length() {
      this.updateCanvas();
    },
    imgToLoad() {
      this.imgToLoad && this.loadImg(true);
    }
  },
  created() {
    this.loadFormats();
    window.addEventListener("resize", this.resizeCanvas);
    this.resizeCanvas();

    document.addEventListener(
      "fullscreenchange",
      this.handleFullscrrenChange,
      false
    );
    document.addEventListener(
      "mozfullscreenchange",
      this.handleFullscrrenChange,
      false
    );
    document.addEventListener(
      "MSFullscreenChange",
      this.handleFullscrrenChange,
      false
    );
    document.addEventListener(
      "webkitfullscreenchange",
      this.removeImage,
      false
    );

    this.imgToLoad && this.loadImg();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
  methods: {
    frameHeight(outerHeight) {
      return this.editor ? outerHeight - 210 : 400;
    },
    frameWidth(innerWidth) {
      return innerWidth - (this.editor ? 10 : 60)
    },
    loadFormats() {
      if (this.handle && !this.handle.includes("simple") && this.possibleFormats.length == 1) {
        this.possibleFormats.push(
          { title: i18n.t('formEllipse'), image: "ellipse" },
          { title: i18n.t('formTriangle'), image: "triangle" },
          { title: i18n.t('formSpecRect'), image: "spec_rectangle" },
          { title: i18n.t('formParallelogram'), image: "parallelogram" },
          { title: i18n.t('formTrapezium'), image: "trapezium" },
          { title: i18n.t('formPentagon'), image: "cropped_rectangle" }
        );
      }
    },
    next() {
      if (this.image) {
        this.savePrintTemplate();
      } else {
        this.$emit("nextStep", 1);
      }
    },
    bgConfig() {
      return {
        width: this.length * this.ratio,
        height: this.height * this.ratio,
        fillLinearGradientStartPoint: {
          x: (this.height * this.ratio) / 2,
          y: 0
        },
        fillLinearGradientEndPoint: {
          x: 20,
          y: (this.length * this.ratio) / 2
        },
        fillLinearGradientColorStops: [0, "#fcfcfc", 1, "#ddd"],
        preventDefault: false
      };
    },
    imageConfig() {
      const flip = this.flipped ? -1 : 1;
      return {
        x: this.getPosition("x"),
        y: this.getPosition("y"),
        image: this.image,
        scale: { x: this.imgScale * this.ratio * flip, y: this.imgScale * this.ratio },
        draggable: this.activeTabName == "move",
        rotation: this.imgRotation,
        offset: { x: this.image.width / 2, y: this.image.height / 2 },
        stroke: "#00a1ff",
        strokeWidth: this.editor && this.activeTabName == "move" ? 2 : 0,
        strokeScaleEnabled: false
      };
    },
    getPosition(axis) {
      var offset = axis == "x" ? this.imgX : this.imgY;
      return this.getOffset(axis) + offset * this.ratio;
    },
    getOffset(axis) {
      var rotation = Math.abs(this.imgRotation);
      if (
        (axis == "x" && (rotation == 0 || rotation == 180)) ||
        (axis == "y" && (rotation == 90 || rotation == 270))
      ) {
        return (this.image.width * this.imgScale * this.ratio) / 2;
      } else {
        return (this.image.height * this.imgScale * this.ratio) / 2;
      }
    },
    updatePosition(e) {
      this.imgX = (e.target.x() - this.getOffset("x")) / this.ratio;
      this.imgY = (e.target.y() - this.getOffset("y")) / this.ratio;
    },
    getImgUrl(format) {
      var file = format.image + "_konva";
      var images = require.context("../../assets/", false, /\.svg$/);
      if (this.selectedFormat === format.title) {
        file += "_selected";
      }
      return images("./" + file + ".svg");
    },
    updateCanvas() {
      this.stageSize.height = this.frameHeight(window.outerHeight);
      var frameWidth = parseInt(this.length);
      var frameHeight =
        parseInt(this.height) > parseInt(this.height1)
          ? parseInt(this.height)
          : parseInt(this.height1);

      var wr = (this.stageSize.width - 45) / frameWidth || 0;
      var hr = (this.stageSize.height - 45) / frameHeight || 0;

      var ratio = Math.min(wr, hr);
      this.ratio = ratio;
      this.stageSize.height = this.height * ratio + 55;
    },
    resizeCanvas() {
      this.stageSize.width = this.frameWidth(window.innerWidth);
      this.updateCanvas();
    },
    scaleImage() {
      if (this.image) {
        var scale = Math.max(
          this.length / this.image.width,
          this.height / this.image.height
        );
        this.imgScale = scale;
        this.sliderValue = scale * 100;
      }
    },
    calculatePPI() {
      if (!this.image) { return 0; }
      var inch = (this.image.width * this.imgScale) / 25.4;
      return this.image.width / inch;
    },
    getPpiType(isAlert) {
      var ppi = this.calculatePPI();
      if (ppi > 79 || this.isSvg) {
        return "success";
      } else if (ppi > 59) {
        return "warning";
      } else {
        return isAlert ? "error" : "danger";
      }
    },
    getPpiButtonIcon() {
      var ppi = this.calculatePPI();
      if (ppi > 79 || this.isSvg) {
        return "el-icon-check";
      } else {
        return "el-icon-warning";
      }
    },
    getPpiMessage() {
      var ppi = this.calculatePPI();
      if (ppi > 79 || this.isSvg) {
        return i18n.t('imgQualitySuccessMsg');
      } else if (ppi > 59) {
        this.showPPIWarning = "flex";
        return i18n.t('imgQualityWarningMsg');
      } else {
        this.showPPIWarning = "flex";
        return i18n.t('imgQualityDangerMsg');
      }
    },
    goInEditorFullscreen() {
      this.editor = true;

      var elem = document.querySelector("#editor");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else {
        window.scrollTo(0, 0);
      }
    },
    savePrintTemplate() {
      this.loading = true;

      setTimeout(() => {
        var konfigId = this.configId || "";

        this.$http
          .post(`${process.env.VUE_APP_CREATE_PRINT}${konfigId}`, {
            width: this.length,
            height: this.height,
            src: this.image.src.replace(`${process.env.VUE_APP_PATH}`, ''), // sharp
            rotation: this.imgRotation,
            imgX: this.imgX,
            imgY: this.imgY,
            imgW: (this.getOffset("x") * 2) / this.ratio,
            imgH: (this.getOffset("y") * 2) / this.ratio
          })
          .then(res => {
            this.configId = res.data;
            this.printPdf = `${process.env.VUE_APP_UPLOAD_PATH}print/${this.configId}.pdf`;
            this.$emit("nextStep", 1);
            this.loading = false;
          }).catch(err => {
            this.$message({
              showClose: true,
              message: `${i18n.t('printErrorMsg')} ${err.message}`,
              type: "error",
              duration: 5000
            });
            this.loading = false;
          });
      }, 2000);
    },
    goOutEditorFullscreen(del) {
      del && (this.image = null);
      if (document.exitFullscreen && document.fullscreenElement) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen && document.webkitIsFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen && document.mozFullScreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen && document.msFullscreenElement) {
        document.msExitFullscreen();
      }
      this.closeEditor();
    },
    closeEditor() {
      this.editor = false;
      this.activeTabName = "resize";
    },
    upload() {
      const controller = new AbortController();
      this.loading = true;
      this.percentage = 0;
      this.uloading = true;
      var konfig_id = this.configId || "";
      const formData = new FormData();
      const file = this.$refs.upload.uploadFiles[0];
      formData.append("file", file.raw);
      this.$http
        .post(
          `${process.env.VUE_APP_UPLOAD_IMG}${konfig_id}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: function (progressEvent) {
              this.percentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }.bind(this),
            signal: controller.signal
          }
        )
        .then(res => {
          const time = new Date().getTime();
          this.configId = res.data.filename.split('.')[0];
          this.imgToLoad = `../uploads/${res.data.filename}?t=${time}`;
          this.fileList = [];
          this.uloading = false;
        }).catch(err => {
          let errorMsg = err.message;
          if (err.response) {
            errorMsg = err.response.data.error;
          } else if (err.request) {
            errorMsg = 'No response received';
            if (err.code == 'ERR_CANCELED') {
              errorMsg = 'Upload unterbrochen';
            }
          }

          this.$message({
            showClose: true,
            message: `${i18n.t('uploadErrorMsg')} ${errorMsg}`,
            type: "error",
            duration: 5000
          });
          this.fileList = [];
          this.uloading = false;
          this.loading = false;
        });
    },
    loadImg(goToFullscreen = false) {
      const image = new window.Image();
      image.crossOrigin = 'Anonymous';

      image.onload = () => {
        this.image = image;
        this.scaleImage();
        this.loading = false;
        goToFullscreen && this.goInEditorFullscreen();
      };
      image.onerror = () => {
        this.loading = false;
        this.$message({
          showClose: true,
          message: `Bild konnte nicht geladen werden`,
          type: "error",
          duration: 5000
        });
      };

      if (this.imgToLoad) {
        image.src = `${process.env.VUE_APP_IMG_PATH}${this.imgToLoad}`;
      }
      this.isSvg = image.src.endsWith(".svg");
    },
    handleFullscrrenChange() {
      if (
        !document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        this.closeEditor();
        this.updateCanvas();
      }
    },
    handleSliderInput() {
      this.imgScale = this.sliderValue / 100;
    },
    roundOffset(value) {
      return Math.round(value * 2) / 2;
    },
    inputOffsetChangeX(value) {
      this.imgX = this.roundOffset(value);
    },
    inputOffsetChangeY(value) {
      this.imgY = this.roundOffset(value);
    },
    setOffsetLeft() {
      this.imgX = 0;
    },
    setOffsetTop() {
      this.imgY = 0;
    },
    setOffsetRight() {
      this.imgX =
        this.imgRotation == 0 || Math.abs(this.imgRotation) == 180
          ? this.length - this.image.width * this.imgScale
          : this.length - this.image.height * this.imgScale;
    },
    setOffsetBottom() {
      this.imgY =
        this.imgRotation == 0 || Math.abs(this.imgRotation) == 180
          ? this.height - this.image.height * this.imgScale
          : this.height - this.image.width * this.imgScale;
    },
    setOffsetHorizontal() {
      this.imgY =
        this.imgRotation == 0 || Math.abs(this.imgRotation) == 180
          ? (this.height - this.image.height * this.imgScale) / 2
          : (this.height - this.image.width * this.imgScale) / 2;
    },
    setOffsetVertical() {
      this.imgX =
        this.imgRotation == 0 || Math.abs(this.imgRotation) == 180
          ? (this.length - this.image.width * this.imgScale) / 2
          : (this.length - this.image.height * this.imgScale) / 2;
    },
    rotateLeft() {
      this.imgRotation -= this.imgRotation == -270 ? -270 : 90;
    },
    rotateRight() {
      this.imgRotation += this.imgRotation == 270 ? -270 : 90;
    }
  }
};
</script>
<style type="text/css">
#editor .message {
  max-width: 700px;
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

#editor .nav {
  background-color: #ccc;
}

#editor .nav .el-button.el-button {
  margin: 5px;
}

#upload-progress {
  position: absolute;
  z-index: 3000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#upload-progress .el-progress {
  top: 50%;
  width: 100%;
  text-align: center;
  position: absolute;
  transform: translateY(-50%);
}

#upload-progress .el-progress .el-progress-circle {
  margin: 0 auto;
}

.iconSize-20 {
  font-size: 20px;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.row-upload-image .el-upload,
.row-upload-image .el-button {
  width: 100%;
  max-width: 450px;
}

.el-tab-pane .el-button {
  margin-left: 5px;
}

.el-tab-pane .el-slider {
  margin: 0 20px;
}

.el-form-item.el-form-item {
  margin-bottom: 18px
}
</style>
